import React, { Fragment, useState } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import {Container,Row,Col,Card,Media,TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import TimelineTab from './TimelineTab';
import AboutTab from './AboutTab';
import FriendsTab from './FriendsTab';
import PhotosTab from './PhotosTab';
import {Timline,Friends,About,Photos,GeneralManager} from "../../constant";
import {Link}  from 'react-router-dom'

const SocialApp = () => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <Fragment>
            <BreadCrumb  subparent="Social" title="Social App"/>
            <Container fluid={true}>
                <div className="user-profile social-app-profile">
                    <Row>
                        <Col sm="12">
                            <Card className="hovercard text-center">
                                <div className="cardheader socialheader"></div>
                                <div className="user-image">
                                    <div className="avatar">
                                        <Media body alt="user" src={require("../../assets/images/user/1.jpg")} />
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}/users/UserEdit`}> 
                                      <div className="icon-wrapper" data-intro="Change rrofile info here"><i className="icofont icofont-pencil-alt-5"></i></div>
                                    </Link>
                                </div>
                                <div className="info market-tabs p-0">
                                    <Nav tabs className="border-tab tabs-scoial">
                                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                {Timline}
                                        </NavLink>
                                        </NavItem>
                                        <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                            <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                {About}
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <div className="user-designation"></div>
                                            <div className="title"><a target="_blank" href="#javascripts">{"ElANA"}</a></div>
                                            <div className="desc mt-2">{GeneralManager}</div>
                                        </NavItem>
                                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLink tag="a" href="#javascript" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                {Friends}
                                        </NavLink>
                                        </NavItem>
                                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLink tag="a" href="#javascript" className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                                {Photos}
                                        </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <TabContent activeTab={activeTab} className="tab-content">
                        <TabPane tabId="1">
                            <TimelineTab />
                        </TabPane>
                        <TabPane tabId="2">
                            <AboutTab />
                        </TabPane>
                        <TabPane tabId="3">
                           <FriendsTab />
                        </TabPane>
                        <TabPane tabId="4">
                            <PhotosTab />
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </Fragment>
    );
};

export default SocialApp;