import React, { Fragment,useEffect,useState} from 'react';
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import Tour from 'reactour'

const IntsructionsTour = (props) => {

    const [opentour,setopentour] = useState(props.tourInstructions)
    const tourSteps = () => {
        const windowWidth = props.windowWidth
        if (windowWidth <= 320) {
            // Phone
            return [{}]
        }else if (windowWidth <= 375){
            // Tablet
            return [{}]
        }else{
            const steps = [
                {
                    selector: '.step1',
                    content: 'Explanada Central',
                },
                {
                    selector: '.step2',
                    content: 'Selecciona tu asiento',
                },
                {
                    selector: '.step3',
                    content: 'Palco VIP',
                },
                {
                    selector: '.step4',
                    content: 'Zoom de cámara',
                },
                {
                    selector: '.step5',
                    content: 'Ajustes de Audio',
                },
                {
                    selector: '.step6',
                    content: 'Estadisticas',
                },
                {
                    selector: '.step7',
                    content: 'Nike Store',
                },
                {
                    selector: '.step8',
                    content: 'Pide con Uber',
                },
                {
                    selector: '.step9',
                    content: 'Show de medio tiempo',
                },
                {
                    selector: '.step10',
                    content: 'Compartir en Redes',
                },
                {
                    selector: '.step11',
                    content: 'Opciones y Ajustes',
                },
                {
                    selector: '.step12',
                    content: 'User Stories',
                },
                {
                    selector: '.step13',
                    content: 'Perfil del usuario',
                },
                {
                    selector: '.step14',
                    content: 'Notificaciones',
                },
                {
                    selector: '.step15',
                    content: 'Contactos',
                }
                // ,
                // {
                //     selector: '.step16',
                //     content: 'Otros Ajustes',
                // }
            ]
            return steps
        }

        
    }
    
    useEffect(()=>{
        setopentour(props.tourInstructions)
    },[props.tourInstructions])

    const closeTour = () => {
        props.setTourInstructions(false)
    }
    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);
    
    return (
        <Fragment>
           <Tour
                steps={tourSteps()}
                rounded={5}
                isOpen={opentour}
                disableInteraction={true}
                disableKeyboardNavigation={false}
                onRequestClose={closeTour}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            /> 
            {/* <Media body className="step1" alt="" src={require("../../assets/images/user/7.jpg")} data-intro="This is Profile image" /></div> */}     
        </Fragment>
    );
}

export default IntsructionsTour;