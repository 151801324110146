import React, {useState, useEffect, Fragment} from 'react'
import axios from 'axios';
import { Card, CardBody, CardHeader, Button} from 'reactstrap'
import { User, CreditCard, Settings, FileText, LogOut } from 'react-feather'
import {YourAccount,InvoicesAndPayments,Setting,YourServices,LogOuts, HolesInData} from "../../../../constant";

const UserCard = (props) =>  {
  
  const [user , setUser] = useState(props.currentUser)

  if (props.isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Fragment>
        <Card className="crm-user-profile-card">
          <CardHeader className="p-0 profile-top-bg">
            <div className="profile-round profile-round-big animation-round-1"></div>
            <div className="profile-round profile-round-medium animation-round-2"> </div>
            <div className="profile-round profile-round-big animation-round-3"></div>
            <div className="profile-round profile-round-small animation-round-4"></div>
            <div className="profile-round profile-round-semi-small animation-round-5"></div>
            <div className="profile-round profile-round-small animation-round-6"></div>
            <div className="profile-details">
              <div className="media"><img className="img-90 rounded-circle" src={require(`../../../../assets/images/user/${user.avatar}`)} alt=""/>
                <div className="media-body">
                  <h5><b>{user.name +' '+ user.lastName}</b></h5>
                  <span className="f-12 d-block">{"@"+user.userName}</span>
                  <span className="badge badge-pill bg-white font-info">{user.role}</span>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <ul>
              <li>
                <h6><User/>{YourAccount}</h6>
              </li>
              <li>
                <h6> <CreditCard/>{InvoicesAndPayments}<span className="pull-right badge badge-pill pill-badge-secondary">{"2"}</span></h6>
              </li>
              <li>
                <h6><Settings/>{Setting}</h6>
              </li>
              <li>
                <h6> <FileText/>{YourServices}</h6>
              </li>
              <li onClick={props.logOutUser} style={{cursor: "pointer"}}>
                <h6><LogOut/>{LogOuts}</h6> 
              </li>
            </ul>
          </CardBody>
        </Card>
    </Fragment>
  )
}

export default UserCard
