import React,{useState,Fragment} from 'react';
import {useDispatch } from 'react-redux';
import {Row,Col,Card,CardHeader,CardBody,ListGroup,Form,FormGroup,Input,Media} from 'reactstrap'
import {SEARCH_BY,SORT_BY} from '../../../../redux/actionTypes'
import {Grid,List} from 'react-feather'
import Allfilters from '../../../application/ecommerce-app/filters/allfilters'
import Carousal from '../../../application/ecommerce-app/filters/Carousal'
import { Filters,ShowingProducts,Featured,LowestPrices,HighestPrices } from "../../../../constant";

const ProductFilter = (props) => {

    
    const [filterSidebar, setFilterSidebar] = useState(true);
    const dispatch = useDispatch();
    const [sidebaron, setSidebaron] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    
    const gridLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
          el.className = '';
          el.classList.add('col-xl-3');
          el.classList.add('col-sm-6');
          el.classList.add('xl-4')
        });
      }
      

    const listLayout = () => {
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".gridRow").childNodes;
        [].forEach.call(elems, function (el) {
          el.className = '';
          el.classList.add('col-xl-12');
        });
      }
    
    const LayoutView = (layoutColumns) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
          var elems = document.querySelector(".gridRow").childNodes;
          [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-xl-' + layoutColumns);
          });
        }
      }

      
    const filterSortFunc = (event) => {
        dispatch({ type: SORT_BY, sort_by: event })
      }

    const onClickFilter = () => {
        if (sidebaron) {
          setSidebaron(false)
          document.querySelector(".product-wrapper").classList.add('sidebaron');
        } else {
          setSidebaron(true)
          document.querySelector(".product-wrapper").classList.remove('sidebaron');
        }
      }
    
      const handleSearchKeyword = (keyword) => {
        setSearchKeyword(keyword)
        dispatch({ type: SEARCH_BY, search: keyword })
      }
      
    return (
        <Fragment>
            <div className="feature-products">
                <Row>
                  <Col md="6" className="products-total">
                    <div className="square-product-setting d-inline-block">
                      <a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript"  >
                        <Grid/>
                      </a>
                    </div>
                    <div className="square-product-setting d-inline-block">
                      <a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript" >
                        <List/>
                        </a>
                    </div>
                    <span className="d-none-productlist filter-toggle" onClick={() => setFilterSidebar(!filterSidebar)} >
                      <h6 className="mb-0">{Filters}
                      <span className="ml-2">
                        <i className="toggle-data fa fa-chevron-down"></i>
                      </span>
                      </h6>
                    </span>
                    <div className="grid-options d-inline-block">
                      <ListGroup as="ul">
                        <li>
                          <a className="product-2-layout-view" onClick={() => LayoutView(6)} href="#javascript" >
                          <span className="line-grid line-grid-1 bg-primary"></span>
                          <span className="line-grid line-grid-2 bg-primary"></span>
                          </a>
                        </li>
                        <li><a className="product-3-layout-view" onClick={() => LayoutView(4)} href="#javascript" >
                          <span className="line-grid line-grid-3 bg-primary"></span>
                          <span className="line-grid line-grid-4 bg-primary"></span>
                          <span className="line-grid line-grid-5 bg-primary"></span>
                          </a>
                        </li>
                        <li>
                          <a className="product-4-layout-view" onClick={() => LayoutView(3)} href="#javascript" >
                          <span className="line-grid line-grid-6 bg-primary"></span>
                          <span className="line-grid line-grid-7 bg-primary"></span>
                          <span className="line-grid line-grid-8 bg-primary"></span>
                          <span className="line-grid line-grid-9 bg-primary"></span>
                          </a>
                        </li>
                        <li>
                          <a className="product-6-layout-view" onClick={() => LayoutView(2)}  href="#javascript">
                            <span className="line-grid line-grid-10 bg-primary"></span>
                            <span className="line-grid line-grid-11 bg-primary"></span>
                            <span className="line-grid line-grid-12 bg-primary"></span>
                            <span className="line-grid line-grid-13 bg-primary"></span>
                            <span className="line-grid line-grid-14 bg-primary"></span>
                            <span className="line-grid line-grid-15 bg-primary"></span>
                            </a>
                        </li>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col md="6" className="text-right">
                    <span className="f-w-600 m-r-5">{ShowingProducts}</span>
                    <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => filterSortFunc(e.target.value)}>
                      <select className="form-control btn-square" name="select">
                        <option value="Featured">{Featured}</option>
                        <option value="LowestPrices">{LowestPrices}</option>
                        <option value="HighestPrices">{HighestPrices}</option>
                      </select>
                    </div>
                  </Col>
                </Row>  
                <Row>
                  <Col sm="3">
                    <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                      <div className="filter-section">
                        <Card>
                          <CardHeader>
                            <h6 className="mb-0 f-w-600">{Filters}
                            <span className="pull-right">
                              <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                            </span>
                            </h6>
                          </CardHeader>
                          <div className="left-filter">
                            <CardBody className="filter-cards-view animate-chk">
                              <Allfilters/>
                              <Carousal/>
                              <div className="product-filter text-center mt-2">
                                <Media className="img-fluid banner-product m-auto" src={require("../../../../assets/images/ecommerce/banner.jpg")} alt=""/>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col md="9" sm="12">
                    <Form>
                      <FormGroup className="m-0">
                        <Input 
                          className="form-control"
                          type="text"
                          placeholder="search"
                          defaultValue={searchKeyword}
                          onChange={(e) => handleSearchKeyword(e.target.value)}
                          />
                          <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row> 
            </div>
        </Fragment>
    )
}

export default ProductFilter
