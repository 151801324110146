import React, { Fragment } from 'react';
import {Row,Col,Card,CardBody,Button,Media,InputGroup, InputGroupAddon,Input} from 'reactstrap';
import PostFeed from './PostFeed'
import ScrollArea from 'react-scrollbar'

const TimelineTab = () => {
    return (
        <Fragment>
            <ScrollArea
                speed={0.5}
                horizontal={false}
                vertical={true}>
                <Row>
                    <Col sm="12">
                        <PostFeed/>
                    </Col>
                    <Col sm="12">
                        <PostFeed/>
                    </Col>
                </Row>
            </ScrollArea> 
        </Fragment>
    );
};

export default TimelineTab;