import React, { Fragment, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Set2DBackGround} from '../../../../redux/common/actions'
import ExperiencesView from './EditView/ExperiencesView'
import ExperiencesList_1D from './1D/ExperiencesList_1D'

const ExperiencesListView = () => {
  
  const dispatch = useDispatch()
  const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
  const mainType = useSelector(state => state.Common.mainType)
  const experiences = useSelector(state => state.Common.currentEvent.experiences)
  
  useEffect(() => {
    
    console.log("Setting 2D BackGround ---------------------------------------")
    platformtypes.map(view =>{
      if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.home))}
    }) 
  },[])
  
  switch(mainType){
    case"Admin":
      return (
        <Fragment>
          <ExperiencesView experiencesData={experiences}/>
        </Fragment>
      )
    break;
    default:
    case"1D":
      return (
        <Fragment>
          <ExperiencesList_1D experiencesData={experiences}/>
        </Fragment>
      )
    break;
    case"2D":
      return (
        <Fragment>
          {"2D"}
          {/* <ExperiencesList_2D experiencesData={experiences}/> */}
        </Fragment>
      )
    break;
  }
};
export default ExperiencesListView;