import React,{useState,useEffect,Fragment} from 'react';
import axios from "axios";
import BreadCrumb from '../../../../../layout/Breadcrumb'
import LabeledInput from '../../../../general/LabeledInput'
import {Container,Row,Col,Button,Nav,NavItem,NavLink,TabContent,TabPane,InputGroup,InputGroupAddon,Input,Form,FormGroup,Label,Table,Media,Badge,Card,CardHeader,CardBody,CardFooter} from 'reactstrap'
import {ShowsLocations,Locations,LocationDetails,LocationThumbnail,LocationName,AdPlacements,Hotspots,Show,Perspectives,Sponsors,Save,Shows,Cancel,Title,FromDate,ToDate,Description, Eventt, Venue, VenueLocations, GeneralInfo, Administrators, GuestsList, BlockedUsers, SponsorsList, List, AdministratorsList, General, Guests, Users, ShowsLists} from '../../../../../constant'
import {useSelector,useDispatch} from 'react-redux'

import GeneralInfoData from './GeneralInfo/GeneralInfoData'
import ShowsData from './Shows/ShowsData'
import AdministratorsData from '../../../EditMode_General/Administrators/AdministratorsData'
import SponsorsTable from './Sponsors/SponsorsTable'
import UsersListData from '../../../EditMode_General/Users/UsersListData'

const ExperienceForm = (props) => {

  const et = useSelector(state => state.Common.eventType)
  const [activeTab, setActiveTab] = useState('1')
  //const [experience, setLocation] = useState(props.experienceData)
  const location = useSelector(state => state.Common.currentEvent.locations[2])

  return (
    <Fragment>
      <BreadCrumb subparent={Show[et]} title={ShowsLists} addBtn={true} statisticsBTN={true}/>
      <Container fluid={true}>
        <Nav tabs className="pull-right  border-tab">
            <NavItem>
                <NavLink className={activeTab === '1' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('1')}>
                    {GeneralInfo}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '2' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('2')}>
                    {Shows[et]}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '3' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('3')}>
                {Sponsors}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '4' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('4')}>
                {Administrators}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '5' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('5')}>
                  {Users}
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <GeneralInfoData locationData={location} />
            </TabPane>
            <TabPane tabId="2">
              <ShowsData showsData={location.shows}/>
            </TabPane>
            <TabPane tabId="3">
               <SponsorsTable sponsorList={location.administrators}/>
            </TabPane>
            <TabPane tabId="4">
              <AdministratorsData administratorsList={location.administrators} />
            </TabPane>
            <TabPane tabId="5">
              <UsersListData guestsList={location.guestsList} bannedList={location.guestsList}/>
            </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  );
};

export default ExperienceForm;