import React, {Fragment,useEffect,useState} from 'react';
import {Container,Row,Col,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import BreadCrumb from '../../../../../layout/Breadcrumb'
import InputRange from "react-input-range";
import ScrollArea from 'react-scrollbar'
import {UserThumbnail} from '../../../../general/UserThumbnail'
import { Badge } from 'reactstrap'
import { Attendance, Comments, DownloadableResources, HandsDown, HandsUp, Polls, Questions, QuestionsPolls, RaisedHands } from '../../../../../constant'

import BottomBar from '../shared/BottomBar'

import UsersList from '../../../../users/UsersList'
import CommentsList from '../../../../general/CommentsList'
import DownloadableFiles from '../../../../general/DownloadableFiles';
import { GooglePieChart1 } from '../../../../charts/GoogleCharts/GooglechartData'

const Experience_1D = (props) => {
 
    const [activeTab, setActiveTab] = useState('1')
    const [experience, setExperience] = useState(props.experienceData)
    const [handsDown, setHandsSown] = useState(experience.attendance.filter(x=> !experience.handsUp.includes(x)))
    const [mainSide, setMainSide] = useState(12)
    const [currentSideTab, setSideTab] = useState("")
    const openSideTab = (tabName) =>{
        if(currentSideTab == tabName){
            setMainSide(12)
            setSideTab("")
        }else{
            setMainSide(9)
            setSideTab(tabName)
        }
    }

    // SHARING CAMERA
    const [sharingCam , setSharingCam] = useState({audio:true,video:true})
    const cameraSettings = (type) =>{
        if(type == "audio"){
            setSharingCam({ ...sharingCam, audio: !sharingCam.audio })
        }else{
            setSharingCam({ ...sharingCam, video: !sharingCam.video })
        }
    }


    // SCREEN SHARE -----------------------------------------------------------------------
    const [leftSize, setLeftSize] = useState(6)
    const [rightSize, setRightSize] = useState(12)
    const [usersPerRow, setUsersPerRow] = useState(12)

    useEffect(()=>{
        mainUsersPerRow()
    },[])

    const mainUsersPerRow = () =>{
    if(experience.attendance.length === 1){
        setUsersPerRow(12)
    }else if(experience.attendance.length === 2){
        setUsersPerRow(6)
    }else if(experience.attendance.length === 3){
        setUsersPerRow(4)
    }else if(experience.attendance.length === 4 || experience.attendance.length === 5){
        setUsersPerRow(3)
    }else if(experience.attendance.length >= 6){
        setUsersPerRow(2)
    }
    }

    const initialslider = {
  value: 6,
    }
    const [values, setValues] = useState(initialslider)

    const screenSpliter = (value) => {
    var leftSize = value
    var rightSize = 12-leftSize
    if(leftSize >= 4 && rightSize <= 10){
        console.log("Screen Spliter: ", value)
        setValues({ ...values, value: value })
        setLeftSize(leftSize)
        setRightSize(rightSize)
        switch(leftSize){
              default:
              case 4:
                if(experience.attendance.length === 1){
                    setUsersPerRow(12)
                }else if(experience.attendance.length >= 2){
                    setUsersPerRow(6)
                }
              break;
              case 5:
                if(experience.attendance.length === 1){
                    setUsersPerRow(12)
                }else if(experience.attendance.length === 2){
                    setUsersPerRow(6)
                }else if(experience.attendance.length >= 3){
                    setUsersPerRow(4)
                }
              break;
              case 6:
                mainUsersPerRow()
              break;
              case 7:
              case 8:
                if(experience.attendance.length === 1){
                    setUsersPerRow(12)
                }else if(experience.attendance.length === 2){
                    setUsersPerRow(6)
                }else if(experience.attendance.length >= 3){
                    setUsersPerRow(4)
                }
              break;
              case 9:
                if(experience.attendance.length === 1){
                    setUsersPerRow(12)
                }else if(experience.attendance.length >= 2){
                    setUsersPerRow(6)
                }
              break;
              case 10:
                  setUsersPerRow(12)
              break;
              case 11:
              case 12:
                mainUsersPerRow()
                setLeftSize(12)
                setRightSize(12)
              break;
        }
    }
    }

    const [sharingScreen, setSharingScreen] = useState(false)
    const shareScreen = () =>{
    console.log("Sharing Screen", !sharingScreen)
    setSharingScreen(!sharingScreen)
    if(sharingScreen){
        screenSpliter(12)
    }else{
        screenSpliter(6)
    }
    }
        
    return (
        <Fragment>
            <BreadCrumb parent="Event" subparent="Experiences" title={experience.name} addBtn={true}/>
            <Container fluid={true}>
                <Row>
                    <Col lg={mainSide}>
                        {sharingScreen?
                            <InputRange
                                maxValue={11}
                            minValue={1}
                            value={values.value}
                            formatLabel={() => ``}
                                onChange={(value) => screenSpliter(value) }
                            />:""}
                        <Row>
                            {sharingScreen?
                                <Col lg={leftSize} style={{BackgroundColor:"black"}}>
                                    <div className="split left">
                                        <h2>Jane Flex</h2>
                                        <p>Some text.</p>
                                        <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    {rightSize == 12? <hr/>: ""}
                                </Col>:""
                            }
                            <Col lg={rightSize} style={{BackgroundColor:"gray"}}>
                                <ScrollArea
                                    speed={0.5}
                                    horizontal={false}
                                    vertical={true}>  
                                    <Row className="p-5">
                                        {experience.attendance.map((data, i) => {
                                            return (
                                                <Col lg={usersPerRow} key={i}>
                                                    <UserThumbnail userID={[data]}/>
                                                    <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> 
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </ScrollArea>
                            </Col>
                        </Row>
                    </Col>
                    {currentSideTab == "users" ? 
                    <Col lg="3">
                        <h4><b>{Attendance}</b> &nbsp; <Badge color="primary" pill>{experience.attendance.length}</Badge></h4>
                        <hr/>
                        <UsersList listType={"users"} usersList={experience.attendance}/>
                    </Col>:""}
                    {currentSideTab == "chat" ? 
                    <Col lg="3">
                        <h4><b>{Comments}</b></h4>
                        <hr/>
                        <CommentsList type="comments" commentsData={experience.comments} attendanceData={experience.attendance}/>
                    </Col>:""}
                    {currentSideTab == "questions" ? 
                    <Col lg="3">
                        <h4><b>{QuestionsPolls}</b></h4>
                        <hr/>
                        <Nav tabs className="pull-right  border-tab">
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('1')}>
                                    {Questions}&nbsp;<i className="fa fa-question-circle"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '2' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('2')}>
                                    {Polls}&nbsp;<i className="fa fa-bar-chart-o"></i>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <CommentsList type="questions" commentsData={experience.questions}/>
                            </TabPane>
                            <TabPane tabId="2">
                                <GooglePieChart1/>
                            </TabPane>
                        </TabContent>
                    </Col>:""}
                    {currentSideTab == "files" ? 
                    <Col lg="3">
                        <h4><b>{DownloadableResources}</b></h4>
                        <hr/>
                        <DownloadableFiles downloadableDocs={experience.downloadableDocs}/>
                    </Col>:""}
                    {currentSideTab == "handsUp" ? 
                    <Col lg="3">
                        <h4><b>{RaisedHands}</b></h4>
                        <hr/>
                        <Nav tabs className="pull-right  border-tab">
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('1')}>
                                    {HandsUp}&nbsp; <Badge color="primary" pill>{experience.handsUp.length}</Badge>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '2' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('2')}>
                                    {HandsDown}&nbsp; <Badge color="danger" pill>{handsDown}</Badge>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                  <UsersList listType={"users"} listType={"users"} usersList={experience.handsUp}/>
                            </TabPane>
                            <TabPane tabId="2">
                                  <UsersList listType={"users"} listType={"users"} usersList={handsDown}/>
                            </TabPane>
                        </TabContent>
                    </Col>:""}
                    {currentSideTab == "apps" ? 
                    <Col lg="3">
                        {"Apps"}
                    </Col>:""}

                </Row>
                <BottomBar 
                    sharingScreen={sharingScreen}
                    shareScreenBtn={()=>shareScreen()}
                    currentSideTab={currentSideTab}
                    openSideTab={tabName=>openSideTab(tabName)}
                    sharingCam={sharingCam}
                    cameraSettings={(type)=>cameraSettings(type)}/>
            </Container>
        </Fragment>
    )
}

export default Experience_1D;