import React,{useState,useEffect,useCallback, useLayoutEffect} from 'react';
// import creativeLogo from '../assets/images/creative-logo1.png';
import logo_light from '../assets/images/creative-logo1.png'
import logo_thumbnail from '../assets/images/tudn_thumbnail.png'
import Leftbar from './header/Leftbar'
import LeftbarVR from './header/LeftbarVR'
import Rightbar from './header/Rightbar'
import LeftVR_Types from './header/LeftVR_Types/LeftVR_Types';
import '../assets/css/evnt_cards.css'
import {SearchBarToggle, MobileRightToggle} from '../redux/common/actions'
import { useDispatch, useSelector } from 'react-redux';
import {MENUITEMS} from './sidebar/menu'
import {Link} from 'react-router-dom'

export const Header = (props) => {
  const configDB = useSelector(content => content.Customizer.customizer)
  const sidebar_background_color = configDB.settings.sidebar_background_setting;
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState('');
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);

  const dispatch = useDispatch();
  const searchTog = useSelector(state => state.Common.searchToggle)
  const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
  const switchToggle= useSelector(state => state.Common.switchToggle) 
  const mainType = useSelector(state => state.Common.mainType)
  const windowWidth = props.windowWidth

  const escFunction = useCallback((event) => {
    if(event.keyCode === 27) {
      setsearchValue('')
    }
  }, []);
    
  useEffect(() => {
    if (windowWidth <= 991) {
        document.querySelector(".page-main-header").className = 'page-main-header open' 
        //document.querySelector(".page-sidebar").className = 'page-sidebar open' 
        // if (localStorage.getItem("layout_version") === 'dark-only') {
        //     document.querySelector(".header-logo").className = 'header-logo light';
        // }
        // else {
        //     document.querySelector(".header-logo").className = 'header-logo normal';
        // }
    }
    else {
        document.querySelector(".page-main-header").className = 'page-main-header open' 
        //document.querySelector(".page-sidebar").className = 'page-sidebar ' + sidebar_background_color
    }
    document.addEventListener("keydown", escFunction, false);
    return () => {
        document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction,windowWidth,sidebar_background_color]);

  const handleSearchKeyword = (keyword) => {
      keyword ? addFix() : removeFix()
      const items = [];
      setsearchValue(keyword)
      mainmenu.filter(menuItems => {
          if (menuItems.title.toLowerCase().includes(keyword) && menuItems.type === 'link') {
              items.push(menuItems);
          }
          if (!menuItems.children) return false
          menuItems.children.filter(subItems => {
              if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                  subItems.icon = menuItems.icon
                  items.push(subItems);
              }
              if (!subItems.children) return false
              subItems.children.filter(suSubItems => {
                  if (suSubItems.title.toLowerCase().includes(keyword)) {
                      suSubItems.icon = menuItems.icon
                      items.push(suSubItems);
                  }
                  return suSubItems
              })
              return subItems
          })
          checkSearchResultEmpty(items)
          setsearchValue(items);
          return menuItems
      });
  }

  const checkSearchResultEmpty = (items) => {
      if (!items.length) {
          setSearchResultEmpty(true);
          document.querySelector(".empty-menu").classList.add('is-open');
      } else {
          setSearchResultEmpty(false);
          document.querySelector(".empty-menu").classList.remove('is-open');
      }
  }

  const addFix = () => {
      setSearchResult(true);
      document.querySelector(".Typeahead-menu").classList.add('is-open');
  }

  const removeFix = () => { 
      setSearchResult(false)
      setsearchValue('')
      document.querySelector(".Typeahead-menu").classList.remove('is-open');
  }

  const event = useSelector(state => state.Common.currentEvent)

    return(
        <div 
            style={{backgroundImage:`url('${props.backImg2D}')`, 
            backgroundSize:"cover",backgroundRepeat:"no-repeat",
            marginLeft: 0,
            width: "100%" }}
          className={`page-main-header ${switchToggle? 'open': ''}`}>
            <div className="main-header-right">
                {/* <div className="main-header-left d-lg-none">
                  <div className="logo-wrapper header-logo normal">
                      <a href="#javascript">
                          <img className="normallogo" src={creativeLogo} alt=""/>
                          <img className="lightlogo" src={logo_light}  alt="" />
                      </a>
                  </div>
                </div> */}
                <a href="https://www.tudn.mx/" target="_blank">
                    <div className="mobile-sidebar text-center d-block">
                       {windowWidth <= 991? 
                        <div style={{paddingTop:"20px", display:"flex"}}>
                            {windowWidth <= 425 ?
                                <img className="lightlogo header-logo" src={logo_thumbnail} alt="" /> :
                                <img className="lightlogo header-logo" src={logo_light} alt="" />
                            }
                            <LeftVR_Types checkIfIsOpen={props.checkIfIsOpen} windowWidth={props.windowWidth} eventType={event.eventType} eventName={event.name} eventLocation={event.location.venue}/>
                        </div>:
                        <img className="lightlogo" src={logo_light}  alt="" />
                       }
                    </div> 
                </a>
                {true ? 
                <LeftbarVR
                    perspectiveData={props.perspectiveData}
                    perspectiveName = {props.perspectiveName}
                    checkIfIsOpen={props.checkIfIsOpen}
                    eventCasterID={props.eventCasterID}/>:
                <Leftbar/>}
                <Rightbar  
                    eventTypes={props.eventTypes}
                    currentShow={props.currentShow} 
                    checkIfIsOpen={props.checkIfIsOpen}
                    eventCasterID={props.eventCasterID}
                    />
                    <form className={`form-inline search-full ${searchTog? 'open': ''}`}>
                      <div className="form-group form-control-plaintext">
                        <input 
                          type="search"
                          id="search"
                          placeholder="Search.."
                          defaultValue={searchValue}
                          onChange={(e) => handleSearchKeyword(e.target.value)}  
                          />
                        <i onClick={() => dispatch(SearchBarToggle(searchTog))} className="icon-close -search close-search"></i>

                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchValue ?
                                searchValue.map((data, index) => {
                                    return (
                                        <div className="ProfileCard u-cf" key={index}>
                                            <div className="ProfileCard-avatar">
                                                <data.icon />
                                            </div>
                                            <div className="ProfileCard-details">
                                                <div className="ProfileCard-realName">
                                                    <Link 
                                                        to={data.path} 
                                                        className="realname" 
                                                        onClick={()=> props.leftSideTabs(true,"user_story") , removeFix}
                                                    >
                                                        {data.title}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                          </div>
                          <div className="Typeahead-menu empty-menu">
                              <div className="tt-dataset tt-dataset-0">
                                  <div className="EmptyMessage">
                                      {"Opps!! There are no result found."}
                                  </div>
                              </div>
                          </div>
                      </div>
                    </form>
                {/* <div className="d-lg-none mobile-toggle pull-right">
                    <i className="fa fa-ellipsis-h fa-2x" style={{color:"white",cursor:"pointer",margin:"0px 5px"}}
                        onClick={() => dispatch(MobileRightToggle(mobileRightTog))}>
                    </i>
                </div> */}
            </div>
        </div>
    )
}

export default Header