import React, { useState, useEffect, Fragment } from 'react'
import {Table,Row,Col,Label, Media, Input,InputGroup,InputGroupAddon,Button,
Nav, NavItem, NavLink, TabPane, TabContent} from 'reactstrap'
import {Link}  from 'react-router-dom'
import {Actions, BannerImage, Delete, Edit, LinkTo, LinkType, Owner, Sponsors} from "../../../../../../../constant"

const BannersTable = (props) =>  {

  const [banners , setBanner] = useState(props.bannersData)
  
  return (
    <Fragment>
        <div className="table-responsive m-t-20">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{BannerImage}</th>
                        <th scope="col">{Owner}</th>
                        <th scope="col">{LinkType}</th>
                        <th scope="col">{LinkTo}</th>
                        <th scope="col">{Actions}</th>
                    </tr>
                </thead>
                <tbody>
                {banners.map((data, i) => {
                    return (
                        <tr key={i}>
                            <th scope="row">{data.id}</th>
                            <td>
                                <Media src={require(`../../../../../../../assets/images/banner/${data.name}`)} alt={data.altText} className="img-fluid" style={{"maxWidth":"700px"}}/>
                            </td>
                            <td>{data.owner}</td>
                            <td>{data.linkTo.type}</td>
                            <td>{data.linkTo.type == "URL" ? 
                                <a href={data.linkTo.id} target="_blank"> 
                                {data.linkTo.id}
                                </a> 
                                : 
                                <Link to={data.linkTo.id}>{data.linkTo.id}</Link> }
                            </td>
                            <td>
                                <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{Delete}</Button>
                                <Button color="warning" className="btn-xs mr-1 f-none m-t-20" >{Edit}</Button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    </Fragment>
  )
}

export default BannersTable
