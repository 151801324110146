import React, { Fragment, useState } from 'react'
import { Button, Card, CardBody, Container, Row, Media} from 'reactstrap'
import { Email, ForgotPassword, Password, SignUp, UpcomingEvents } from '../../../../constant'
import LabeledInput from '../../../general/LabeledInput'
import logo_light from '../../../../assets/images/creative-logo1(3).png'
import ScrollArea from 'react-scrollbar'
import Rating from 'react-rating'
import { CarouselUpcomingEvnts } from '../../../advance/carousel/CarouselComponent'
import '../../../../assets/css/evnt_cards.css'

export const LogIn_Card = (props) => {
    const playScene = () => {
        document.getElementById('sceneVideo').play()
        // document.getElementById('Streaming2D_VR').play()
    }

    return(
        <Fragment>
            <Card className={"evnt-card log-in-card"}>
                <CardBody style={{color:"white"}}>
                <img className="lightlogo log-in-logo" src={logo_light}  alt="" onClick={()=> playScene()}/>
                <h2><b>{"LOG IN"}</b></h2>
                <hr/>
                <LabeledInput label={Email} placeholder={Email} type={'email'}/>
                <br/>
                <LabeledInput label={Password} placeholder={Password} type={'password'}/>
                <br/>
                <p className={"pull-right"}>{ForgotPassword}</p>
                <Button className={"pull-left"} color="primary"
                    onClick={()=> props.logInUser()}>{"LOG IN"}</Button>
                <br/><br/>
                <p>{SignUp}</p>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export const LogInCatalog_Card = (props) => {

    const initilindex = {index:0,isOpen:false}
    const [photoIndex,setPhotoIndex] = useState(initilindex)
    const [rating,setRating] = useState(5)
    const [calendarView , setCalendarView] = useState(false)
    const [smallImages,setsmallImages] = useState([
        "01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg"
    ])

    return(
        <Fragment>
            <Card className={"evnt-card log-in-catalog"}>
                <CardBody style={{color:"white"}}>
                <h4><b>{UpcomingEvents}</b></h4>
                <hr/>
                    <Container fluid={true}>
                        <ScrollArea
                        speed={0.5}
                        horizontal={false}
                        vertical={true}
                        style={{height:"150px"}}>
                            <CarouselUpcomingEvnts style={{cursor:"pointer"}} upcomingEventBanners={["upcomingEvents_1.jpg","upcomingEvents_2.jpg","upcomingEvents_3.jpg"]} />
                            {calendarView ?
                                <Fragment>
                                    <br/>
                                    <Row>
                                    {smallImages.length > 0 ?
                                        smallImages.map((image,i)=>{
                                            return(
                                                <Fragment>
                                                    <figure className="col-xl-3 col-sm-6" style={{position:"relative",textAlign:"center",color:"white"}}>
                                                        <Media
                                                            src={require(`../../../../assets/images/big-lightgallry/${image}`)}
                                                            style={{width:"100%",borderRadius:"5px"}}
                                                            alt="Gallery"
                                                        />
                                                        <div style={{position:"absolute",bottom:"0px",left:"16px"}}>
                                                            <Button color="primary">{"$80 MXN"}</Button>
                                                            <div className="pull-right" style={{marginLeft:"15px"}}> 
                                                                <Rating
                                                                    initialRating={rating}
                                                                    emptySymbol="fa fa-star-o"
                                                                    fullSymbol="fa fa-star"
                                                                    onChange={(rate) => setRating(rate)}
                                                                 >
                                                                </Rating>
                                                            </div>
                                                        </div>
                                                    </figure>
                                                    <br/>
                                                </Fragment>
                                            )
                                        })
                                    : ""}
                                    </Row>
                                </Fragment>:""
                            }
                        </ScrollArea>
                    </Container> 
                </CardBody>
            </Card>
        </Fragment>
    )
}