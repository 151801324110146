import React,{useState,useEffect,Fragment} from 'react';
import {Link}  from 'react-router-dom'
import {Button} from 'reactstrap'
import {CreateShow,Shows, ShowsLists} from '../../../../../../constant'
import {useSelector,useDispatch} from 'react-redux'

import ShowsTable from './ShowsTable'

const ShowsData = (props) => {

  const dispatch = useDispatch()
  const editMode = useSelector(state => state.Common.editMode)
  const et = useSelector(state => state.Common.eventType)
  const [shows , setLocation] = useState(props.showsData)

  return (
    <Fragment>
    <h1>{Shows[et]}</h1>
    <hr/>
    <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`}> 
      <Button color="primary" className="mr-1 f-right m-t-20" ><i className="fa fa-plus-circle fa-lg"></i>&nbsp; &nbsp;{CreateShow}</Button>
    </Link>
    <br/>
    <ShowsTable showsData={shows}/>
    </Fragment>
  );
};

export default ShowsData;