import React, {Fragment, useState} from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import {File} from 'react-feather'
import {Container,Media,Button} from 'reactstrap'
import { DownloadFile, New,NewSale } from "../../constant";
import ScrollArea from 'react-scrollbar';

const DownloadableFiles = (props) => {
    const [files, setFiles] = useState(props.downloadableDocs)
    return (
        <Fragment>
            <Container fluid={true}>            
            <ScrollArea
              speed={0.5}
              horizontal={false}
              vertical={true}
              > 
              <div className="timeline-small">
                <br/>
                {
                  files.map((data,i)=>{
                    return(
                      <Media>
                        <div className="timeline-round m-r-30 timeline-line-1 bg-primary">
                            <File/>
                        </div>
                        <Media body>
                          <h6>{"FileName"}<span className="pull-right f-14">{"PDF / 00 KB"}</span></h6>
                          <Button  to="#javascript" color="primary" size="sm" outline>{DownloadFile}</Button>
                        </Media>
                      </Media>
                    )
                  })
                }
              </div>
            </ScrollArea>
            </Container>
        </Fragment>
    );
};

export default DownloadableFiles;