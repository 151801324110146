import React,{ useState, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import LeftVR_Types from './LeftVR_Types/LeftVR_Types'
import {HeaderEventCaster} from '../../components/general/UserThumbnail'

const LeftbarVR = (props) => {
  
  const event = useSelector(state => state.Common.currentEvent)
  const [perspective, setPerspective] = useState(props.perspectiveData)

  const [LeftBar,setLeftBar] = useState(false)
  function ToggleLeftBar() {
    setLeftBar(!LeftBar)
  }
  
  return(
    <Fragment>
      <div className="vertical-mobile-sidebar"><i className="fa fa-bars sidebar-bar"></i></div>
      <div className="nav-right col left-menu-header">
        <ul className={`nav-menus-left ${LeftBar? 'open': ''}`}>
          <li style={{cursor:"pointer",width:"300px"}}>
            <LeftVR_Types windowWidth={props.windowWidth} eventType={event.eventType} eventName={event.name} eventLocation={event.location.venue}/>
          </li>
          <li style={{cursor:"pointer",width:"300px"}}>
            <h5 style={{color:"white",marginBottom:'0.02rem'}}><b>{props.perspectiveName}</b></h5>
            <h6 style={{color:"white"}}><b><i className="fa fa-eye"></i>&nbsp;{perspective.attendance.length+'8,7231 '}</b></h6>
          </li>
          {/* <li style={{cursor:"pointer",color:"white",width:"250px"}} onClick={()=> props.checkIfIsOpen({type:"AudioSettings"})}>
            <HeaderEventCaster eventCasterID={props.eventCasterID}/>
          </li> */}
        </ul>
        {/* <div onClick={ToggleLeftBar} className="d-xl-none mobile-toggle-left pull-right">
          <MoreHorizontal/>
        </div> */}
      </div>
  </Fragment>
  )
}


export default LeftbarVR;