import React, { Fragment } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import {Container,Row,Col,Card,CardBody,Media,Button} from 'reactstrap'
import JobFilter from './Job-filter';
import {UIandUX_IT_Frontend_Developer,Live,New } from "../../constant";
import {Link}  from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'

const JobList = () => {
  
    const dispatch = useDispatch()

    return (
        <Fragment>
            <BreadCrumb  subparent="Job Search" title="List View"/>
            <Container fluid={true}>
                <Row>
                    <JobFilter />
                    <Col xl="9 xl-60">
                        <Card>
                        <div className="job-search">
                            <CardBody>
                                <Media>
                                    <img className="img-100 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                    <Media body>
                                    <h6 className="f-w-600">
                                        <a href="#javascript">{UIandUX_IT_Frontend_Developer}</a>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                                            <Button className="pull-right" color="primary">{"Join Experience"}</Button>
                                        </Link>
                                    </h6>
                                    <p>{"(L6) Salt Lake City, UT"}
                                        <br/>
                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                        <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                    </p>
                                    <p>
                                        {`We are looking for an experienced and creative designer and/or frontend engineer with expertise in accessibility to join our team ,
                                        3+ years of experience working in as a Frontend Engineer or comparable role. You won’t be a team of one though — you’ll be collaborating closely with other...`}
                                    </p>
                                    </Media>
                                </Media>
                            </CardBody>
                        </div>
                        </Card>
                        <Card>
                        <div className="job-search">
                            <CardBody>
                                <Media>
                                    <img className="img-100 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                    <Media body>
                                    <h6 className="f-w-600">
                                        <a href="#javascript">{UIandUX_IT_Frontend_Developer}</a>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                                            <Button  className="pull-right" color="primary">{"Join Experience"}</Button>
                                        </Link>
                                    </h6>
                                    <p>{"(L6) Salt Lake City, UT"}
                                        <br/>
                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                        <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                    </p>
                                    <p>
                                        {`We are looking for an experienced and creative designer and/or frontend engineer with expertise in accessibility to join our team ,
                                        3+ years of experience working in as a Frontend Engineer or comparable role. You won’t be a team of one though — you’ll be collaborating closely with other...`}
                                    </p>
                                    </Media>
                                </Media>
                            </CardBody>
                        </div>
                        </Card>
                        <Card>
                        <div className="job-search">
                            <CardBody>
                                <Media>
                                    <img className="img-100 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                    <Media body>
                                    <h6 className="f-w-600">
                                        <a href="#javascript">{UIandUX_IT_Frontend_Developer}</a>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                                            <Button  className="pull-right" color="primary">{"Join Experience"}</Button>
                                        </Link>
                                    </h6>
                                    <p>{"(L6) Salt Lake City, UT"}
                                        <br/>
                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                        <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                    </p>
                                    <p>
                                        {`We are looking for an experienced and creative designer and/or frontend engineer with expertise in accessibility to join our team ,
                                        3+ years of experience working in as a Frontend Engineer or comparable role. You won’t be a team of one though — you’ll be collaborating closely with other...`}
                                    </p>
                                    </Media>
                                </Media>
                            </CardBody>
                        </div>
                        </Card>
                        <Card>
                        <div className="job-search">
                            <CardBody>
                                <Media>
                                    <img className="img-100 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                    <Media body>
                                    <h6 className="f-w-600">
                                        <a href="#javascript">{UIandUX_IT_Frontend_Developer}</a>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                                            <Button  className="pull-right" color="primary">{"Join Experience"}</Button>
                                        </Link>
                                    </h6>
                                    <p>{"(L6) Salt Lake City, UT"}
                                        <br/>
                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                        <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                    </p>
                                    <p>
                                        {`We are looking for an experienced and creative designer and/or frontend engineer with expertise in accessibility to join our team ,
                                        3+ years of experience working in as a Frontend Engineer or comparable role. You won’t be a team of one though — you’ll be collaborating closely with other...`}
                                    </p>
                                    </Media>
                                </Media>
                            </CardBody>
                        </div>
                        </Card>
                        <Card>
                        <div className="job-search">
                            <CardBody>
                                <Media>
                                    <img className="img-100 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                    <Media body>
                                    <h6 className="f-w-600">
                                        <a href="#javascript">{UIandUX_IT_Frontend_Developer}</a>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                                            <Button  className="pull-right" color="primary">{"Join Experience"}</Button>
                                        </Link>
                                    </h6>
                                    <p>{"(L6) Salt Lake City, UT"}
                                        <br/>
                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                        <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                    </p>
                                    <p>
                                        {`We are looking for an experienced and creative designer and/or frontend engineer with expertise in accessibility to join our team ,
                                        3+ years of experience working in as a Frontend Engineer or comparable role. You won’t be a team of one though — you’ll be collaborating closely with other...`}
                                    </p>
                                    </Media>
                                </Media>
                            </CardBody>
                        </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default JobList;