import React,{useState,useEffect,Fragment} from 'react';
import axios from "axios"

import {Container,Row,Col,Button,Nav,NavItem,NavLink,TabContent,TabPane,InputGroup,InputGroupAddon,Input,Form,FormGroup,Label,Table,Media,Badge,Card,CardHeader,CardBody,CardFooter} from 'reactstrap'
import {ShowsLocations,Locations,LocationDetails,LocationThumbnail,LocationName,AdPlacements,Hotspots,Show,Perspectives,Sponsors,Save,Shows,Cancel,Title,FromDate,ToDate,Description, Eventt, Venue, VenueLocations, GeneralInfo, MainEventType, PlatformTypes, LocationType, DisplayTo, DisplayToOptions, LocationDescription, List, Add, Information, View} from '../../../../../../constant'
import {useSelector,useDispatch} from 'react-redux'

import GeneralInfoForm from './GeneralInfoForm'
import Data_2D from './2D/Data_2D'
import Data_3D from './3D/Data_3D'

const ViewTypes = (props) => {

  const dispatch = useDispatch()
  const editMode = useSelector(state => state.Common.editMode)
  const tableOrForm = useSelector(state => state.Common.tableOrForm)
  const et = useSelector(state => state.Common.eventType)

  const [BasicLineTab, setBasicLineTab] = useState('1')
  const [BottomLineTab, setBottomLineTab] = useState('1')
  const [VerticleTab, setVerticleTab] = useState('1')

  axios.defaults.baseURL = `http://localhost:4000`
  const [isLoading, setLoading] = useState(true)
  
  const [event , setEvent] = useState(props.eventData)

  return (
    <Fragment>
        <h1>{GeneralInfo}</h1>
        <hr/>
        <Row>
          <Col sm="2" xs="12">
            <Nav className="nav flex-column nav-pills">
              <NavItem>
                <NavLink   className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')} style={{cursor: "pointer"}} >{Information}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')} style={{cursor: "pointer"}} >{'1D ' + View}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')} style={{cursor: "pointer"}} >{'2D ' + View}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')} style={{cursor: "pointer"}} >{'3D ' + View}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')} style={{cursor: "pointer"}} >{'VR ' + View}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '6' ? 'active' : ''} onClick={() => setVerticleTab('6')} style={{cursor: "pointer"}} >{'AR ' + View}</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="10" xs="12">
             <TabContent activeTab={VerticleTab}>
              <TabPane  className="fade show" tabId="1">  
                <GeneralInfoForm eventData={event}/>
              </TabPane>
              <TabPane tabId="2">
                {'1D'}
              </TabPane>
              <TabPane tabId="3">
                <Data_2D/>
              </TabPane>
              <TabPane tabId="4">
                <Data_3D/>
              </TabPane>
              <TabPane tabId="5">
                {'VR'}
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        
    </Fragment>
  );
};

export default ViewTypes;