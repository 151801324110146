import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {useHistory,Link} from 'react-router-dom'
import {Row,Col,Card,Media,Button} from 'reactstrap'
import { Show, Upcoming } from '../../../../../constant'
import Rating from 'react-rating'

const ShowsGrid = (props) => {
    const history = useHistory()
    const clickApply = () => {
        history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`);
    }

    const [rating,setRating] = useState(0)
    const et = useSelector(state => state.Common.eventType)
    const [upcommingShows , setUpCommingShows] = useState(props.upcommingShowsData) 
    
    return (
        <Fragment>
            <h2><b>{Upcoming+' '+Show[et]}</b></h2>
            <hr/>
            <Row>
                {upcommingShows.map((data, i) => {
                    return (
                        <Col xl="4 xl-50 box-col-6" sm="6" key={i}>
                            <Card>
                                <div className="blog-box blog-grid text-center product-box">
                                    <div className="product-img">
                                        <Media className="img-fluid top-radius-blog" src={require(`../../../../../assets/images/avtar/${data.thumbnail}`)} alt="" />
                                        <div className="product-hover">
                                            <ul>
                                                {/* <li><i className="icon-link" onClick={() => clickApply()}></i></li> */}
                                                <li><i className="icon-calendar"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="blog-details-main">
                                        <ul className="blog-social">
                                            <li className="digits">{data.fromDate}</li>
                                            <li className="digits">{"by"}: {data.talent}</li>
                                            <li className="digits">{data.watching} {"Hits"}</li>
                                        </ul>
                                        <div style={{color:"#158df7"}}>
                                            <Rating
                                                initialRating={data.rating}
                                                emptySymbol="fa fa-star-o"
                                                fullSymbol="fa fa-star"
                                                onChange={(rate) => setRating(rate)}
                                            >
                                            </Rating>
                                        </div>
                                        <hr />
                                        <h6 className="blog-bottom-details">{data.description}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Fragment>
    );
};

export default ShowsGrid;