import React,{useState,useEffect,Fragment} from 'react';

import LabeledInput from '../../../../../general/LabeledInput'
import LabeledDropDown from '../../../../../general/LabeledDropDown'

import {Container,Row,Col,Button,Nav,NavItem,NavLink,TabContent,TabPane,InputGroup,InputGroupAddon,Input,Form,FormGroup,Label,Table,Media,Badge,Card,CardHeader,CardBody,CardFooter} from 'reactstrap'
import {ShowsLocations,Locations,LocationDetails,LocationThumbnail,
  LocationName,AdPlacements,Hotspots,Show,Perspectives,Sponsors,
  Save,Shows,Cancel,Title,FromDate,ToDate,Description, Eventt, 
  Venue, VenueLocations, GeneralInfo, MainEventType, PlatformTypes, 
  LocationType, DisplayTo, DisplayToOptions, LocationDescription, 
  List, Add, Information, View} from '../../../../../../constant'
import {useSelector,useDispatch} from 'react-redux'

const GeneralInfoForm = (props) => {

  const dispatch = useDispatch()
  const editMode = useSelector(state => state.Common.editMode)
  const [location , setLocation] = useState(props.locationData)

  return (
    <Fragment>
        <Row>
          <Col xl="12 xl-60">
            <Form>
              <FormGroup>
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="6">
                        <LabeledInput label={LocationName} placeholder={LocationName} type={'text'} value={location.name} onChange={() => console.error('Change This Value 1')}/>
                      </Col>
                      <Col lg='6'>
                        <LabeledDropDown label={DisplayTo+':'} options={DisplayToOptions} multiple={false}/>
                      </Col>
                      <Col lg="6">
                        <LabeledInput label={FromDate+' :'} placeholder={FromDate} type={'time'} value={location.fromDate} onChange={() => console.error('Change This Value 2')}/>
                      </Col>
                      <Col lg='6'>
                        <LabeledInput label={ToDate+' :'} placeholder={ToDate} type={'time'} value={location.toDate} onChange={() => console.error('Change This Value 3')}/>
                      </Col>
                      <Col lg='6'>
                        <LabeledDropDown label={LocationType} options={PlatformTypes} multiple={true}/>
                      </Col>
                      <Col lg='6'>
                        <LabeledInput label={LocationDescription} placeholder={LocationDescription} type={'textarea'} rows={4} value={location.description} />
                        <br/>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <LabeledInput label={LocationThumbnail} placeholder={LocationThumbnail} type={'file'} onChange={() => console.error('Change This Value 4')}/>
                    <Media style={{width:'100%'}} className="img-fluid m-r-20 p-2" src={require(`../../../../../../assets/images/blog/${location.thumbnail}`)} alt="Event Main Grafic" />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
            <div className="pull-right">
              <Button color="primary" className="mr-1" >{Save}</Button>
              <Button className="btn btn-light" type="reset" >{Cancel}</Button>
            </div>
          </Col>
        </Row>
    </Fragment>
  );
};

export default GeneralInfoForm;