import React, { useState, useEffect, Fragment } from 'react'
import {Table,Row,Col,Label, Media, Input,InputGroup,InputGroupAddon,Button,
Nav, NavItem, NavLink, TabPane, TabContent} from 'reactstrap'
import {BrandableAreas, MainBannerSlider, Whitelabeling} from "../../../../../../constant"

import BannersData from './MainBanners/BannersData'

const BrandableAreasData = (props) =>  {

  const [event , setEvent] = useState(props.eventData)
  const [VerticleTab, setVerticleTab] = useState('1')
  
  return (
    <Fragment>
        <h1>{BrandableAreas}</h1>
        <hr/>
        <Row>
          <Col sm="2" xs="12">
            <Nav className="nav flex-column nav-pills">
              <NavItem>
                <NavLink   className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')} style={{cursor: "pointer"}} >{Whitelabeling}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')} style={{cursor: "pointer"}} >{MainBannerSlider}</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="10" xs="12">
             <TabContent activeTab={VerticleTab}>
              <TabPane  className="fade show" tabId="1"> 
                <h5>{BrandableAreas+' / '+Whitelabeling}</h5>
                <hr/>
              </TabPane>

              <TabPane tabId="2">
                <BannersData bannersData={event.banners} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        
    </Fragment>
  )
}

export default BrandableAreasData
