import React, { useState, Fragment, useEffect } from 'react'
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {Container,Row,Col,Card,CardBody,CardHeader,Button,Input,Label,Form,FormGroup,Nav,NavItem,NavLink,TabPane,TabContent,Media} from 'reactstrap'
import CountUp from 'react-countup'
import {Follower,NewOrder,Facebook,Twitter,Instagram,Linkedin,ContactUs,
  YourName,Email,SEND_IT,Profit,Loss,Post,Like, Message,EventInfo,
  EventTitle,EventDescription,FacebookLink,InstagramLink,TwitterLink,
  LinkedinLink,LatestEventPosts,Attendance,Timeline,LatestPost, EventName, 
  Eventt, Homes} from "../../../../../constant"
import EventCounters from './EventCounters'
import UserCard from '../../Main/UserCard'
import EventData from './EventData'
import EventForm from '../EditView/EventForm'
import {useSelector} from 'react-redux'
import EventCountDown from "./EventCountDown"
import axios from 'axios'

const Event_1D = (props) =>  {
    
  const [event , setEvent] = useState(props.eventData)

  return (
    <Fragment>
        <BreadCrumb  subparent={Homes} title={event.name} statisticsBTN={true}/>
        <Container fluid={true}>
        {event.showCounters ?  <EventCounters/> : ''}
        <Row>
          <Col xl="4" className="xl-50 box-col-6">
            <EventCountDown eventName={event.name} eventLeftTime={5616} eventBeginningDate={event.fromDate} eventEndingDate={event.toDate} barPercentage={70}/>
            <UserCard/>
          </Col>
          <Col xl="8 box-col-6"><EventData eventData={event}/></Col>
          <Col sm="6" xl="3" lg="6" className="xl-50 box-col-6">
          <Card className="social-widget-card">
            <CardBody>
              <div className="redial-social-widget radial-bar-70" data-label="50%">
                <i className="fa fa-facebook font-primary"></i></div>
              <h5 className="b-b-light">{Facebook}</h5>
              <Row>
                <Col className="text-center b-r-light"><span>{Post}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={6589} /></h4>
                </Col>
                <Col className="text-center"><span>{Like}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={75269} /></h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
          <Col sm="6" xl="3" lg="6" className="xl-50 box-col-6">
          <Card className="social-widget-card">
            <CardBody>
              <div className="redial-social-widget radial-bar-70" data-label="50%">
                <i className="fa fa-google-plus font-primary"></i></div>
              <h5 className="b-b-light">{Instagram}</h5>
              <Row>
                <Col className="text-center b-r-light"><span>{Post}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={369} /></h4>
                </Col>
                <Col className="text-center"><span>{Follower}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={3458} />
                  </h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
          <Col sm="6" xl="3" lg="6" className="xl-50 box-col-6">
         <Card className="social-widget-card">
            <CardBody>
              <div className="redial-social-widget radial-bar-70" data-label="50%">
                <i className="fa fa-twitter font-primary"></i></div>
              <h5 className="b-b-light">{Twitter}</h5>
              <Row>
                <Col className="text-center b-r-light"><span>{Post}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={6589} />
                  </h4>
                </Col>
                <Col className="text-center"><span>Follower</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={75269} />
                  </h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
          <Col sm="6" xl="3" lg="6" className="xl-50 box-col-6">
          <Card className="social-widget-card">
            <CardBody>
              <div className="redial-social-widget radial-bar-70" data-label="50%">
                <i className="fa fa-linkedin font-primary"></i></div>
              <h5 className="b-b-light">{Linkedin}</h5>
              <Row>
                <Col className="text-center b-r-light"><span>{Post}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={1234} />
                  </h4>
                </Col>
                <Col className="text-center"><span>{Linkedin}</span>
                  <h4 className="counter mb-0">
                    <CountUp className="counter" end={4369} />
                  </h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
          <Col sm="12" xl="12" lg="12"  md="12" className="xl-50 box-col-6">
          <Card className="height-equal">
            <CardHeader>
              <h5>{ContactUs}</h5>
            </CardHeader>
            <CardBody className="contact-form">
              <Form className="theme-form">
                <div className="form-icon"><i className="icofont icofont-envelope-open"></i></div>
                <FormGroup>
                  <Label for="exampleInputName">{YourName}</Label>
                  <Input className="form-control" id="exampleInputName" type="text" placeholder="John Dio" />
                </FormGroup>
                <FormGroup >
                  <Label className="col-form-label" htmlFor="exampleInputEmail1">{Email}</Label>
                  <Input className="form-control" id="exampleInputEmail1" type="email" placeholder="Demo@gmail.com" />
                </FormGroup>
                <FormGroup >
                  <Label className="col-form-label" htmlFor="exampleInputEmail1">{Message}</Label>
                  <textarea className="form-control textarea" rows="3" cols="50" placeholder="Your Message"></textarea>
                </FormGroup>
                <div className="text-sm-right">
                  <Button className="btn btn-primary-gradien">{SEND_IT}</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </Container>
    </Fragment>
  )
}
export default Event_1D
