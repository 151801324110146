import { SET_GLOBAL_EVENT, SEARCH_TOGGLE, MOBILE_RIGHT_TOGGLE, RIGHT_SIDEBAR, 
  SWITCH_TOGGLE, SWITCH_DOC_TYPE, CHANGE_PLATFORM_TYPE, SET_2D_BACKGROUND,
  SET_EVENTCASTER} from '../actionTypes'

export const SearchBarToggle = (toggleVal) =>{  
  return(
    {
        type: SEARCH_TOGGLE,
        toggleVal
    }
  )
}

export const MobileRightToggle = (toggleVal) => {
  return(
    {
      type: MOBILE_RIGHT_TOGGLE,
      toggleVal
    }
  )
}

export const RightSidebarToggle = (toggleVal) => {
  return(
    {
      type: RIGHT_SIDEBAR,
      toggleVal
    }
  )
}

export const SwitchToggle = (toggleVal) => {
  return(
    {
      type: SWITCH_TOGGLE,
      toggleVal
    }
  )
}


// EVNT ACTIONS -----------------------------------------------
export const ChangePlatformType = (docType) =>{
  return(
    {
      type: CHANGE_PLATFORM_TYPE,
      docType:docType
    }
  )
}

export const SetGlobalEvent = (eventObj) => {
  return(
    {
      type: SET_GLOBAL_EVENT,
      eventObj:eventObj
    }
  )
}

export const SwitchDocType = (docType) =>{
  return(
    {
      type: SWITCH_DOC_TYPE,
      docType:docType
    }
  )
}

export const Set2DBackGround = (imgLink) =>{
  return(
    {
      type: SET_2D_BACKGROUND,
      url:imgLink
    }
  )
}

// export const setEventCaster = (CasterID) =>{
//   return(
//     {
//       type: SET_EVENTCASTER,
//       id:CasterID
//     }
//   )
// }










