import React,{useState,Fragment, useEffect} from 'react'
import {Label,Input} from 'reactstrap'

const LabeledDropDown = (props) => {
  const [labeledDropDown, setLadeldDropDown] = useState(props)
  return (
    <Fragment>
        {labeledDropDown.label != undefined ? <Label><b>{labeledDropDown.label}</b>&nbsp;</Label>:labeledDropDown.label}
        <Input id={labeledDropDown.id}  type="select" name="select" className={labeledDropDown.type} onChange={event => props.setSelectedOption(event.target.value)} multiple={labeledDropDown.multiple}>
          {labeledDropDown.options.map((option,i) =>
            <option key={i} value={option}>{option}</option>
          )}
        </Input>
    </Fragment>
  );
};

export default LabeledDropDown;