import React, { Fragment, useEffect, useState } from 'react';
import {Col, Card, Media, Badge} from "reactstrap"
import { Perspectives, Attendance, Watching} from "../../../../../constant";
import { Link } from 'react-router-dom'
import Rating from 'react-rating'

const LocationsGrid = (props) => {

    const [rating,setRating] = useState(0)

    const [locations , setLocations] = useState(props.locationsData)
    
    return (
        <Fragment>
            {locations.map((data,i) => {
                return(
                    <Col md="6"  xl="3 box-col-6" key={'generalLocations-'+i}>
                        <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowsListViews/ShowsListView`} style={{color:"black"}}>
                            <Card>
                                <div className="blog-box blog-grid text-center">
                                    <Media className="img-fluid top-radius-blog" style={{width:"100%"}} src={require(`../../../../../assets/images/blog/${data.thumbnail}`)} alt={data.thumbnail}/>
                                    <div className="blog-details-main" >
                                        <h4 className="blog-bottom-details">{data.name} &nbsp; <Badge color="success">{data.status}</Badge></h4>
                                        <hr/>
                                        <ul className="blog-social">
                                            <li className="digits">{data.fromDate}</li>
                                            <li className="digits">{data.toDate}</li>
                                        </ul>
                                        <ul className="p-b-5">
                                            <li style={{color:"#158df7"}}>
                                                <Rating
                                                    initialRating={data.rating}
                                                    emptySymbol="fa fa-star-o"
                                                    fullSymbol="fa fa-star"
                                                    onChange={(rate) => setRating(rate)}
                                                >
                                                </Rating>
                                            </li>
                                            {data.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+data.perspectives.length}</li> : ""}
                                            <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+data.watching.length}</li>
                                            <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+data.attendance.length}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                )
            })}
        </Fragment>
    );
};

export default LocationsGrid;