import React, { Fragment, useEffect, useState } from 'react';
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {Container, Row, Col, Card, Media, Badge} from "reactstrap"
import { VenueLocations, Venue, Eventt, Perspectives, Attendance, Watching} from "../../../../../constant";
import Rating from 'react-rating'
import { Link } from 'react-router-dom';
import LocationsGrid from '../Shared/LocationsGrid';

const Locations_1D = (props) => {

    const [rating,setRating] = useState(0)

    const [primaryLocation , setPrimaryLocation] = useState(props.primaryLocationData)
    const [mainLocations , setMainLocations] = useState(props.mainLocationsData)
    const [generalLocations , setGeneralLocations] = useState(props.generalLocationsData)

    return (
        <Fragment>
            <BreadCrumb  subparent={Venue} title={VenueLocations} addBtn={true} statisticsBTN={true}/>
            <Container fluid={true}>
                <Row>
                    <Col xl="6 box-col-12">
                        <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowsListViews/ShowsListView`}>
                            <Card>
                                <div className="blog-box blog-shadow">
                                    <Media className="img-fluid" style={{width:"100%"}} src={require(`../../../../../assets/images/blog/${primaryLocation.thumbnail}`)} alt={primaryLocation.thumbnail} />
                                    <div className="blog-details">
                                        <h1 style={{color:"white"}}><b>{primaryLocation.name}</b></h1>
                                        <p className="digits"><Badge color="success">{primaryLocation.status}</Badge> &nbsp; {primaryLocation.fromDate +' - '+ primaryLocation.toDate}</p>
                                        <h4>{primaryLocation.description}</h4>
                                        <ul className="">
                                            <li style={{color:"#158df7"}}>
                                                <Rating
                                                    initialRating={primaryLocation.rating}
                                                    emptySymbol="fa fa-star-o"
                                                    fullSymbol="fa fa-star"
                                                    onChange={(rate) => setRating(rate)}
                                                >
                                                </Rating>
                                            </li>
                                            {primaryLocation.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+primaryLocation.perspectives.length}</li> : ""}
                                            <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+primaryLocation.watching.length}</li>
                                            <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+primaryLocation.attendance.length}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    
                    <Col xl="6 box-col-12">
                    {mainLocations.map((data,i) => {
                        return(
                            <Link key={'mainLocations-'+i} to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowsListViews/ShowsListView`} style={{color:"black"}}>
                                <Card>
                                    <div className="blog-box blog-list row">
                                        <Col sm="5">
                                            <Media className="img-fluid sm-100-w" style={{width:"100%"}} src={require(`../../../../../assets/images/blog/${data.thumbnail}`)} alt={data.thumbnail} />
                                        </Col>
                                        <Col sm="7">
                                            <div className="blog-details p-15">
                                                <h3>{data.name}</h3>
                                                <h6 className="digits"><Badge color="success">{data.status}</Badge> &nbsp; {data.fromDate +' - '+ data.toDate}</h6>
                                                <div className="blog-bottom-content">
                                                    <ul>
                                                        <li>
                                                            <Rating
                                                                initialRating={data.rating}
                                                                emptySymbol="fa fa-star-o"
                                                                fullSymbol="fa fa-star"
                                                                onChange={(rate) => setRating(rate)}
                                                            >
                                                            </Rating>
                                                        </li>
                                                        {data.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+data.perspectives.length}</li> : ""}
                                                        <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+data.watching.length}</li>
                                                        <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+data.attendance.length}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="12" className="p-l-40">
                                            <p>{data.description}</p>
                                        </Col>
                                    </div>
                                </Card>
                            </Link>
                        )
                    })}
                    </Col>
                    
                    <LocationsGrid locationsData={generalLocations}/>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Locations_1D;