import React, { Fragment, useEffect, useState } from 'react'
import {Container} from "reactstrap"
import { Eventt,Venue,VenueLocations } from "../../../../../constant"
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {CarouselShows2D} from '../../../../advance/carousel/CarouselComponent'
import ShowsGrid from "../Shared/ShowsGrid"

const ShowList_2D = (props) => {

    const [liveShows , setLiveShows] = useState(props.liveShowsData)
    const [upcommingShows , setUpCommingShows] = useState(props.upcommingShowsData)
    
    return (
        <Fragment>
            <BreadCrumb  subparent={Venue} title={VenueLocations} addBtn={true} statisticsBTN={true}/>
            <Container fluid={true}>
                <CarouselShows2D liveShowsData={liveShows}/>
                <hr/>
                <ShowsGrid upcommingShowsData={upcommingShows}/>
                <br/>
            </Container>
        </Fragment>
    );
};

export default ShowList_2D;