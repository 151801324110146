import React,{Fragment, useState} from 'react';
import {InputGroup,InputGroupAddon,Input,Label,Table,Media,Badge,Button,Row,Col,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import {Link}  from 'react-router-dom'
import {Live,ShowArtist,ShowName,ArtistManager,Type,Hosted,CoverImage,
  SettingsEdit,SponsorAds,ShowSponsor,PerspectiveName,Streaming,Addons,
  Options,Hotspots,Status,Email,Add,Sponsors,ToList,Sponsor,Settings,
  Edit,New,Hotspot,AdPlacement,SimpleStatistics,Statistics,Delete,End,
  Beginning, Shows, List} from '../../../../../../constant'
import {UserThumbnail} from '../../../../../general/UserThumbnail'
import {useSelector, useDispatch} from 'react-redux'

const ShowsTable = (props) => {

  const dispatch = useDispatch()
  const editMode = useSelector(state => state.Common.editMode)
  const et = useSelector(state => state.Common.eventType)
  const [shows , setShow] = useState(props.showsData)

  return (
    <Fragment>
      <h5>{Shows[et]+' / '+List}</h5>
      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              <th scope="col">{"#"}</th>
              <th scope="col">{CoverImage}</th>
              <th scope="col">{ShowName}</th>
              <th scope="col">{ShowArtist}</th>
              <th scope="col">{ArtistManager}</th>
              <th scope="col">{ShowSponsor}</th>
              <th scope="col">{Beginning}</th>
              <th scope="col">{End}</th>
              <th scope="col">{Options}</th>
            </tr>
          </thead>
          <tbody>
          {shows.map((data, i) => {
            return (
            <tr key={"Show-"+data.id}>
              <th scope="row">{i}</th>
              <td><Media className="img-fluid img-60" src={require(`../../../../../../assets/images/avtar/${data.thumbnail}`)} alt="#"/></td>
              <td><h3><b>{data.name}</b></h3></td>
              <td><UserThumbnail userID={data.talents}/></td>
              <td><UserThumbnail userID={[1]}/></td>
              <td><UserThumbnail userID={[1]}/></td>
              <td><b>{data.fromDate}</b></td>
              <td><b>{data.toDate}</b></td>
              <td>
                <ul>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`}> 
                      <Button color="primary" size="xs">{SettingsEdit}</Button>
                    </Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/hospital`}> 
                      <Button color="primary" size="xs">{Statistics}</Button>
                    </Link>
                  </li>
                  <li><Button color="light" size="xs">{Delete}</Button></li>
                </ul>
              </td>
            </tr>
            )
          })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default ShowsTable;