import 'aframe'
import 'aframe-look-at-component'
import {Entity, Scene} from 'aframe-react'
import React, { Fragment} from 'react'
import { useSelector } from 'react-redux'

const VR_Objs = (props) => {

  return (
    <Fragment>
        { props.objs3d != undefined && props.objs3d.length > 0 ?
            props.objs3d.map((obj,i)=>{
              return(
              <Fragment>
                <Entity obj-model={{obj:obj.obj, mtl:obj.mtl}} 
                 position={{x: obj.position.x, y: obj.position.y, z:obj.position.z}}
                 rotation={{x: obj.rotation.x, y: obj.rotation.y, z:obj.rotation.z}}
                 scale={{x: obj.scale.x, y: obj.scale.y, z:obj.scale.z}}
                 events={
                    { 
                      click: () => props.AdClick(obj.ads)
                    }
                  }
                />
              </Fragment>
              )
            }): console.log("This perspective does not have 3D Objects")
        }
    </Fragment>  
  );
}
export default VR_Objs;
