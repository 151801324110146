import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react'
import { HalfTimeShow, Instructions } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow';

export const RemoteControl = (props) => {
    return(
        <Fragment>
            <Card style={{margin:"15px 30px", height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody style={{color:"white"}}>
                    <ul>
                        <li style={{margin:"10px 5px",cursor:"pointer"}}><i className="fa fa-star fa-sm"></i>{" Control"}</li>
                        <li style={{margin:"10px 5px",cursor:"pointer"}}><i className= "mdi mdi-cast-connected"></i>{" Change Event Caster"}</li>
                        <li style={{margin:"10px 5px",cursor:"pointer"}} onClick={() => console.log(true)} ><i className="fa fa-info-circle"></i>&nbsp;{"Promo Pop Up"}</li>
                        <li style={{margin:"10px 5px",cursor:"pointer"}} onClick={() => console.log({toLocationId:0,toPerspectiveId:1,type:undefined})} ><i className="mdi mdi-remote"></i>{HalfTimeShow}</li>
                    </ul>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default RemoteControl