import React, { Fragment,useState,useEffect } from 'react'
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {useHistory,Link} from 'react-router-dom'
import {Container, Row, Col, Card, Media, Button, Badge} from 'reactstrap'
import {GoTo,Show,Shows,ShowsLists, Eventt, Perspectives, Attendance, Watching} from "../../../../../constant"
import JobFilter from '../../../../jobSearch/Job-filter'
import {useSelector,useDispatch} from 'react-redux'
import Rating from 'react-rating'

import ShowsGrid from '../Shared/ShowsGrid'

const ShowsList_1D = (props) => {
    const history = useHistory()

    const clickApply = () => {
        history.push(`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`);
    }

    const [rating,setRating] = useState(0)

    const et = useSelector(state => state.Common.eventType)
    const [liveShows , setLiveShows] = useState(props.liveShowsData)
    const [upcommingShows , setUpCommingShows] = useState(props.upcommingShowsData)

    return (
        <Fragment>
            <BreadCrumb  subparent={Show[et]} title={ShowsLists} addBtn={true}/>
            <Container fluid={true}>
                <Row>
                    <JobFilter/>
                    <Col xl="9 xl-60">
                        <Row>
                            {liveShows.map((data,i) => {
                                return(
                                    <Col xl="12" key={"Show-"+data.id} >
                                        <Card>
                                            <Row className="blog-box blog-list">
                                                <Col sm="5">
                                                <Media className="img-fluid sm-100-w" style={{width:"100%"}} src={require(`../../../../../assets/images/avtar/${data.thumbnail}`)} alt={data.thumbnail}/>
                                            </Col>
                                                <Col sm="7">
                                                <div className="blog-details">
                                                    <h2><b>{data.name}</b></h2>
                                                    <div><Badge color="success">{data.status}</Badge> <b>Hosted by: {data.talent}</b></div>
                                                    <div className="blog-bottom-content">
                                                        <ul className="">
                                                            <li><b>From: </b>{data.fromDate} | <b>To: </b>{data.toDate}</li>
                                                        </ul>
                                                        <hr/>
                                                        <ul className="p-b-5">
                                                            <li style={{color:"#158df7"}}>
                                                                <Rating
                                                                    initialRating={data.rating}
                                                                    emptySymbol="fa fa-star-o"
                                                                    fullSymbol="fa fa-star"
                                                                    onChange={(rate) => setRating(rate)}
                                                                >
                                                                </Rating>
                                                            </li>
                                                            {data.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+data.perspectives.length}</li> : ""}
                                                            <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+data.watching.length}</li>
                                                            <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+data.attendance.length}</li>
                                                        </ul>
                                                        <hr/>
                                                        <p className="mt-0">{data.description}</p>
                                                        <br/>
                                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`}>
                                                            <Button color="primary btn-block" width="100%">{GoTo+' '+Show[et]}</Button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                        <ShowsGrid upcommingShowsData={upcommingShows}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ShowsList_1D;