import React, { useState, useEffect, Fragment } from 'react'
import {Table,Row,Col,Label, Media, Input,InputGroup,InputGroupAddon,Button,
Nav, NavItem, NavLink, TabPane, TabContent} from 'reactstrap'
import LabeledInput from '../../../general/LabeledInput'
import LabeledDropDown from '../../../general/LabeledDropDown'
import { Add, AddAdministrator, AdministratorCRUDPermissions, AdministratorEmail, 
    AdministratorPermissionsTypes, Administrators } from '../../../../constant'

const AdministratorsForm = (props) =>  {

  const [administrators , setAdministrator] = useState(props.administratorsData)
  
  return (
    <Fragment>
        <Row>
            <Col lg="12">
                <LabeledInput label={AdministratorEmail} placeholder={AdministratorEmail}/>
                <LabeledDropDown label={AdministratorCRUDPermissions} options={AdministratorPermissionsTypes} multiple={true}/>
                <Button color="primary" className="mr-1 f-right m-t-10" >{AddAdministrator}</Button>
            </Col>
        </Row>
    </Fragment>
  )
}

export default AdministratorsForm
