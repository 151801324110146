import React from 'react'
import { Button, Card, CardBody} from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap'
import CloseWindow from './2DAssets/CloseWindow'

export const AdMediaSrc = (props) => {

    const afterActivity = (actionAfterAd) => {
        switch(actionAfterAd.type){
            case"redirect":
                window.open(actionAfterAd.link,'_blank');
            break;
            case"changePerspective":
                props.changePerspective({toLocationId:actionAfterAd.toLocationId,toPerspectiveId:actionAfterAd.toPerspectiveId,type:undefined})
            break;
            default:
            break;
        }
        props.checkIfIsOpen({type:props.windowName})
    }

    switch(props.currentAd.media.type){
        case"image":
        return(
            <Fragment>
                <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                    <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                    <Media style={{width:"435px",height:"250px"}} src={props.currentAd.media.src} /> 
                    <br/>
                    <Button onClick={()=>{afterActivity(props.currentAd.actionAfterAd)}} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"+50 K"}</Button> 
                </Card>
            </Fragment>
        )
        case"video":
        return(
            <Fragment>
                <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                    <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                    <iframe width="435" height="250" src={props.currentAd.media.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                    <br/>
                    <Button onClick={()=> afterActivity(props.currentAd.actionAfterAd)} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"+5000 K"}</Button> 
                </Card>
            </Fragment>
        )
        case"youtube":
        return(
            <Fragment>
                <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                    <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                    <iframe width="435" height="250" src={props.currentAd.media.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                    <br/>
                    <Button onClick={()=> afterActivity(props.currentAd.actionAfterAd)} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"+5000 K"}</Button> 
                </Card>
            </Fragment>
        )
        case"iframe":
        return(
            <Fragment>
                <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                    <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                    <iframe width="435" height="430" src={props.currentAd.media.src} title="3D Experience"></iframe>  
                    <br/>
                    <Button onClick={()=> afterActivity(props.currentAd.actionAfterAd)} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"+50 K"}</Button> 
                </Card>
            </Fragment>
        )
    }
}

export default AdMediaSrc