import React, { Fragment } from 'react'
import {Card,CardBody,Button,Media,InputGroup,InputGroupAddon,Input} from 'reactstrap'
import one from "../../assets/images/user/1.jpg"
import timeline2 from "../../assets/images/social-app/timeline-2.png"
import { MoreVertical } from 'react-feather'
import { ELANA,IssaBell,MoreCommnets,JasonBorne,PostComment } from "../../constant"
import ScrollArea from 'react-scrollbar'

const PostFeed = () => {
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="new-users-social">
                        <Media>
                            <Media className="rounded-circle image-radius m-r-15" src={one} alt="" />
                            <Media body>
                                <h6 className="mb-0 f-w-700">{ELANA}</h6>
                                <p>{"January, 12,2019"}</p>
                            </Media><span className="pull-right mt-0"><MoreVertical /></span>
                        </Media>
                    </div>
                    <Media className="img-fluid" alt="" src={timeline2} />
                    <div className="timeline-content">
                        <p>
                            {PostComment}
                        </p>
                        <div className="like-content"><span><i className="fa fa-heart font-danger"></i></span><span className="pull-right comment-number"><span>{"20"} </span><span><i className="fa fa-share-alt mr-0"></i></span></span><span className="pull-right comment-number"><span>{"10"} </span><span><i className="fa fa-comments-o"></i></span></span></div>
                        <div className="social-chat">
                            <ScrollArea
                                speed={0.5}
                                horizontal={false}
                                vertical={true}>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{JasonBorne} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="your-msg">
                                    <Media>
                                        <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                        <Media body><span className="f-w-600">{IssaBell} <span>{"1 Year Ago"} <i className="fa fa-reply font-primary"></i></span></span>
                                            <p>{"we are doing dance and singing songs, please vote our post which is very good for all young peoples"}</p>
                                        </Media>
                                    </Media>
                                </div>
                                <div className="text-center"><a href="#javascript">{MoreCommnets}</a></div>
                            </ScrollArea>
                        </div>
                        <div className="comments-box">
                            <Media>
                                <Media className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={one} />
                                <Media body>
                                    <InputGroup className="text-box">
                                        <Input className="form-control input-txt-bx" type="text" name="message-to-send" placeholder="Post Your commnets" />
                                        <InputGroupAddon addonType="append">
                                            <Button color="transparent"><i className="fa fa-smile-o">  </i></Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Media>
                            </Media>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PostFeed;