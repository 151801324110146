import React,{Fragment, useEffect, useState} from 'react';
import { Badge,Media } from 'reactstrap'
import {UserThumbnail} from './UserThumbnail'
import ScrollArea from 'react-scrollbar'

const NotificationsList = (props) => {
  
  const [comments, setComment] = useState(props.commentsData)
  const height = props.height || "auto"

  return (
    <Fragment>
      <ScrollArea
        speed={0.5}
        horizontal={false}
        vertical={true}
        style={{height:height}}>
        <div className="chat">
          {comments.map((data,i)=>{
            return(
              <div>
                <div className="media p-b-5">
                  <UserThumbnail userID={[data.fromID]}/>
                  {data.type == "question" ? 
                  <div className="media-body">
                    <div className="pull-right">
                      <Badge color="primary" href="#javascript" className="pull-right" pill><i className="fa fa-thumbs-up"></i></Badge>
                      <br/>
                      <span className="pull-right">{"15 Votes"}</span>
                    </div>
                  </div>:""}
                </div>
                <div style={{backgroundColor:"#cdcdcd4d",borderRadius:"15px"}}>
                    <div className="message-main p-10">
                      <p className="mb-0 f-12"><b>{data.dateFrom}</b>
                      <br/>{data.text}</p>
                    </div>
                </div>
                <hr/>
              </div>
            )
          })}
        </div>
      </ScrollArea>
    </Fragment>
  );
};
export default NotificationsList;