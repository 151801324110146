import { SET_GLOBAL_EVENT, SEARCH_TOGGLE, MOBILE_RIGHT_TOGGLE, RIGHT_SIDEBAR, 
    SWITCH_TOGGLE, SWITCH_VRMODE,SWITCH_EDIT_MODE,ABLE_TO_EDIT,
    NOT_ABLE_TO_EDIT, SWITCH_DOC_TYPE, SWITCH_TABLE_TO_FORM,
    SWITCH_FORM_TO_TABLE, EDIT_MODE_FALSE, EDIT_MODE_TRUE,CHANGE_PLATFORM_TYPE,
    SET_2D_BACKGROUND, SET_EVENTCASTER} from '../actionTypes'
    
const Initial_state = {
    currentEvent:"",
    eventType: 0,
    mainType:"1D",
    docType:'EventHome',
    backImg2D:'',

    userDemo:false,

    platformTour:false,

    searchToggle: false,
    mobileRightToggle: false,
    rightSidebarToggle: false,
    switchToggle: false,

    // Event Object
    eventObj:'',
    eventCasterID:2
}

const Common = (state=Initial_state, action) => {
    console.log("00000000000000000",action.state)
    switch (action.type) {
        case SEARCH_TOGGLE:
             state.searchToggle = !state.searchToggle;
             return state;
        case MOBILE_RIGHT_TOGGLE:            
            state.mobileRightToggle = !state.mobileRightToggle;
            return state;
        case RIGHT_SIDEBAR:
            state.rightSidebarToggle = !state.rightSidebarToggle;
            return state;
        case SWITCH_TOGGLE:
            state.switchToggle = !state.switchToggle
            return state;

            
        case SET_GLOBAL_EVENT:
            state.currentEvent = action.eventObj
            return state;

        case SWITCH_DOC_TYPE:
            state.docType = action.docType
            return state;

        case CHANGE_PLATFORM_TYPE:
            state.mainType = action.docType
            return state;
            
        case SET_2D_BACKGROUND:
            state.backImg2D = action.url
            return state;
            
        case SET_EVENTCASTER:
            state.eventCasterID = action.id
            return state;

        default:
        return state
    }
}
export default Common;
