import React, { Fragment,useState,useEffect } from 'react';
import {useHistory,Link} from 'react-router-dom'
import {Row,Col,Card,Media,Button} from 'reactstrap'
import axios from 'axios'

const UpcomingConferences = (props) => {
    const history = useHistory();
    const [learnindData,setLearningData] = useState([])

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/api/learning.json`).then(res => setLearningData(res.data))
    },[])

    const clickApply = () => {
        history.push(`${process.env.PUBLIC_URL}/learning/learning-detail`);
    }

    return (
        <Fragment>
            <Row>
                {learnindData.map((data, i) => {
                    return (
                        <Col xl="4 xl-50 box-col-6" sm="6" key={i}>
                            <Card>
                                <div className="blog-box blog-grid text-center product-box">
                                    <div className="product-img">
                                        <Media className="img-fluid top-radius-blog" src={require('../../assets/images/' + data.img)} alt="" />
                                        <div className="product-hover">
                                            <ul>
                                                {/* <li><i className="icon-link" onClick={() => clickApply()}></i></li> */}
                                                <li><i className="icon-calendar"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="blog-details-main">
                                        <ul className="blog-social">
                                            <li className="digits">{data.date}</li>
                                            <li className="digits">{"by"}: {data.writer}</li>
                                            <li className="digits">{data.hits} {"Hits"}</li>
                                        </ul>
                                        <hr />
                                        <h6 className="blog-bottom-details">{data.short_description}</h6>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Fragment>
    );
};

export default UpcomingConferences;