import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios'
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {Container,Row,Col,Card,CardBody,Media,Button} from 'reactstrap'
import ExperiencesFilter from '../shared/ExperiencesFilter';
import Rating from 'react-rating'
import {Attendance, HostedBy, JoinExperience, Live,New, Type } from "../../../../../constant";
import {Link}  from 'react-router-dom'

const ExperiencesList_1D = (props) => {

    const [experiencesData , setExperiencesData] = useState(props.experiencesData)
    const [rating,setRating] = useState(0)

    const [experiences , setExperiences] = useState([])

    axios.defaults.baseURL = `http://localhost:4000`
    const [isLoading, setLoading] = useState(true)

    useEffect(()=>{
        var i = 0
        experiencesData.forEach(experience =>{

            var hostNames = ""
            var a = 0
            experience.hosts.forEach(hostID => {
            
                axios.get(`/users/${hostID}`)
                .then(res => {
    
                    const user = res.data
                    hostNames += ` ${user.name+' '+user.lastName} ,`
                    a++
                    if(a === experience.hosts.length){
                        console.log("Setting ",experience.name," hosts : ",hostNames)
                        experience.hostNames = hostNames
                    }
    
                }).catch(err => console.error("02",err))

            })

            setExperiences( newExperience => [...newExperience,experience])
            i++
            if(i === experiencesData.length){
                setLoading(false)
            }else{
                console.log("The ",experience.name," hosts are:", experience.hostNames)
            }
        })
    },[])
      
    if (isLoading) {
      return <div className="App">Loading...</div>;
    }

    return (
        <Fragment>
            <BreadCrumb  subparent="Job Search" title="List View" addBtn={true}/>
            <Container fluid={true}>
                <Row>
                    <ExperiencesFilter />
                    <Col xl="9 xl-60">
                        {
                            experiences.map((data,i)=>{
                                return(
                                    <Card>
                                        <div className="job-search">
                                            <CardBody>
                                                <Media>
                                                    <img className="img-100 img-fluid m-r-20" src={require(`../../../../../assets/images/job-search/${data.thumbnail}`)} alt=""/>
                                                    <Media body>
                                                    <h6 className="f-w-600">
                                                        <a href="#javascript">{data.name}</a>
                                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperienceView/ExperienceView`}> 
                                                            <Button className="pull-right" color="primary">{JoinExperience}</Button>
                                                        </Link>
                                                    </h6>
                                                    <b>{HostedBy+": "+data.hostNames}</b>
                                                    <p>{data.fromDate+" - "+data.toDate}
                                                        <br/>
                                                        <span><b>{Type+": "+data.type+" / "}</b></span> &nbsp;
                                                        <span><b>{Attendance+": "+data.attendance.length}</b></span> &nbsp;
                                                        <span className="badge badge-success">{Live}</span> &nbsp;
                                                        <span style={{color:"#158df7"}}>
                                                            <Rating
                                                                initialRating={data.rating}
                                                                emptySymbol="fa fa-star-o"
                                                                fullSymbol="fa fa-star"
                                                                onChange={(rate) => setRating(rate)}
                                                             >
                                                            </Rating>
                                                        </span>
                                                    </p>
                                                    <p>{data.description}</p>
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </div>
                                    </Card>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ExperiencesList_1D;