import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody, Col, Label, Row} from 'reactstrap'
import { Fragment } from 'react'
import { AudioControls, SportsCaster, VolumeLevels } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow';
import UsersList from '../../../users/UsersList';
import InputRange from 'react-input-range';
import ScrollArea from 'react-scrollbar';
import '../../../../assets/css/evnt_cards.css'

export const AudioSettings = (props) => {
  
  const initialslider = {
    value: 10,
    nagative: -10,
    foramtedLabel: 10,
    disabled: 10,
    foramtedLabelKg: 5,
    draggable: {
      min: 3,
      max: 7,
    },
  }

  const [values, setValues] = useState(initialslider);

  const initialslider2 = {
    value: 10,
    nagative: -10,
    foramtedLabel: 10,
    disabled: 10,
    foramtedLabelKg: 5,
    draggable: {
      min: 3,
      max: 7,
    },
  }
  const [values2, setValues2] = useState(initialslider2);
  const windowWidth = props.windowWidth
  const [windowType, setWindowType] = useState(null)
  const [castersHeight, setCastersHeight] = useState("80px")
  useEffect(()=>{
      if(windowWidth<= 1000 && windowWidth >= 425){
        setCastersHeight("120px")
        setWindowType("evnt-card-lg")
      }else{
        setCastersHeight("80px")
        setWindowType("evnt-card-sm")
      }
  },[windowWidth])

  return(
      <Fragment>
        <Card className={`evnt-card ${windowType}`}>
          <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
          <CardBody style={{color:"white"}}>
            <div className="location-item-layout">
              <div className="evnt-audio-casters">
                <h2 className="evnt-card-title"><b>{AudioControls}</b></h2>
                <h5>{SportsCaster}</h5>
                <ScrollArea
                  speed={0.5}
                  horizontal={false}
                  vertical={true}
                  style={{height:castersHeight}}>
                  <UsersList  listType={"casters"} usersList={[2,3,6]} searchBar={false} setSportCaster={props.setSportCaster}/>
                </ScrollArea>
              </div>
              <div className="evnt-audio-levels">
                <hr/>
                <h5>{VolumeLevels}</h5>
                <br/>
                <Row className="mb-0">
                    <Col md="3" sm="12">
                      <Label>{"Caster"}</Label>
                    </Col>
                    <Col md="9" sm="12">
                      <InputRange
                        maxValue={20}
                        minValue={0}
                        value={values.value}
                        onChange={(value) =>
                          setValues({ ...values, value: value })
                        }
                      />
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col md="3" sm="12">
                      <Label>{"Stadium"}</Label>
                    </Col>
                    <Col md="9" sm="12">
                      <InputRange
                        maxValue={20}
                        minValue={0}
                        value={values2.value}
                        onChange={(value) =>
                          setValues2({ ...values2, value: value })
                        }
                      />
                    </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </Fragment>
  )
}

export default AudioSettings