import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react'
import { Media,Nav,NavItem,NavLink,TabContent,TabPane } from 'reactstrap'
import ScrollArea from 'react-scrollbar'
import UsersList from '../../../users/UsersList'
import CloseWindow from './2DAssets/CloseWindow'
import { Contacts, Groups } from '../../../../constant'
import UsersGroups from '../../../users/UsersGroups'

export const UsersList360 = (props) => {
    const [IconWithTab, setIconWithTab] = useState('1')

    const userGroups = [
        {
            groupName:"La Monumental",
            img:"user-card/group1.jpg"
        },
        {
            groupName:"Futboleros",
            img:"user-card/group2.jpg"
        },
        {
            groupName:"Cascarita",
            img:"user-card/group3.jpg"
        }
    ]

    return(
        <Fragment>
            <Card style={{margin:"15px 30px", width:"300px",height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink style={{cursor:"pointer"}} className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}><i className="fa fa-user"></i>{Contacts}</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{cursor:"pointer"}} className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}><i className="fa fa-users"></i>{Groups}</NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={IconWithTab}>
                      <TabPane  className="fade show" tabId="1">
                        <br/>
                        <div onClick={() => props.checkIfIsOpen({type:"ChatTab",data:{name:"Martina Wellmer",img:`user-card/5.jpg`}})}>
                          <UsersList  listType={"users"} usersList={props.usersList} height={"230px"}/>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <br/>
                        <UsersGroups userGroups={userGroups} height={"230px"} checkIfIsOpen={props.checkIfIsOpen}/>
                      </TabPane>
                    </TabContent> 
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default UsersList360