import React, { useState, Fragment, useEffect } from 'react'
import {Row,Col,Card,CardBody,CardHeader,CardFooter,Button,Input,Label,Form,FormGroup,Nav,NavItem,NavLink,TabPane,TabContent,Media} from 'reactstrap'
import {EventName,EventInfo,EventDescription,LatestEventPosts,Attendance,Timeline,LatestPost, EventTimeline} from "../../../../../constant"
import EventTimeLine from '../../../../calendar/EventTimeLine'
import UsersList from '../../../../users/UsersList'
import {useSelector} from 'react-redux'
import NewsFeed from '../../../../users/NewsFeed'
import axios from 'axios'

const EventData = (props) =>  {
    const [activeTab, setActiveTab] = useState('1')
    const editMode = useSelector(state => state.Common.editMode)
    const [event , setEvent] = useState(props.eventData)

    /*
    function importAll(r) {
      let images = {};
      r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
      return images;
    }
    const mainGrafic = importAll(require.context('../../../assets/images/banner/', false, /\.(png|jpe?g|svg)$/));
    */

    return (
      <Fragment>
        <Card>
          <CardHeader>
              <h1><b>{event.name}</b></h1>
          </CardHeader>
          <CardBody>
            <div className="tabbed-card">
              <Nav tabs className="pull-right  nav-pills nav-primary">
                  <NavItem>
                      <NavLink className={activeTab === '1' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('1')}>
                          {EventInfo}
                      </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink className={activeTab === '2' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('2')}>
                          {LatestPost}
                      </NavLink>
                  </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col lg="4">
                        <h4><b>{EventDescription}</b></h4>
                        <hr/>
                        <p className="mb-0">{event.description}</p>
                      </Col>
                      <Col lg="8">
                        <Media style={{width:'100%'}} className="img-fluid m-r-20" src={require(`../../../../../assets/images/banner/${event.mainGrafic.src}`)} alt="Event Main Grafic" />
                      </Col>
                      <Col lg="3">
                        <br/>
                        <h4><b>{Attendance}</b></h4>
                        <hr/>
                        <UsersList listType={"users"} usersList={event.attendance}/>
                      </Col>
                      <Col lg="9">
                        <br/>
                        <h4><b>{EventTimeline}</b></h4>
                        <hr/>
                          <EventTimeLine locationsList={event.locations}/>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <h4><b>{LatestEventPosts}</b></h4>
                    <hr/>
                    <NewsFeed/>
                  </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Card>
      </Fragment>
    )
}

export default EventData
