import React, { useState, Fragment, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Set2DBackGround} from '../../../redux/common/actions'

import LocationForm from './LocationsViews/EditView/LocationForm'
import Locations_1D from './LocationsViews/1D/Locations_1D'
import { Media } from 'reactstrap'
import Locations_2D from './LocationsViews/2D/Locations_2D'

const Locations = () =>  {

  const dispatch = useDispatch()
  const mainType = useSelector(state => state.Common.mainType)
  const locations = useSelector(state => state.Common.currentEvent.locations)
  const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
  const [isLoading, setLoading] = useState(true)

  //LOCATIONS DATA -------------------------------------------------------------
  const [primaryLocation , setPrimaryLocation] = useState({})
  const [mainLocations , setMainLocations] = useState([])
  const [generalLocations , setGeneralLocations] = useState([])
  useEffect(() => {

    console.log("Setting Locations 2D BackGround ---------------------------------------")
    platformtypes.map(view =>{
      if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.venue))}
    }) 

    console.log("Setting locations ---------------------------------------")
    var i = 0
    locations.forEach(location => {
      if(location.type === "primary"){
        setPrimaryLocation(location)
        console.log('Setting Primary Location: ',location)
      }else if(location.type === "secondary"){
        setMainLocations(mainLocations => [...mainLocations,location])
        console.log('Setting Main Location: ',location)
      }else{
        setGeneralLocations(generalLocations => [...generalLocations,location])
        console.log('Setting Normal Location: ',location)
      }
      i++
      if(i === locations.length){
        setLoading(false)
      }
    })
  },[])

  
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  switch(mainType){
    case"Admin":
      return (
        <Fragment>
          <LocationForm locationsData={locations}/>
        </Fragment>
      )
    break;
    default:
    case"1D":
      return (
        <Fragment>
          <Locations_1D
            primaryLocationData={primaryLocation}
            mainLocationsData={mainLocations}
            generalLocationsData={generalLocations} />
        </Fragment>
      )
    break;
    case"2D":
      return (
        <Fragment>
          <Locations_2D locationsData={locations} generalLocationsData={generalLocations} />
        </Fragment>
      )
    break;
  }


}
export default Locations
