import React, {useState,Fragment} from 'react'
import CountUp from 'react-countup'
import {FromDate,ToDate} from "../../../../../constant"

const EventCountDown = (props) =>  {

    const [eventCountDown, setCountDown] = useState(props);
    return (
      <Fragment>
        <div className="card xl-none">
          <div className="ecommerce-widget card-body">
            <div className="row">
              <div className="col-4"><span>{eventCountDown.eventName}</span>
                <h3 className="total-num CountDown">
                    <CountUp className="CountDown" end={eventCountDown.eventLeftTime} />
                </h3>
              </div>
              <div className="col-8">
                <div className="text-md-right">
                  <ul>
                    <li>{FromDate}<span className="product-stts font-primary ml-2">{eventCountDown.eventBeginningDate}<i className="icon-angle-up f-12 ml-1"></i></span></li>
                    <li>{ToDate}<span className="product-stts font-primary ml-2">{eventCountDown.eventEndingDate}<i className="icon-angle-down f-12 ml-1"></i></span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="progress-showcase">
              <div className="progress lg-progress-bar">
                <div className="progress-bar bg-primary" role="progressbar" style={{ width: "70%" }} aria-valuenow={eventCountDown.barPercentage} aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
}

export default EventCountDown
