import React,{Fragment} from 'react';
import {Form,FormGroup,Button,Input} from 'reactstrap'
import user from '../../assets/images/user/2.jpg'
import user2 from '../../assets/images/user/12.jpg'
import ScrollArea from 'react-scrollbar';

const ConferenceChat = (props) => {
      return (
          <Fragment>
            <ScrollArea
                speed={0.5}
                horizontal={false}
                vertical={true}>
                <div className="chat">
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:07 PM"}</span>
                      <div className="message-main">
                        <p className="mb-0 f-12">{"Lorem  Ipsum is  simply  dummy text  of the printing and typesetting."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media right-side-chat">
                    <div className="media-body text-right"><span className="f-12 d-block">{"Message seen 4:10 PM"}</span>
                      <div className="message-main pull-right">
                        <p className="mb-0 text-left">{"Lorem  Ipsum is  simply  dummy text  of the printing."}</p>
                        <div className="clearfix"></div>
                      </div>
                    </div><img className="rounded-circle chat-user-img img-60 m-l-20" src={user} alt=""/>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="media left-side-chat"><img className="rounded-circle chat-user-img img-60 m-r-20" src={user2} alt=""/>
                    <div className="media-body"><span className="f-12">{"Message seen 4:15 PM"}</span>
                      <div className="message-main">
                        <p>{"Lorem  Ipsum is  simply  dummy."}</p>
                      </div>
                    </div>
                  </div>
                </div>
            </ScrollArea>
            <Form>
                <FormGroup>
                    <Input className="form-control"  type="text" name="inputPassword" placeholder="Chat / Comment" autoComplete="off" />
                </FormGroup>
                <FormGroup>
                    <Button color="primary">{'Send'}</Button>
                </FormGroup>
            </Form>
          </Fragment>
      );
  };
  
  export default ConferenceChat;