import React,{useState,useEffect,Fragment} from 'react';
import BreadCrumb from '../../../layout/Breadcrumb'
import { useSelector, useDispatch } from 'react-redux';
import {Container,Row,Col,Card,Button,Input,Media,Modal, ModalHeader,ModalBody,InputGroup,InputGroupAddon,InputGroupText} from 'reactstrap'
import {ADD_TO_CART,ADD_TO_WISHLIST} from '../../../redux/actionTypes'
import {Link,useHistory} from 'react-router-dom'
import errorImg from '../../../assets/images/search-not-found.png';
import {getVisibleproducts} from '../../../services/ecommerce.service'
import  {watchfetchProducts} from "../../../redux/ecommerce/Product/action"
import ProductFilter from './filters/filters'
import ProductGrid from './ProductGrid'

const  Product = (props) =>  {
  
    const  history = useHistory();
    const dispatch = useDispatch()
    const data = useSelector(content => content.data.productItems);
    // eslint-disable-next-line 
    const [layoutColumns] = useState(3);
    const symbol = useSelector(content => content.data.symbol);
    const [open, setOpen] = useState(false);
    const [singleProduct, setSingleProduct] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    // eslint-disable-next-line
    const [setStock] = useState('');
    const [quantity, setQuantity] = useState(1);
    const filters = useSelector(content => content.filters);
    const products = getVisibleproducts(data, filters)

    
    useEffect(() => {
      dispatch(watchfetchProducts())
    },[dispatch]);

    const onOpenModal = (productId) => {
        setOpen(true);
        products.forEach((product, i) => {
          if (product.id === productId) {
            setSingleProduct(product)
          }
        })
      };

    const onCloseModal = () => {
      setOpen(false)
    };


  const minusQty = () => {
    if (quantity > 1) {
      setStock('InStock')
      setQuantity(quantity - 1)
    }
  }

  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value))
  }

  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1)
    } else {
      setStock('Out of Stock !')
    }
  }

    const addcart = (product, qty) => {
      dispatch({ type: ADD_TO_CART, payload: { product, qty } })
      history.push(`${process.env.PUBLIC_URL}/ecommerce/cart`);
    }

    const addWishList = (product) => {
      dispatch({ type: ADD_TO_WISHLIST, payload: {product} });
      history.push(`${process.env.PUBLIC_URL}/ecommerce/wishlist`);
    }

    const onClickDetailPage = (product) => {
      const id = product.id;
      history.push(`${process.env.PUBLIC_URL}/ecommerce/product-detail/${id}`)
    }

        
    return (
        <Fragment>
          <BreadCrumb  subparent="Ecommerce" title="Product" addBtn={true} settingsBTN={true}/>
          <Container fluid={true} className="product-wrapper">
            <ProductFilter/>
            <ProductGrid/>  
          </Container>
        </Fragment>
    );
}

export default Product;