import React, { useState, Fragment, useEffect } from 'react'
import { Eventt, Homes } from '../../../../../constant'
import BreadCrumb from '../../../../../layout/Breadcrumb'

const Event_2D = (props) =>  {
    
  const [event , setEvent] = useState(props.eventData)

  return (
    <Fragment>
      {/* <BreadCrumb  subparent={Homes} title={event.name} statisticsBTN={true}/> */}
      {/* <Media src={'https://image.shutterstock.com/shutterstock/photos/1437565484/display_1500/stock-photo-stockholm-sweden-june-entrance-of-the-lollapalooza-music-festivals-first-apperance-in-1437565484.jpg'}/> */}
    </Fragment>
  )
}
export default Event_2D
