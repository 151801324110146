import React, { useState, useEffect, Fragment } from 'react'
import {Button} from 'reactstrap'
import { BannersListData, BrandableAreas, CreateBanner, MainBannerSlider } from '../../../../../../../constant'
import BannersForm from './BannersForm'
import BannersTable from './BannersTable'

const BannersData = (props) =>  {

  const [banners , setBanners] = useState(props.bannersData)
  const [tableOrForm, setTableOrForm] = useState(true)
  
  return (
    <Fragment>
    <h5>{BrandableAreas+' / '+MainBannerSlider}</h5> 
    {tableOrForm?
      <Button color="primary" className="mr-1" onClick={() => setTableOrForm(!tableOrForm)} ><i className="fa fa-plus-circle fa-lg"></i>&nbsp; &nbsp;{CreateBanner}</Button>:
      <Button color="primary" className="mr-1" onClick={() => setTableOrForm(!tableOrForm)} ><i className="fa fa-list fa-lg"></i>&nbsp; &nbsp;{BannersListData}</Button>
    }
    <br/>
    {tableOrForm? <BannersTable bannersData={banners.items}/>:<BannersForm/>}
    </Fragment>
  )
}

export default BannersData
