import React,{Fragment,useState} from 'react';
import { Collapse } from 'reactstrap';
import { Card,CardHeader,CardBody,Input,Media,Button,Form,FormGroup} from 'reactstrap';
import {Attendance} from '../../constant';
import UsersList from "./UsersList"

const UsersListCard = (props) => {
    const [isMutual, setisMutual] = useState(true);
    const [usersList , setUsersList] = useState(props.usersList)
    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <h5 className="mb-0">
                        <Button color="link pl-0" onClick={() => setisMutual(!isMutual)}
                            data-toggle="collapse" data-target="#collapseicon6" aria-expanded={isMutual} aria-controls="collapseicon6">{Attendance}</Button>
                    </h5>
                </CardHeader>
                <Collapse isOpen={isMutual}>
                    <CardBody className="social-status filter-cards-view">
                        <UsersList  listType={"users"} usersList={usersList}/>
                    </CardBody>
                </Collapse>
            </Card>
            </Fragment>
    );
}

export default UsersListCard;