import React,{Fragment, useEffect, useState} from 'react';
import {Button, Badge, Input,InputGroup,InputGroupAddon,Form,Media} from 'reactstrap'
import {TextMessage,Send, ChattingWith} from '../../constant'
import {UserThumbnail} from './UserThumbnail'
import ScrollArea from 'react-scrollbar'

import LabeledDropDown from './LabeledDropDown'

const CommentsList = (props) => {
  
  const [comments, setComment] = useState(props.commentsData)
  const height = props.height || "auto"

  return (
    <Fragment>
      {props.type == "questions"?
      <Form>
        <Button to="#javascript" color="primary" outline
          style={{width:"100%"}}>{"Top 10"}</Button>
          <br/>
      </Form>:""}  
      {props.type == "comments" && props.attendanceData != undefined?
      <Form>
        <LabeledDropDown label={ChattingWith} options={["Hosts","Everyone","Name 01",,"Name 02"]} multiple={false}/>
        <br/>
      </Form>:""}   
      <ScrollArea
        speed={0.5}
        horizontal={false}
        vertical={true}
        style={{height:height}}>
        <div className="chat">
          {comments.map((data,i)=>{
            return(
              <div>
                <div className="media p-b-5">
                  <UserThumbnail userID={[data.fromID]}/>
                  {data.type == "question" ? 
                  <div className="media-body">
                    <div className="pull-right">
                      <Badge color="primary" href="#javascript" className="pull-right" pill><i className="fa fa-thumbs-up"></i></Badge>
                      <br/>
                      <span className="pull-right">{"15 Votes"}</span>
                    </div>
                  </div>:""}
                </div>
                <div style={{backgroundColor:"#d1e0e8",borderRadius:"15px"}}>
                    <div className="message-main p-10">
                      <p className="mb-0 f-12"><b>{data.dateFrom + "- 5 min ago"}</b>
                      <br/>{data.text}</p>
                      {data.content != undefined ?
                      <Media style={{width:"50px",marginRight:"0px"}} src={require(`../../assets/images/stickers/${data.content.src}`)} />:""}
                    </div>
                </div>
                <hr/>
              </div>
            )
          })}
        </div>
      </ScrollArea>
      <InputGroup className="text-box">
        <Input
            type="text"
            className="form-control input-txt-bx"
            placeholder={TextMessage}
        />
        <InputGroupAddon addonType="append">
            <Button color="primary">{Send}</Button>
        </InputGroupAddon>
      </InputGroup>
    </Fragment>
  );
};
export default CommentsList;