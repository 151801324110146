import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { toast } from 'react-toastify';

const PopUpNotifications = (props) => {

    const basictoaster = (toastname,text) => {
        switch (toastname) {
            case 'successNotification':
                toast.info("Success Notification !", {
                    position: toast.POSITION.TOP_RIGHT
                });
            break;
            case 'adviceNotification':
                toast.info(text, {
                position: toast.POSITION.BOTTOM_RIGHT
                });
            break;
            case 'basicwarningToast':
            toast.warn("Warning Notification !", {
            position: toast.POSITION.TOP_RIGHT
            });
            break;
            case 'basicdangerToast':
                toast.error("Danger Notification !", {
                position: toast.POSITION.TOP_RIGHT
                });
            break;
            default:
            break;
        }
    }

    var advices = [
        "Vamos America!",
        "No olvides ordenar tu comida dando click AQUÍ",
        "En el show de medio tiempo: J Balvin",
        "¿Ya desbloqueaste nuestro palco VIP?",
        "¿Ya desbloqueaste nuestro palco VIP?",
        "15% en la Nike Store con código: EVNT , Da click AQUÍ para canjear",
        "Recuerda participar en la subasta por el jersey del América",
        "Logo patrocinador (con acceso a home page)"
    ]

    useEffect(()=>{
        var i = 0
        var alertNotifications = setInterval(function () {
            if(i<advices.length){
                basictoaster("adviceNotification",advices[i])
                i++
            }else{
                i = 0
                basictoaster("adviceNotification",advices[i])
            }
          }, 240000)
    },[])
  return (
    <Fragment>
    </Fragment>
  )
}
  export default PopUpNotifications