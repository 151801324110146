import React, { Fragment, useState } from 'react'
import { Collapse } from 'reactstrap'
import {Row,Col,Card,CardHeader,CardBody,Media,Input,Label,Button,Form,FormGroup} from 'reactstrap'
import {SocialNetworks,Facebook,Instagram,Twitter,Linkedin,Talent,Show,Sponsors,Connect,SponsorDescription} from "../../constant";
import timeline3 from "../../assets/images/social-app/timeline-3.png"
import UsersListCard from "../users/UsersListCard"
import {Link}  from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import {useSelector} from 'react-redux'

const ShowLeftBar = () => {
    const [isFilter, setIsFilter] = useState(true)
    const [isDesign, setIsDesign] = useState(true)
    const [isDevelopment, setIsDevelopment] = useState(true)
    const [isIntro, setisIntro] = useState(true)
    const userDemo = useSelector(state => state.Common.userDemo)
    const editMode = useSelector(state => state.Common.editMode)
    const et = useSelector(state => state.Common.eventType)

    return (
        <Fragment>
            <Col xl="3 xl-40">
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader>
                                    <h5 className="mb-0">
                                        <Button color="link pl-0" onClick={() => setIsDesign(!isDesign)}
                                            data-toggle="collapse" data-target="#collapseicon1" aria-expanded={isDesign} aria-controls="collapseicon1">{Talent[et]}</Button>
                                    </h5>
                                </CardHeader>
                                <Collapse isOpen={isDesign}>
                                    <ScrollArea
                                        speed={0.5}
                                        horizontal={false}
                                        vertical={true}>
                                        <CardBody>
                                            <Media>
                                                <img className="img-40 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                                <Media body>
                                                    <h6 className="f-w-600">
                                                        <Link to={`${process.env.PUBLIC_URL}/pages/sponsors/sponsor2D`}> 
                                                            {'User Name Last Name'}
                                                        </Link>
                                                    </h6>
                                                    <p>{'User Position'}, {'User Company'}</p>
                                                </Media>
                                            </Media>
                                            <Button color="primary mb-2 text-center"><i className="fa fa-user-plus"></i>  {Connect}</Button>
                                            {userDemo? <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>:''}
                                        </CardBody>
                                        <CardBody>
                                            <Media>
                                                <img className="img-40 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                                <Media body>
                                                    <h6 className="f-w-600">
                                                        <Link to={`${process.env.PUBLIC_URL}/pages/sponsors/sponsor2D`}> 
                                                            {'User Name Last Name'}
                                                        </Link>
                                                    </h6>
                                                    <p>{'User Position'}, {'User Company'}</p>
                                                </Media>
                                            </Media>
                                            <Button color="primary mb-2 text-center"><i className="fa fa-user-plus"></i>  {Connect}</Button>
                                            {userDemo? <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>:''}
                                        </CardBody>
                                        <CardBody>
                                            <Media>
                                                <img className="img-40 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                                <Media body>
                                                    <h6 className="f-w-600">
                                                        <Link to={`${process.env.PUBLIC_URL}/pages/sponsors/sponsor2D`}> 
                                                            {'User Name Last Name'}
                                                        </Link>
                                                    </h6>
                                                    <p>{'User Position'}, {'User Company'}</p>
                                                </Media>
                                            </Media>
                                            <Button color="primary mb-2 text-center"><i className="fa fa-user-plus"></i>  {Connect}</Button>
                                            {userDemo? <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>:''}
                                        </CardBody>
                                        <CardBody>
                                            <Media>
                                                <img className="img-40 img-fluid m-r-20" src={require("../../assets/images/job-search/1.jpg")} alt=""/>
                                                <Media body>
                                                    <h6 className="f-w-600">
                                                        <Link to={`${process.env.PUBLIC_URL}/pages/sponsors/sponsor2D`}> 
                                                            {'User Name Last Name'}
                                                        </Link>
                                                    </h6>
                                                    <p>{'User Position'}, {'User Company'}</p>
                                                </Media>
                                            </Media>
                                            <Button color="primary mb-2 text-center"><i className="fa fa-user-plus"></i>  {Connect}</Button>
                                            {userDemo? <iframe src="https://player.vimeo.com/video/61237897?title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>:''}
                                        </CardBody>
                                    </ScrollArea>
                                </Collapse>
                            </Card>
                        </Col>
                        <Col xl="12">
                            <UsersListCard/>
                        </Col>
                        <Col xl="12">
                        <Card>
                            <CardHeader>
                                <h5 className="mb-0">
                                    <Button color="link pl-0" onClick={() => setisIntro(!isIntro)}
                                        data-toggle="collapse" data-target="#collapseicon7" aria-expanded={isIntro} aria-controls="collapseicon7">{Show[et]+' '+Sponsors}</Button>
                                </h5>
                            </CardHeader>
                            <Collapse isOpen={isIntro}>
                                <ScrollArea
                                speed={0.5}
                                horizontal={false}
                                vertical={true}>
                                    <CardBody className="filter-cards-view">
                                        {editMode? '' : <Media className="img-fluid" alt="" src={timeline3}/>} 
                                        {editMode? 
                                            <FormGroup className="mb-0">
                                              <Label><b>{SponsorDescription+':'}</b></Label>
                                              <Input type="textarea" className="form-control"  rows="3"  placeholder={SponsorDescription}/>
                                            </FormGroup> :  
                                            <p className="mb-0">{SponsorDescription}</p> }
                                        <div className="social-network theme-form"><span className="f-w-600">{SocialNetworks}</span>
                                            {editMode? '' : <Button color="social-btn btn-fb mb-2 text-center"><i className="fa fa-facebook m-r-5"></i>{Facebook}</Button>} 
                                            {editMode? '' : <Button color="social-btn btn-google text-center"><i className="fa fa-instagram m-r-5"></i>{Instagram}</Button>}
                                            {editMode? '' : <Button color="social-btn btn-twitter mb-2 text-center"><i className="fa fa-twitter m-r-5"></i>{Twitter}</Button>}  
                                            {editMode? '' : <Button color="social-btn btn-fb mb-2 text-center"><i className="fa fa-linkedin m-r-5"></i>{Linkedin}</Button>} 
                                        </div>
                                    </CardBody>
                                </ScrollArea>
                            </Collapse>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Fragment>
    );
};

export default ShowLeftBar;