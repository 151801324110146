import React, { useEffect} from 'react'
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react'
import { Media } from 'reactstrap';
import CommentsList from '../../../general/CommentsList'
import CloseWindow from './2DAssets/CloseWindow';

export const ChatTab = (props) => {

    useEffect(()=>{
        console.log("chatDetails",props.chatDetails)
    },[])

    return(
        <Fragment>
            <Card style={{margin:"15px 30px", width:"300px", height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody>
                    <p style={{color:"white",display:"inline-block"}}>
                        <div onClick={() => props.checkIfIsOpen({type:"user_story"})} className="customers d-inline-block avatar-group" style={{display:"inline-block"}}>
                            <ul>
                              <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require(`../../../../assets/images/${props.chatDetails.img}`)} alt=""/></li>
                              {/* <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require('../../../../assets/images/user/5.jpg')} alt=""/></li> */}
                              {/* <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require('../../../../assets/images/user/1.jpg')} alt=""/></li> */}
                            </ul>
                        </div>
                        <h3><b>{props.chatDetails.name}</b></h3>
                    </p>
                    <CommentsList type="comments" commentsData={props.commentsData} height={"225px"}/>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ChatTab