import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
// import RightSidebar from "../layout/RightSidebar";
import Footer from "../layout/Footer";
import Loader from "../layout/Loader";

import { Media } from 'reactstrap'

import ThemeCustomize from "../components/common/ThemeCustomize"
import {SetGlobalEvent, ChangePlatformType,SwitchDocType} from '../redux/common/actions'
import VR_View from "./VR/VR_View/VR_View";
import View360 from "./VR/360Video/View360"

const AppLayout = ({ children }) => {

   const dispatch = useDispatch()
   const mainType = useSelector(state => state.Common.mainType)
   const backImg2D = useSelector(state => state.Common.backImg2D)

   axios.defaults.baseURL = `http://localhost:4000`
   const [isLoading, setLoading] = useState(true)
   const [eventTypes, setEventType] = useState([])
   const [locations, setLocations] = useState([])
   const [perspectives, setPerspectives] = useState([])
   
   useEffect(() => {
    const events = require("../api/events.json")
    const event =  events.find(event => event.id === 1)
    console.log('Setting Event Globally: ', event)
    dispatch(SetGlobalEvent(event))
    setLocations(event.locations)
    setPerspectives(event.locations[2])
    event.platformtypes.map((eventType,i)=>{
      if(eventType.available){
        setEventType(types => [...types , eventType.type])
      }
      if(eventType.main){dispatch(ChangePlatformType(eventType.type))}
    })
    console.log("Setting Event Types", eventTypes )

    //IF  USER IS ADMIN
    setEventType(types => [...types , "Admin"])
    getDocType()
    setLoading(false)

    // axios.get(`/events/1`)
    // .then( res => {
    //   console.log('Setting Event Globally: ', res.data)
    //   dispatch(SetGlobalEvent(res.data))
    //   setLocations(res.data.locations)
    //   setPerspectives(res.data.locations[2])
    //   res.data.platformtypes.map((eventType,i)=>{
    //     if(eventType.available){
    //       setEventType(types => [...types , eventType.type])
    //     }
    //     if(eventType.main){dispatch(ChangePlatformType(eventType.type))}
    //   })
    //   console.log("Setting Event Types", eventTypes )

    //   //IF  USER IS ADMIN
    //   setEventType(types => [...types , "Admin"])
    //   getDocType()

    // }).catch(err => console.error("03",err))
    // .finally(() => setLoading(false)) 
   },[])
   

   const getDocType = () =>{
      const currentURL = window.location.href
      if(currentURL.includes("EventHome")){
        dispatch(SwitchDocType("EventHome"))
      }else if(currentURL.includes("Venue")){
        dispatch(SwitchDocType("venue"))
      }
   }


  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  switch(mainType){
    default:
    case"1D":
    return (
     <Fragment>
       <Loader />
       <div className="page-wrapper compact-page">
         <div className="page-body-wrapper">
           <Header eventTypes={eventTypes}/>
           {/* <Sidebar /> */}
           {/* <RightSidebar /> */}
           <div className="page-body">{children}</div>
           <Footer />
           {/* <ThemeCustomize /> */}
         </div>
       </div>
     </Fragment>
    );
    break;
    case"2D":
     return (
       <Fragment>
         <Loader />
         <div className="page-wrapper compact-page">
           <div className="page-body-wrapper">
               <Header backImg2D={backImg2D} eventTypes={eventTypes}/>
               {/* <Sidebar /> */}
               {/* <RightSidebar /> */}
               <div className="page-body" 
                style={{backgroundImage:`url('${backImg2D}')`,
                backgroundSize:"cover",
                backgroundPosition:"center center",
                backgroundRepeat:"no-repeat"}} >{children}</div>
               <Footer />
               {/* <ThemeCustomize /> */}
           </div>
         </div>
       </Fragment>
     )
    break;
    case"360":
     return (
       <Fragment>
         <Loader />
         <div className="page-wrapper compact-page">
           <div className="page-body-wrapper">
               <View360 eventTypes={eventTypes}/>
           </div>
         </div>
       </Fragment>
     )
    break;
    case"VR":
      return (
        <Fragment>
          <Loader />
          <div className="page-wrapper compact-page">
            <div className="page-body-wrapper">
                <VR_View eventTypes={eventTypes}/>
                {/* <Sidebar /> */}
                {/* <RightSidebar /> */}
                {/* <div className="page-body" 
                 style={{backgroundImage:`url('${backImg2D}')`,
                 backgroundSize:"cover",
                 backgroundPosition:"center center",
                 backgroundRepeat:"no-repeat"}} >{children}</div> */}
                {/* <Footer /> */}
                {/* <ThemeCustomize /> */}
            </div>
          </div>
        </Fragment>
      )
    break;
  }
};

export default AppLayout;
