import React, {useState, useEffect, Fragment} from 'react'
import axios from 'axios';
import { Card, CardHeader, Form, FormGroup, Row, Col, Button} from 'reactstrap';
import LabeledInput from '../../../general/LabeledInput';
import { UserEmail, UserLogIn, UserPassword } from '../../../../constant';

const UserCard = (props) =>  {
  
  return (
    <Fragment>
      <Card className="crm-user-profile-card">
        <CardHeader className="p-0 profile-top-bg">
          <div className="profile-details">
            <Form className="p-50">
              <FormGroup>
                <Row>
                  <Col lg='12'>
                    <LabeledInput label={UserLogIn} placeholder={UserEmail} type={'email'} value={''}/>
                  </Col>
                  <Col lg='12'>
                    <LabeledInput label={UserPassword} placeholder={UserPassword} type={'password'} value={''}/>
                  </Col>
                  <Col lg="12" className="text-center">
                    <br/>
                    <Button color="primary" className="mr-1" onClick={props.authenticateUser} ><i className="fa fa-plus-circle fa-lg"></i>&nbsp; &nbsp;{'Log In'}</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        </CardHeader>
      </Card>       
    </Fragment>
  )
}

export default UserCard
