import React,{useState, useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {Set2DBackGround} from '../../../../redux/common/actions'

import ShowsList_1D from './1D/ShowsList_1D';
import ShowList_2D from './2D/ShowList_2D';
import ShowsForm from './EditView/ShowsForm';

const ShowsListView = (props) => {
  
  const dispatch = useDispatch()
  const mainType = useSelector(state => state.Common.mainType)
  const shows = useSelector(state => state.Common.currentEvent.locations[2].shows)
  const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
  const [isLoading, setLoading] = useState(true)

  //SHOWS DATA -------------------------------------------------------------
  const [liveShows , setLiveShows] = useState([])
  const [upcommingShows , setUpCommingShows] = useState([])
  useEffect(() => {

    console.log("Setting Shows 2D BackGround ---------------------------------------")
    platformtypes.map(view =>{
      if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.shows))}
    }) 
    console.log("Setting Shows ---------------------------------------")
    var i = 0
    shows.forEach(show => {
      console.log('Setting Live Show: ', show)
      setLiveShows(liveShows => [...liveShows,show])
      console.log('Setting Upcomming Show: ', show)
      setUpCommingShows(upcommingShows => [...upcommingShows,show])
      i++
      if(i === shows.length){
        setLoading(false)
      }
    })

  },[])

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  switch(mainType){
    case"Admin": 
      return (
        <Fragment>
          <ShowsForm/>
        </Fragment>
      );
    break;
    default:
    case"1D": 
      return (
        <Fragment>
          <ShowsList_1D liveShowsData={liveShows} upcommingShowsData={upcommingShows} />
        </Fragment>
      );
    break;
    case"2D": 
      return (
        <Fragment>
          <ShowList_2D liveShowsData={liveShows} upcommingShowsData={upcommingShows} />
        </Fragment>
      );
    break;
    case"3D": 
      return (
        <Fragment>
          {'3D VIEW'}
        </Fragment>
      );
    break;
  }
};

export default ShowsListView;