import React,{Fragment} from 'react';
import ImageGallery from '../../../../../EditMode_General/PlatformTypes/2D/ImageGallery'
const Data_2D = (props) => {
  return (
    <Fragment>
      <h3>{'2D VIEW DATA'}</h3>
      <hr/>
      <ImageGallery/>
    </Fragment>
  );
};

export default Data_2D;