import React, { Fragment, useEffect, useState } from 'react'
import {Media, Badge} from 'reactstrap'
import {Live} from "../../constant"
import ScrollArea from 'react-scrollbar'

const EventTimeLine = (props) => {

  const [isLoading, setLoading] = useState(true)
  const [locations , setLocation] = useState(props.locationsList)
  const [activities , setActivities] = useState([])

  var maxHeight = props.maxHeight || "500px"

  useEffect(() => {
    console.log("maxHeight",maxHeight)
    locations.forEach(location => {
      location.shows.forEach( show => {
        setActivities(activity => [...activity,show])
      })
    })
    setLoading(false)
  },[locations])
    
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Fragment>
      <div className="timeline-small">
        <ScrollArea
            speed={0.5}
            horizontal={false}
            vertical={true}
            style={{maxHeight:maxHeight}}
            >  
            <br/>
            {activities.map((activity,i)=>{
              return(
                <Media key={'activity_'+i+'_'+activity.id}>
                  <div className="timeline-round m-r-30 timeline-line-1">
                    <Media body className="img-50 rounded-circle" src={require(`../../assets/images/user/${activity.thumbnail}`)} alt={activity.thumbnail}/>
                  </div>
                  <Media body>
                    <p style={{fontSize:24,fontWeight:'bold',marginBottom:'2px'}}>{activity.name}<span className="pull-right f-14"><Badge color="success" style={{color:'white'}}>{Live}</Badge></span></p>
                    <p style={{marginBottom:'2px'}}>{activity.fromDate + ' - ' + activity.toDate}</p>
                    <p>{activity.description}</p>
                  </Media>
                </Media>
              )
            })}
            <br/>
        </ScrollArea>
      </div>
    </Fragment>
  );
};

export default EventTimeLine;