import React, { useState, useEffect, useCallback, Fragment } from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {Container, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {Eventt, Homes, GeneralInfo, Administrators, BrandableAreas, UsersList} from "../../../../../constant"
import BreadCrumb from '../../../../../layout/Breadcrumb'
import GeneralInfoData from './GeneralInfo/ViewTypes'
import BrandableAreasData from './BrandableAreas/BrandableAreasData'
import AdministratorsData from '../../../EditMode_General/Administrators/AdministratorsData'
import UsersListData from '../../../EditMode_General/Users/UsersListData'

const EventForm = (props) =>  {

  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('1')
  const editMode = useSelector(state => state.Common.editMode)
  const [event , setEvent] = useState(props.eventData)

  const [startDate,setstartDate] = useState(new Date())
  const [endDate,setendDate] = useState(new Date())

  const [selectedOption, setDropdownOption] = useState('')
  useEffect(() => {
    console.log(selectedOption)
  },[selectedOption])
  
  return (
    <Fragment>
       <BreadCrumb  subparent={Homes} title={event.name} statisticsBTN={true}/>
       <Container fluid={true}>
        <Nav tabs className="pull-right  border-tab">
            <NavItem>
                <NavLink className={activeTab === '1' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('1')}>
                    {GeneralInfo}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '2' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('2')}>
                    {BrandableAreas}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '3' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('3')}>
                    {Administrators}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === '4' ? 'active' : ''} style={{cursor: "pointer"}} onClick={() => setActiveTab('4')}>
                    {UsersList}
                </NavLink>
            </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <GeneralInfoData eventData={event} />
            </TabPane>
            <TabPane tabId="2">
              <BrandableAreasData eventData={event} />
            </TabPane>
            <TabPane tabId="3">
              <AdministratorsData administratorsList={event.administrators} />
            </TabPane>
            <TabPane tabId="4">
              <UsersListData guestsList={event.guestsList} bannedList={event.guestsList}/>
            </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  )
}

export default EventForm
