import React,{ Fragment, useEffect, useState } from 'react'
import { Badge } from 'reactstrap'
import { PaddingLeft } from '../../../constant'

const LeftVR_Types = (props) => {

  const [countDown, setCountDown] = useState("90:00")

  const secondsToCountDown = (duration) => {
    var timer = duration, minutes, seconds;
    var counterInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setCountDown(minutes + ":" + seconds)

        if (--timer < 0) {
          timer = duration;
          clearInterval(counterInterval)
        }
    }, 1000);
}

  useEffect(()=>{
    secondsToCountDown(60*90)
  },[])

  const windowWidth = props.windowWidth
  const titleSize = () => {
      if (windowWidth <= 320) {
          // Phone
          return "15px"
      }else{
          // Normal
          return "18px"
      }
  }

  switch(props.eventType.type){
    default:
    case"":
      return(
        <Fragment>
          <h5 style={{color:"white",cursor:"pointer",marginBottom:'0.02rem'}}><b>{props.eventName}</b></h5>
          <h6 style={{color:"white",cursor:"pointer"}}><b>{props.eventLocation}</b></h6>
        </Fragment>
      )
    break;
    case"football":
      return(
        <Fragment>
          <div className={"header-match-counter"}>
            <p style={{color:"white",fontSize:"18px",cursor:"pointer",marginBottom:'0.02rem'}}><Badge color="primary" pill>{3}</Badge>&nbsp;<b>{props.eventName}</b>&nbsp;<Badge color="primary" pill>{1}</Badge></p>
            <h6 style={{color:"white",cursor:"pointer", textAlign:"center",width:"100%"}}><b>{props.eventLocation}</b>&nbsp;<Badge color="primary" pill>{countDown}</Badge></h6>
          </div>
        </Fragment>
      )
    break;
    
  }
}

export default LeftVR_Types;