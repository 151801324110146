import React, {useState, useEffect, Fragment, useCallback} from 'react'
import axios from 'axios';
import { Card, CardBody, CardHeader, Button} from 'reactstrap'
import { User, CreditCard, Settings, FileText, LogOut } from 'react-feather'
import LogedInUser from './LogedInUser'
import LogInUser from './LogInUser'
const UserCard = (props) =>  {
  
  axios.defaults.baseURL = `http://localhost:4000`
  const [isLoading, setLoading] = useState(true)
  const [user , setUser] = useState(null)
  const authenticateUser = useCallback(() =>{
    axios.get(`/users/0`)
    .then( res => {
      console.log('Setting Current User: ', res.data)
      setUser(res.data)
    }).catch(err => console.error("04",err))
    .finally(() => setLoading(false)) 
  },[])

  const logOutUser = () => {
    setUser(null)
  }

  return (
    <Fragment>
      {user != null? 
        <LogedInUser isLoading={isLoading} currentUser={user} logOutUser={logOutUser}/>:
        <LogInUser authenticateUser={authenticateUser}/>}
    </Fragment>
  )
}

export default UserCard
