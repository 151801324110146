import 'aframe'
import 'aframe-look-at-component'
import {Entity, Scene} from 'aframe-react'
import React, { Fragment} from 'react'
import { useSelector } from 'react-redux'

const VR_Ads = (props) => {
  const event = useSelector(state => state.Common.currentEvent)
  
  return (
    <Fragment>
        { props.adPlacements != undefined && props.adPlacements.length > 0 ?
              props.adPlacements.map((adPlacement,i)=>{
                return(
                <Fragment>
                  {adPlacement.ads[0].sponsorID  != undefined && adPlacement.ads[0].adPlacementID != undefined && event.sponsors[adPlacement.ads[0].sponsorID].adPlacements[adPlacement.ads[0].adPlacementID].imagePlacement != undefined ?
                    <Entity id={`${adPlacement.name} Thumbnail`} primitive="a-image" 
                      src={require(`../../../assets/images/ads/${adPlacement.ads[0].sponsorID,adPlacement.ads[0].adPlacementID,event.sponsors[adPlacement.ads[0].sponsorID].adPlacements[adPlacement.ads[0].adPlacementID].imagePlacement}`)} width="3" height="2" 
                      position={{x: adPlacement.position.x, y: adPlacement.position.y, z:adPlacement.position.z}}
                      rotation={{x: adPlacement.rotation.x, y: adPlacement.rotation.y, z:adPlacement.rotation.z}}
                      scale={{x: adPlacement.scale.x, y: adPlacement.scale.y, z:adPlacement.scale.z}}
                      events={{ click: () => props.AdClick(adPlacement.ads) }}/>:
                    <Entity 
                      id={adPlacement.name}
                      geometry={{primitive: 'sphere'}} 
                      material={{color: props.adPlacementColor, opacity: 0}} 
                      position={{x: adPlacement.position.x, y: adPlacement.position.y, z:adPlacement.position.z}}
                      rotation={{x: adPlacement.rotation.x, y: adPlacement.rotation.y, z:adPlacement.rotation.z}}
                      scale={{x: adPlacement.scale.x, y: adPlacement.scale.y, z:adPlacement.scale.z}}
                      events={{ click: () => props.AdClick(adPlacement.ads) }}/> 
                  }
                </Fragment>
                )
              }) : console.log("This perspective does not have ads")
            }
    </Fragment>  
  );
}
export default VR_Ads
