import React, {Fragment} from 'react'
import { Row, Col, Card, CardBody} from 'reactstrap'
import { Database, ShoppingBag, MessageCircle, UserPlus } from 'react-feather'
import {useSelector} from 'react-redux'
import {Current,Watching,Show,Experience,Messages,NewUser,Products} from "../../../../../constant";

const EventCounters = () =>  {
  const et = useSelector(state => state.Common.eventType)

    return (
      <Fragment>
        <Row>
          <Col sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-primary b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><Database/></div>
                  <div className="media-body">
                    <h6 className="mb-0 counter">{Current+' '+Show[et]}</h6>
                    <h5 className="mb-0 counter"><b>{'SHOW TITLE'}</b></h5>
                    <span className="m-0">{Watching+' : '+'6659'}</span>
                    <Database className="icon-bg"/>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <div className="bg-secondary b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><ShoppingBag/></div>
                  <div className="media-body">
                    <h6 className="mb-0 counter">{Current+' '+Experience}</h6>
                    <h5 className="mb-0 counter"><b>{'Experience Title'}</b></h5>
                    <span className="m-0">{Watching+' : '+'6659'}</span>
                    <ShoppingBag className="icon-bg"/>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <CardBody className="bg-primary b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><MessageCircle/></div>
                  <div className="media-body">
                    <h6 className="mb-0 counter">{Current+' '+Experience}</h6>
                    <h5 className="mb-0 counter"><b>{'Experience Title'}</b></h5>
                    <span className="m-0">{Watching+' : '+'6659'}</span>
                    <MessageCircle className="icon-bg"/>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" xl="3" lg="6">
            <Card className="o-hidden">
                <CardBody className="bg-primary b-r-4">
                <div className="media static-top-widget">
                    <div className="align-self-center text-center"><UserPlus/></div>
                    <div className="media-body"><span className="m-0">{NewUser}</span>
                    <h4 className="mb-0 counter">{"45631"}</h4><UserPlus className="icon-bg"/>
                    </div>
                </div>
                </CardBody>
            </Card>
            </Col>
        </Row>
      </Fragment>
    )
}

export default EventCounters
