import React, { Fragment } from 'react'
import { Table, Row, Col} from 'reactstrap'

export const TeamStatistics_table = (props) => {
    const componentWidth = props.width || "380px"
    
    return(
        <Fragment> 
            <Row style={{width:componentWidth}}>
                <Col lg="12">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col">{"Distribution"}</th>
                                    <th scope="col">{"Metric"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{"Total Passes"}</td>
                                    <td>{"6936"}</td>
                                </tr>
                                <tr>
                                    <td>{"Pass accuracy"}</td>
                                    <td>{"81.07%"}</td>
                                </tr>
                                <tr>
                                    <td>{"Total crosses"}</td>
                                    <td>{"206"}</td>
                                </tr>
                                <tr>
                                    <td>{"Cross accuracy"}</td>
                                    <td>{"16.02%"}</td>
                                </tr>
                                <tr>
                                    <td>{"Possession"}</td>
                                    <td>{"53%"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col">{"Attack"}</th>
                                    <th scope="col">{"Metric"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{"Goals"}</td>
                                    <td>{"31"}</td>
                                </tr>
                                <tr>
                                    <td>{"Goals - inside the box"}</td>
                                    <td>{"23"}</td>
                                </tr>
                                <tr>
                                    <td>{"Goals - outside the box"}</td>
                                    <td>{"8"}</td>
                                </tr>
                                <tr>
                                    <td>{"Goals - set pieces"}</td>
                                    <td>{"2"}</td>
                                </tr>
                                <tr>
                                    <td>{"Headed goals"}</td>
                                    <td>{"7"}</td>
                                </tr>
                                <tr>
                                    <td>{"Goals / match"}</td>
                                    <td>{"1.6"}</td>
                                </tr>
                                <tr>
                                    <td>{"Penalties accuracy"}</td>
                                    <td>{"75%"}</td>
                                </tr>
                                <tr>
                                    <td>{"Total shots"}</td>
                                    <td>{"193"}</td>
                                </tr>
                                <tr>
                                    <td>{"Shot accuracy"}</td>
                                    <td>{"45.6%"}</td>
                                </tr>
                                <tr>
                                    <td>{"Offsides"}</td>
                                    <td>{"18"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col">{"Dicipline"}</th>
                                    <th scope="col">{"Metric"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{"Yellow cards"}</td>
                                    <td>{"20"}</td>
                                </tr>
                                <tr>
                                    <td>{"Red Cards"}</td>
                                    <td>{"3"}</td>
                                </tr>
                                <tr>
                                    <td>{"Fouls concedes"}</td>
                                    <td>{"240"}</td>
                                </tr>
                                <tr>
                                    <td>{"Fouls won"}</td>
                                    <td>{"251"}</td>
                                </tr>
                                <tr>
                                    <td>{"Penalties conceded"}</td>
                                    <td>{"3"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col">{"Defence"}</th>
                                    <th scope="col">{"Metric"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{"Goals conceded"}</td>
                                    <td>{"16"}</td>
                                </tr>
                                <tr>
                                    <td>{"Goals conceded/match"}</td>
                                    <td>{"0.8"}</td>
                                </tr>
                                <tr>
                                    <td>{"Clean Sheets"}</td>
                                    <td>{"7"}</td>
                                </tr>
                                <tr>
                                    <td>{"Tackles"}</td>
                                    <td>{"261"}</td>
                                </tr>
                                <tr>
                                    <td>{"Duels"}</td>
                                    <td>{"1994"}</td>
                                </tr>
                                <tr>
                                    <td>{"Clearances"}</td>
                                    <td>{"244"}</td>
                                </tr>
                                <tr>
                                    <td>{"Blocked Shots"}</td>
                                    <td>{"46"}</td>
                                </tr>
                                <tr>
                                    <td>{"Interceptions"}</td>
                                    <td>{"220"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default TeamStatistics_table