import 'aframe'
import 'aframe-look-at-component'
import React, { Fragment, useEffect, useState } from 'react'
import {Entity} from 'aframe-react'
import { useSelector } from 'react-redux'
import VR_Streaming2D from './VR_Streaming2D'

const VR_MediaGallery = (props) => {
  const [loadingMenu, setLoadingMenu] = useState(true)
  const [galleryPosition, setGalleryPosition] = useState(props.mediaGallery.position)
  const [galleryRotation, setGalleryRotation] = useState(props.mediaGallery.rotation)
  const [galleryScale, setGalleryScale] = useState(props.mediaGallery.scale)

  const [team2 , setTeam2] = useState("Tijuana")

  const setCurrentResume = (resume) => {
    // setTeam2(resume.team2)
    // setResumeStresming(resume.streaming)
    console.log("Setting New Resume", resume)

  }

  const gallery = [
    {
      id:0,
      thumbnail: "Stadium/america-santos.jpg",
      streaming:"Stadium/america-santos.mp4",
      name: 'America vs Pachuca',
      team1:'America',
      team2:'Santos'
    },
    {
      id:1,
      thumbnail: "Stadium/america-atlas.jpg",
      streaming2D:"Stadium/america-atlas.mp4",
      name: 'Centro Cancha',
      team1:'America',
      team2:'Atlas'
    },
    {
      id:2,
      thumbnail: "Stadium/america-tigres.jpg",
      streaming2D:"Stadium/america-tigres.mp4",
      name: 'Centro Medio',
      team1:'America',
      team2:'Tigres'
    },
    {
      id:3,
      thumbnail: "Stadium/america-necaxa.jpg",
      streaming2D:"Stadium/america-necaxa.mp4",
      name: 'Centro Platea 1',
      team1:'America',
      team2:'Necaxa'
    },
    {
      id:4,
      thumbnail: "Stadium/america-puebla.jpg",
      streaming2D:"Stadium/america-puebla.mp4",
      name: 'Centro Platea 2',
      team1:'America',
      team2:'Puebla'
    },
    {
      id:5,
      thumbnail: "Stadium/america-tijuana.jpg",
      streaming2D:"Stadium/america-tijuana.mp4",
      name: 'Esquina Sur Abajo',
      team1:'America',
      team2:'Tijuana'
    }
  ];

  useEffect(()=>{
    setGalleryPosition(props.mediaGallery.position)
    setGalleryRotation(props.mediaGallery.rotation)
    setGalleryScale(props.mediaGallery.scale)
    console.log("Setting VR Menu",props.mediaGallery)
    setLoadingMenu(false)
  },[props.mediaGallery])

  const thumbnailsPositions = ["-0.33 -0.2 -0.016","0 -0.2 0","0.33 -0.2 0","-0.33 -0.43 -0.016","0 -0.43 0","0.33 -0.43 0"]

  if (loadingMenu) {
    return <div className="App">Loading...</div>;
  }

  
  var streaming2DVR = {
    status:"live",
    type:"Streaming2D",
    src:props.streaming2DVR,
    streamingFrame:{
      sponsorID:0,
      adPlacementID:4
    },
    from:{
      position:{
        x:5,
        y:1.15,
        z:-9.65
      },
      rotation:{
        x:0,
        y:0,
        z:0
      },
      scale:{
        x:1,
        y:1,
        z:1
      }
    },
    to:{
      position:{
        x:-0.72,
        y:-5,
        z:-5.6
      },
      rotation:{
        x:0,
        y:0,
        z:0
      },
      scale:{
        x:1,
        y:1,
        z:1
      }
    }
  }

  return (
    <Fragment>
        <a-entity id="media-wrapper"
          position={`${galleryPosition.x} ${galleryPosition.y} ${galleryPosition.z}`}
          rotation={`${galleryRotation.x} ${galleryRotation.y} ${galleryRotation.z}`}
          scale={`${galleryScale.x} ${galleryScale.y} ${galleryScale.z}`}>
          <a-entity id="media-title" position={`-4.40 5 -10.60`} rotation={`0 0 0`} scale="15 15 15"
           text="value: Resumenes; align:center;"></a-entity>
          <a-entity id="media-wrapper"  position={`-4.45 5 -10.75`} rotation={`0 0 0`} scale="10 10 10">
            <a-entity id="media-block-0">
            {gallery.map((media,i) =>{
                return(
                  <Fragment>
                    <Entity id={`${media.name} Thumbnail`} primitive="a-image" 
                    src={require(`../../../assets/images/perspectives/${media.thumbnail}`)} 
                    width="3" height="2" position={thumbnailsPositions[i]} rotation="0 0 0" scale="0.1 0.1 0.1"
                    events={ 
                      { click: () => setCurrentResume(media) }
                    }/>
                    {/* <a-entity id="menu-title" text={`value: ${media.team1} vs ${media.team2}; align:center;`} position={thumbnailsPositions[i]} scale="0.8 0.8 0.8"></a-entity> */}
                  </Fragment> 
                )
              })
            }
            </a-entity>
          </a-entity>
          <a-entity id="Streaming-Title" text={`value: Previo al partido; align:center;`}
            position={`5.22 5 -10.60`} rotation={`0 0 0`} scale="15 15 15"></a-entity>

          <VR_Streaming2D streaming2D={streaming2DVR} checkIfIsOpen={props.checkIfIsOpen} changePerspective={props.changePerspective} showBanner={true}/>
        </a-entity>
    </Fragment>  
  )
}
export default VR_MediaGallery;
