import React, { Fragment, useState } from 'react';
import {useSelector} from 'react-redux'
import {Card,Row,Col,TabContent, TabPane, Nav, NavItem, NavLink,Input,Label,FormGroup} from 'reactstrap';
import {ProductDescription,ProductReviews,ProductQuestions,NormallyBoughtTogether} from '../../../constant'
import ComentsList from '../../general/CommentsList'
import ProductGrid from './ProductGrid'

const Tabset = () => {
    const [activeTab, setActiveTab] = useState('1');
    const editMode = useSelector(state => state.Common.editMode)
    return (
        <Fragment>
            <Card>
                <Row className="product-page-main m-0">
                    <Col sm="12">
                    <Nav tabs className="borderb-tab-primary">
                        <NavItem  id="myTab" role="tablist">
                            <NavLink href="#" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                {NormallyBoughtTogether}
                            </NavLink>
                        </NavItem>
                        <NavItem  id="myTab" role="tablist">
                            <NavLink href="#"  className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                {ProductDescription}
                            </NavLink>
                        </NavItem>
                        <NavItem  id="myTab" role="tablist">
                            <NavLink href="#"  className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                {ProductReviews}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <br/>
                            <ProductGrid/>
                        </TabPane>
                        <TabPane tabId="2">
                            <br/>
                            {editMode? 
                              <FormGroup className="mb-0">
                                <Label><b>{ProductDescription+':'}</b></Label>
                                <Input type="textarea" className="form-control"  rows="3"  placeholder={ProductDescription}/>
                              </FormGroup> :  
                              <p className="mb-0">{ProductReviews}</p> }
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col lg="6">
                                    <br/>
                                    <h3><b>{ProductReviews}</b></h3>
                                    <hr/>
                                    <ComentsList/>
                                </Col>
                                <Col lg="6">
                                    <br/>
                                    <h3><b>{ProductQuestions}</b></h3>
                                    <hr/>
                                    <ComentsList/>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    </Col>
                </Row>
            </Card>
        </Fragment>
    );
}

export default Tabset;