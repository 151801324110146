import React,{Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {Row,Col,Card,CardBody,Badge,Button} from 'reactstrap'
import {Carousel,CarouselItem,CarouselIndicators,CarouselCaption,CarouselControl,UncontrolledCarousel,Media} from  'reactstrap'
import { Attendance, Free, GoTo, PerspectiveLocked, Perspectives, PreviewPerspective,
   Purchase, PurchaseTokens, PurchaseToUnlock, Show, TemporarilyUnavailable, Watching } from '../../../constant';
import '../../../assets/css/evnt_cards.css'
import Rating from 'react-rating'

const items1 = [
    {
      src: require("../../../assets/images/Carousel/1.jpg"),
      altText: 'Slide 1',
      caption: 'Slide 1'
    },
    {
      src: require("../../../assets/images/Carousel/2.jpg"),
      altText: 'Slide 2',
      caption: 'Slide 2'
    },
    {
      src: require("../../../assets/images/Carousel/3.jpg"),
      altText: 'Slide 3',
      caption: 'Slide 3'
    }
  ];

const items2 =[
    {
        id:1,
        src: require("../../../assets/images/banner/SponsorsBanner1.png"),
        altText: 'Slide 1',
        caption: 'Slide 1'
      },
      {
        id:2,
        src: require("../../../assets/images/banner/SponsorsBanner2.png"),
        altText: 'Slide 2',
        caption: 'Slide 2'
      },
      {
        id:3,
        src: require("../../../assets/images/banner/SponsorsBanner3.png"),
        altText: 'Slide 3',
        caption: 'Slide 3'
      }
];

const items3 =[
    {
        id:1,
        src: require("../../../assets/images/Carousel/1.jpg"),
        altText: 'Slide 1',
        caption: 'Slide 1'
      },
      {
        id:2,
        src: require("../../../assets/images/Carousel/2.jpg"),
        altText: 'Slide 2',
        caption: 'Slide 2'
      },
      {
        id:3,
        src: require("../../../assets/images/Carousel/3.jpg"),
        altText: 'Slide 3',
        caption: 'Slide 3'
      }
];

 export const CarsouselDemoone = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    
      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      }
    
      const goToIndex = (newIndex) => {
          setActiveIndex(newIndex);
          if (animating) return;
      }
    return (
          <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
          >
            <CarouselIndicators items={items1} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {items1.map((item) => {
                return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={item.src}
                >
                <Media src={item.src} alt={item.altText} className="img-fluid"/>
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
                );
            })}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>
    );
}

export const CarsouselDemotwo = (props) => {
  
    return (
        <UncontrolledCarousel items={items2}/>
    );
}

export const CarsouselDemothree = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items3.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    
      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items3.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      }
    
      const goToIndex = (newIndex) => {
          setActiveIndex(newIndex);
          if (animating) return;
      }
    return (
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items3} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {items3.map((item) => {
                    return (
                    <CarouselItem
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                        key={item.id}
                    >
                    <Media src={item.src} alt={item.altText} className="img-fluid"/>
                    <CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption} />
                    </CarouselItem>
                    );
                })}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
    );
}

// EVNT CAROUSELS ------------------------------------------

export const CarouselTopBanners = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [banners, setBanners] = useState(props.eventBanners);

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }

  return (
    <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
    >
      <CarouselIndicators items={items1} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {banners.map((item) => {
          return (
          <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.id}
          >
          {item.linkTo.type == "URL" ? 
            <a href={item.linkTo.id} target="blank">
              <Media src={require(`../../../assets/images/banner/${item.name}`)} alt={item.altText} className="img-fluid"/>
              <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </a> : 
            <Link>
              <Media src={require(`../../../assets/images/banner/${item.name}`)} alt={item.altText} className="img-fluid"/>
              <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </Link>
          }
        
          </CarouselItem>
          );
      })}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export const CarouselLocationsVR_2D = (props) => {
  const event = useSelector(state => state.Common.currentEvent)
  const [activePerspectives, setActivePerspectives] = useState(event.locations[0].perspectives.filter(perspective => perspective.status != undefined && perspective.status == "live" && perspective.status !== undefined))

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === activePerspectives.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex)
  }
  
  const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? activePerspectives.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
  const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }

  const [blurIndex , setBlurIndex] = useState(2)  
  useEffect(()=>{
    setTimeout(()=>{setBlurIndex(2)},4000)
  },[blurIndex])

  const windowWidth = props.windowWidth
  const [rating,setRating] = useState(4)
  return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
          {activePerspectives.map((perspective,i) => {
              return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`Location ${perspective.name}`}
                >
                <div className="location-item-layout">
                  <div style={{position:"relative"}}>                    
                      <div style={{ position: "relative",  textAlign: "center",cursor:"pointer"}}>
                        
                      {windowWidth<= 1000 && windowWidth >= 425 ? "":
                        <Media  
                         src={require(`../../../assets/images/banner/cardBanner.jpg`)} 
                         alt={"Card Banner"} className="img-fluid b-r-10" 
                         style={{width:"100%", cursor:"pointer"}}/>}
                        <Media  
                        className="img-fluid b-r-10 location-thumbnail" 
                        onClick={()=> {
                          props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"perspective",toLocationId:props.menuForLocation,toPerspectiveId:perspective.id,type:undefined}})
                          props.checkIfIsOpen({type:props.windowName})
                        }}
                        src={require(`../../../assets/images/perspectives/${perspective.thumbnail}`)} 
                        alt={perspective.name}
                        style={
                          (perspective.price != undefined && perspective.price.amount == 0 ?
                          {maxWidth:"200px",marginTop:"10px", marginLeft:"auto", marginRight:"auto",cursor:"pointer" }:
                          {maxWidth:"200px",marginTop:"10px", marginLeft:"auto", marginRight:"auto",cursor:"none" ,filter:`blur(${blurIndex}px)`, WebkitFilter:`blur(${blurIndex}px)`})}/>

                        {!(perspective.price != undefined && perspective.price.amount == 0) && blurIndex == 2 ?
                          <div style={{position: "absolute", top: "62%", left: "50%",  transform: "translate(-50%, -50%)"}}>
                            <i className="fa fa-lock fa-5x"></i>
                            <Button style={{width:"100%"}} color="secondary" size="xs" onClick={()=>setBlurIndex(0)}>{PreviewPerspective}</Button>
                          </div>:""
                        }
                      </div>
                  </div>
                  <div className="location-data">
                    {windowWidth<= 1000 && windowWidth >= 425 ?
                    <Media  
                     src={require(`../../../assets/images/banner/cardBanner.jpg`)} 
                     alt={"Card Banner"} className="img-fluid b-r-10" 
                     style={{maxWidth:"300px", cursor:"pointer"}}/>:""}

                    <h6 className="evnt-card-item-title"><b>{perspective.name}</b></h6>
                    <div>
                      <ul className="location-data-list">
                        <li><i className="fa fa-eye"></i>&nbsp;{57+"K"}</li>
                        <li>
                           <Rating
                             initialRating={rating}
                             emptySymbol="fa fa-star-o"
                             fullSymbol="fa fa-star"
                             onChange={(rate) => setRating(rate)}
                           ></Rating>
                         </li>
                      </ul>
                      <div>
                        {perspective.price == undefined ?
                          <Button className="location-button" color="secondary" outline>{TemporarilyUnavailable}</Button>: ""
                        }
                        {perspective.price != undefined && perspective.price.amount === 0 ?
                          <Button className="location-button" onClick={()=> props.changePerspective({toLocationId:props.menuForLocation,toPerspectiveId:perspective.id,type:undefined})} 
                           color="success">{Free}</Button>  :
                          <Button className="location-button " onClick={()=> props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"perspective",item:perspective.name,price:perspective.price,toLocationId:props.menuForLocation,toPerspectiveId:perspective.id,type:undefined}})} color="primary">{` $ ${perspective.price.amount} ${perspective.price.currency}`}</Button>
                        }
                      </div>
                    </div>
                    

                    
                  </div>
                </div>
              </CarouselItem>
              );
          })}
          {/* <CarouselIndicators items={activePerspectives} activeIndex={activeIndex} onClickHandler={goToIndex} />                 */}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
  );
}

export const CarouselStories = (props) => {
  const event = useSelector(state => state.Common.currentEvent)
  const [activePerspectives, setActivePerspectives] = useState(event.locations[0].perspectives.filter(perspective => perspective.status != undefined && perspective.status == "live" && perspective.status !== undefined))
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const userStories = [
    {
      src:"Stadium/Stories/stadium_story01.mp4"
    },
    // {
    //   src:"Stadium/Stories/stadium_story02.mp4"
    // },
    {
      src:"Stadium/Stories/stadium_story03.mp4"
    },
    {
      src:"Stadium/Stories/stadium_story04.mp4"
    }
  ]

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
  const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
  const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }

  return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
          {/* <CarouselIndicators items={perspectives} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
          {userStories.map((story,i) => {
              return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`Stories ${i}`}>
                <video style={{width:"180px",display:"block",margin:"0 auto"}} controls autostart autoPlay src={require(`../../../assets/video/${story.src}`)} type="video/mp4" />
                {/* <CarouselCaption captionText={`${i+1} - ${perspective.name}`} /> */}
              </CarouselItem>
              );
          })}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
  );
}

export const CarouselLocations2D = (props) => {

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [locations , setLocations] = useState(props.locationsData)

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }
  return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
          {locations.map((item) => {
              return (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={item.name}
                >
                  <div className="p-50">
                    <Row>
                      <Col lg="1"></Col>
                      <Col lg="5">
                        <Media src={require(`../../../assets/images/blog/${item.thumbnail}`)} alt={item.thumbnail} className="img-fluid"/>
                      </Col>
                      <Col lg="5">
                        <Card style={{
                            top: "50%",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)"}}>
                          <CardBody>
                            <div className="blog-details">
                              <h1 style={{color:"white"}}><b>{item.name}</b></h1>
                              <p className="digits"><Badge color="success">{item.status}</Badge> &nbsp; {item.fromDate +' - '+ item.toDate}</p>
                              <h4>{item.description}</h4>
                              <ul className="blog-social">
                                  {item.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+item.perspectives.length}</li> : ""}
                                  <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+item.watching.length}</li>
                                  <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+item.attendance.length}</li>
                              </ul>
                            </div>
                            <CarouselIndicators items={items1} activeIndex={activeIndex} onClickHandler={goToIndex} />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="1"></Col>
                    </Row>
                  </div>
                </CarouselItem>
              );
          })}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
  );
}

export const CarouselShows2D = (props) => {

  const dispatch = useDispatch()
  const [shows , setLocations] = useState(props.liveShowsData)
  const et = useSelector(state => state.Common.eventType)

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }
  return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
          {shows.map((item) => {
              return (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={item.name}
                >
                  <div className="p-50">
                    <Row>
                      <Col lg="1"></Col>
                      <Col lg="5">
                        <Media src={require(`../../../assets/images/avtar/${item.thumbnail}`)} alt={item.thumbnail} className="img-fluid"/>
                      </Col>
                      <Col lg="5">
                        <Card style={{
                            top: "50%",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)"}}>
                          <CardBody>
                            <div className="blog-details">
                              <h2><b>{item.name}</b></h2>
                              <div><Badge color="success">{item.status}</Badge> <b>Hosted by: {item.talent}</b></div>
                              <div className="blog-bottom-content">
                                  <ul className="">
                                      <li><b>From: </b>{item.fromDate} | <b>To: </b>{item.toDate}</li>
                                  </ul>
                                  <hr/>
                                  <ul className="blog-social p-b-5">
                                      {item.perspectives.length > 0 ? <li><i className="icofont icofont-video-cam"></i> &nbsp; {Perspectives+': '+item.perspectives.length}</li> : ""}
                                      <li className="digits"><i className="icofont icofont-user"></i> &nbsp; {Attendance+': '+item.watching.length}</li>
                                      <li className="digits"><i className="icofont icofont-eye-alt"></i> &nbsp; {Watching+': '+item.attendance.length}</li>
                                  </ul>
                                  <hr/>
                                  <p className="mt-0">{item.description}</p>
                                  <br/>
                                  <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`}>
                                      <Button color="primary btn-block" width="100%">{GoTo+' '+Show[et]}</Button>
                                  </Link>
                              </div>
                            </div>
                            <CarouselIndicators items={items1} activeIndex={activeIndex} onClickHandler={goToIndex} />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="1"></Col>
                    </Row>
                  </div>
                </CarouselItem>
              );
          })}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
  );
}

export const CarouselUpcomingEvnts = (props) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
  const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
  const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
        if (animating) return;
    }


  return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
          {/* <CarouselIndicators items={perspectives} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
          {props.upcomingEventBanners.map((perspective,i) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`Location ${perspective.name}`}>
                <div style={{position:"relative"}}>
                  <div style={{position: "absolute",bottom: "0", left: "50%",  transform: "translate(-50%, -50%)",cursor:"pointer"}}>
                    <Button color="primary"><b>{"Purchase Event - $ 80 MXN"}</b></Button>
                  </div>
                  <Media  
                    src={require(`../../../assets/images/product/${perspective}`)} 
                    alt={perspective.name} className="img-fluid b-r-10" 
                    style={{width:"100%"}}/>
                </div>
              </CarouselItem>
            )
          })}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
  );
}