import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody, Media, Button} from 'reactstrap'
import { Fragment } from 'react'
import { UpgradePerspectives, PurchasePerspective, PreviewPerspective } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow';
import '../../../../assets/css/evnt_cards.css'
import Rating from 'react-rating'
export const PerspectivesUpgrade = (props) => {

    const windowWidth = props.windowWidth
    const [perspectiveToBuy,setPerspectiveToBuy]=useState(props.perspectiveToBuy)
    const [bluredImg, setBluredImg]=useState(true)
    const [rating,setRating] = useState(4)
    
    const [windowType, setWindowType]=useState(null)
    useEffect(()=>{
        if(windowWidth<= 1000 && windowWidth >= 425){
            setWindowType("evnt-card-lg")
        }else{
            setWindowType("evnt-card-md")
        }
    },[windowWidth])

    return(

        <Fragment>
            <Card className={`evnt-card ${windowType}`}>
                <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody style={{color:"white"}}>
                <h2 className="evnt-card-title"><b>{UpgradePerspectives}</b></h2>
                <div className="location-item-layout">
                    <div>
                        <Fragment>
                          {windowWidth<= 1000 && windowWidth >= 425 ? "":
                          <Media  
                           src={require(`../../../../assets/images/banner/cardBanner.jpg`)} 
                           alt={"Card Banner"} className="img-fluid b-r-10" 
                           style={{width:"100%", cursor:"pointer"}}/>}
                          {bluredImg?
                            <div style={{ position: "relative",  textAlign: "center",cursor:"pointer"}}>
                              <Media  
                              className="img-fluid b-r-10 location-thumbnail" 
                              onClick={()=> {
                                props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"perspective",toLocationId:perspectiveToBuy.toLocationId,toPerspectiveId:perspectiveToBuy.toPerspectiveId,type:undefined}})
                                props.checkIfIsOpen({type:props.windowName})
                              }}
                              src={require(`../../../../assets/images/${perspectiveToBuy.src}`)} 
                              alt={perspectiveToBuy.name} 
                              style={{maxWidth:"200px",cursor:"none", marginTop:"10px", marginLeft:"auto", marginRight:"auto", filter:"blur(2px)", WebkitFilter:"blur(2px)"}}/>
                              <div style={{position: "absolute", top: "50%", left: "50%",  transform: "translate(-50%, -50%)"}}>
                                <i className="fa fa-lock fa-5x"></i>
                                <Button style={{width:"100%"}} color="secondary" size="xs" onClick={()=>setBluredImg(false)} >{PreviewPerspective}</Button>
                              </div>
                            </div>:
                            <Fragment>
                                {   perspectiveToBuy.previewType == "img" ?
                                    <Media className="img-fluid b-r-10 location-thumbnail" 
                                    style={{width:"400px", height:"auto",float:"none"}} src={require(`../../../../assets/images/${perspectiveToBuy.src}`)}/>
                                    :
                                    <video
                                    id="UpgradePerspective_Video"
                                    className="vip-suite-video location-thumbnail"
                                    src={require(`../../../../assets/video/${perspectiveToBuy.video}`)}
                                    loop="true"
                                    autostart
                                    controls
                                    crossOrigin={"anonymous"}
                                    ></video>
                                }
                            </Fragment>
                          }
                        </Fragment>
                    </div>
                    <div className="location-data">
                      {windowWidth<= 1000 && windowWidth >= 425 ?
                        <Media  
                         src={require(`../../../../assets/images/banner/cardBanner.jpg`)} 
                         alt={"Card Banner"} className="img-fluid b-r-10" 
                         style={{maxWidth:"300px", cursor:"pointer"}}/>:""}

                        <h6 className="evnt-card-item-title"><b>{perspectiveToBuy.name}</b></h6>
                        <div>
                          <ul className="location-data-list">
                            <li><i className="fa fa-eye"></i>&nbsp;{57+"K"}</li>
                            <li>
                               <Rating
                                 initialRating={rating}
                                 emptySymbol="fa fa-star-o"
                                 fullSymbol="fa fa-star"
                                 onChange={(rate) => setRating(rate)}
                               ></Rating>
                             </li>
                          </ul>
                        </div>
                        <Button className="location-button" onClick={()=>props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"perspective",item:perspectiveToBuy.name,price:{amount:15,currency:"MXN"},toLocationId:perspectiveToBuy.toLocationId,toPerspectiveId:perspectiveToBuy.toPerspectiveId,type:undefined}})} color="primary" >{PurchasePerspective}</Button>  
                    </div>      
                </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default PerspectivesUpgrade