import React,{Fragment,useEffect,useState} from 'react';
import { Collapse } from 'reactstrap';
import { Card,CardHeader,CardBody,Input,Media,Button,Form,FormGroup} from 'reactstrap';
import {Attendance, SearchGroups} from '../../constant';
import ScrollArea from 'react-scrollbar';
// import { setEventCaster } from '../../redux/common/actions'

import {UserThumbnail} from '../general/UserThumbnail'

const UsersGroups = (props) => {
    
    const height = props.height || "auto"

    const userThumbnail = (userID) => {
        var usersData = require("../../api/usercard.json")
        const user = usersData.filter(user=> user.id == userID)
        return user[0].profilePicture
    }

    return (
        <Fragment>
            {props.searchBar == undefined || props.searchBar != false ?
                <Fragment>
                    <Form>
                        <FormGroup className="m-0">
                            <Input className="form-control-social" type="search" placeholder={`${SearchGroups}...`} />
                        </FormGroup>
                    </Form> 
                    <br/>
                </Fragment>: ""}    
                <ScrollArea
                    speed={0.5}
                    horizontal={false}
                    vertical={true}
                    style={{height:height}}>
                    { props.userGroups.map((group,i)=>{ 
                            return(
                                <Fragment>
                                    <p style={{color:"white",display:"inline-block",cursor:"pointer"}} onClick={() => props.checkIfIsOpen({type:"ChatTab",data:{name:group.groupName,img:group.img}})}>
                                        <div className="customers inline-flex avatar-group" style={{display:"flex"}}>
                                            <ul>
                                              <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require(`../../assets/images/${group.img}`)} alt=""/></li>
                                              {/* <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require(`../../assets/images/${userThumbnail(group.users[1])}`)} alt=""/></li>
                                              <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require(`../../assets/images/${userThumbnail(group.users[2])}`)} alt=""/></li> */}
                                            </ul>
                                            <p style={{paddingLeft:"5px",fontSize:"18px"}}>{group.groupName}</p>
                                        </div>
                                    </p>
                                    <br/>
                                </Fragment>
                            )
                        })
                    }
                </ScrollArea>
        </Fragment>
    );
}

export default UsersGroups;