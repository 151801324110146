import React, { Fragment } from 'react'
import Chart from 'react-apexcharts'
import { apexRadarPolygonfillCharts } from "../../../../charts/ApexCharts/ApexData";

export const TeamStatistics_graph = (props) => {
    
    return(
        <Fragment>
            <div id="radarchart">
                <Chart options={apexRadarPolygonfillCharts.options} series={apexRadarPolygonfillCharts.series} type="radar" height={280} />
            </div>
        </Fragment>
    )
}

export default TeamStatistics_graph