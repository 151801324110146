import React, { useState, useEffect, Fragment } from 'react'
import {useSelector} from 'react-redux'
import {Label,Row,Col,Media,Input} from 'reactstrap'
import DatePicker from "react-datepicker"
import LabeledInput from '../../../../../general/LabeledInput'
import LabeledDropDown from '../../../../../general/LabeledDropDown'
import {EventName,MediaLink,MediaInputOptions,Save,Cancel,
    EventDescription,FacebookLink,InstagramLink,TwitterLink,LinkedinLink, EventGraphic, EventGeneralInfo, GraphicSoruce, EventVenue, EventCountry, EventState, EventCity, FromDate, Today, ToDate, ShowEventCounters, MainEventType, PlatformTypes, EventPricePasswordTerms, EventPrice, Currency, CurrencyList, EventTermsConditions, CatalogAccess, GeneralInfo, Administrators, GuestsList, Sponsors, EventTypeOptions} from "../../../../../../constant"

const GeneralInfoForm = (props) =>  {

  const editMode = useSelector(state => state.Common.editMode)
  const [event , setEvent] = useState(props.eventData)
  
  const [startDate,setstartDate] = useState(new Date())
  const [endDate,setendDate] = useState(new Date())

  const [selectedOption, setDropdownOption] = useState('')
  
  useEffect(() => {
    console.log(selectedOption)
  },[selectedOption])
  
  return (
    <Fragment>
        <h1>{EventGeneralInfo}</h1>
        <hr/>
        <Row>
          <Col lg='6'>
            <Row>
              <Col lg='12'>
                <LabeledInput label={EventName} placeholder={EventName} type={'text'} value={event.name} />
              </Col>
              <Col lg="6">
                <Label><b>{FromDate}</b></Label>
                <DatePicker className="form-control digits" showPopperArrow={false} selected={endDate} showTimeSelect dateFormat="Pp" />
              </Col>
              <Col lg="6">
                <Label><b>{ToDate}</b></Label>
                <DatePicker className="form-control digits" showPopperArrow={false} selected={startDate} showTimeSelect dateFormat="Pp" />
              </Col>
              <Col lg='6'>
                <LabeledInput label={EventCity} placeholder={EventCity} type={'text'} value={event.location.country}/>
              </Col>
              <Col lg='6'>
                <LabeledInput label={EventVenue} placeholder={EventVenue} type={'text'} value={event.location.venue}/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={EventState} placeholder={EventState} type={'text'} value={event.location.city}/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={EventCountry} placeholder={EventCountry} type={'text'} value={event.location.state}/>
              </Col>
              <Col lg='12'>
                <LabeledInput label={EventDescription} placeholder={EventDescription} type={'textarea'} rows={4} value={event.description} />
                <br/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={FacebookLink} placeholder={FacebookLink} type={'text'} value={event.socialMedia.facebook}/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={InstagramLink} placeholder={InstagramLink} type={'text'} value={event.socialMedia.instagram}/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={TwitterLink} placeholder={TwitterLink} type={'text'} value={event.socialMedia.twitter}/>
              </Col>
              <Col lg='6'>
                  <LabeledInput label={LinkedinLink} placeholder={LinkedinLink} type={'text'} value={event.socialMedia.linkedin}/>
              </Col>
              <Col lg='6'>
                <LabeledInput label={EventPrice} placeholder={EventPrice} type={'number'} value={event.price.amount} />
              </Col>
              <Col lg='6'>
                <LabeledDropDown label={Currency} options={CurrencyList}/>
              </Col>
            </Row>
          </Col>
          <Col lg='6'>
            <Row>
              <Col lg='6'>
                <Row>
                  <Col lg='12'>
                    <LabeledDropDown label={MainEventType} options={PlatformTypes}/>
                  </Col>
                  <Col lg='12'>
                    <LabeledInput label={EventTermsConditions} placeholder={EventTermsConditions+' Link'} type={'text'} value={event.speacialTermsOrConditions}/>
                  </Col>
                </Row>
              </Col>
              <Col lg='6'>
                <LabeledDropDown label={EventTypeOptions} options={PlatformTypes} multiple={true}/>
              </Col>
              <Col lg='6'>
                <div className="checkbox checkbox-primary p-10">
                  <Input id="checkbox-primary-1" type="checkbox" defaultChecked/>
                  <Label for="checkbox-primary-1"><b>{ShowEventCounters}</b></Label>
                </div>
              </Col>
              <Col lg='6'>
                <div className="checkbox checkbox-primary p-10">
                  <Input id="checkbox-primary-2" type="checkbox" defaultChecked/>
                  <Label for="checkbox-primary-2"><b>{CatalogAccess}</b></Label>
                </div>
              </Col>
              <Col lg='6'>
                <LabeledDropDown label={GraphicSoruce} options={MediaInputOptions} setSelectedOption={option => setDropdownOption(option)}/>
              </Col>
              <Col lg='6'>
                {selectedOption != 'Image' ? <LabeledInput label={MediaLink} placeholder={MediaLink} type={'file'} value={''} /> :
                <LabeledInput label={MediaLink} placeholder={MediaLink} type={'text'} value={''} value={selectedOption} /> }
              </Col>
              <Col lg="12">
                <Media style={{width:'100%'}} className="img-fluid m-r-20 p-2" src={require(`../../../../../../assets/images/banner/${event.mainGrafic.src}`)} alt="Event Main Grafic" />
              </Col>
            </Row>
          </Col>
        </Row>
    </Fragment>
  )
}

export default GeneralInfoForm
