import React,{Fragment} from 'react';
import ImageGallery from '../../../../../EditMode_General/PlatformTypes/2D/ImageGallery'
const Data_3D = (props) => {
  return (
    <Fragment>
      <h3>{'3D VIEW DATA'}</h3>
      <hr/>
      <ImageGallery/>
    </Fragment>
  );
};

export default Data_3D;