import React, { useState, useEffect, Fragment } from 'react'
import {Button,Row,Col,Label,Input} from 'reactstrap'
import {Link}  from 'react-router-dom'
import axios from 'axios'
import {Administrators,AddAdministrator,AdministratorsList,AdministratorsBulkUpload,
  DonwloadCSVTemplate,ChooseAdminCSV,SendRegistrationReminder} from "../../../../constant"

import AdministratorsTable from './AdministratorsTable'
import AdministratorsForm from './AdministratorsForm'

const AdministratorsData = (props) =>  {

  const [administrators , setAdministrators] = useState(props.administratorsList) 
  const [tableOrForm, setTableOrForm] = useState(true)

  axios.defaults.baseURL = `http://localhost:4000`
  const [adminUsers , setAdminUsers] = useState([])
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    axios.get(`/users`)
    .then(res => { 
        var adminIDs = []
        administrators.forEach(admin => {
            adminIDs.push(admin.id)
        })

        var allUsers = res.data
        allUsers.forEach(tempUser =>{
            if(adminIDs.indexOf(tempUser.id) !== -1) 
            {
                tempUser.permissions = administrators[adminIDs.indexOf(tempUser.id)].permissions
                setAdminUsers(adminUsers => [...adminUsers,tempUser])
                console.log('Setting Admin: ',tempUser)
                setLoading(false)
            }
        })
    }).catch(err => console.error("01",err))
  },[administrators])
  
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Fragment>
    <h1>{Administrators}</h1>
    <hr/>
    {tableOrForm? 
      <Row>
        <Col lg="4">
            <Label>
                <b>{AdministratorsBulkUpload}</b> &nbsp;
                <small>
                    <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                    {DonwloadCSVTemplate}
                    </Link>
                </small>
            </Label>
            <div className="custom-file">
              <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
              <Label className="custom-file-label" htmlFor="validatedCustomFile">{ChooseAdminCSV}</Label>
              <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
            </div>
        </Col>
        <Col lg="8">
            <Button color="primary" className="mr-1 f-right m-t-20" onClick={() => setTableOrForm(!tableOrForm)} ><i className="fa fa-plus-circle fa-lg"></i>&nbsp; &nbsp;{AddAdministrator}</Button>
            <Button color="warning" className="mr-1 f-right m-t-20" >{SendRegistrationReminder}</Button>
        </Col>
      </Row>:<Button color="primary" className="mr-1 f-right m-t-20" onClick={() => setTableOrForm(!tableOrForm)} ><i className="fa fa-list fa-lg"></i>&nbsp; &nbsp;{AdministratorsList}</Button>}
    <br/>
    {tableOrForm? <AdministratorsTable administratorsData={adminUsers}/>:<AdministratorsForm/>}

    </Fragment>
  )
}

export default AdministratorsData
