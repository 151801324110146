import React, {useState,useEffect} from 'react'
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react'
import man from '../../assets/images/dashboard/user.png'
import {firebase_app} from '../../data/config'
import { SportsCaster } from "../../constant";
import {withRouter} from 'react-router-dom';
import { Fragment } from 'react';

export const UserThumbnail = (props) => {

    const [profile, setProfile] = useState('');
    const [name, setName] = useState('')

    // auth0 profile
    const {logout} = useAuth0()
    const authenticated = JSON.parse(localStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

    axios.defaults.baseURL = `http://localhost:4000`
    const [isLoading, setLoading] = useState(true)
    const [userID , setUserID] = useState(props.userID)  
    // const [userName , setUserName] = useState(props.userName)
    // const [userRole , setUserRole] = useState(props.userRole)
    const [users , setUsers] = useState([])
  

    useEffect(() => {

      setProfile(localStorage.getItem('profileURL' || man));
      setName(localStorage.getItem('Name'))
      var usersData = require("../../api/usercard.json")
      userID.forEach(ID =>{
        var user = usersData.filter(user =>  user.id == ID)
        console.log('Setting User Thumbnail: ', user)
        setUsers(users => [...users,user[0]])
      })
      setLoading(false)

    },[]);

    const Logout_From_Firebase = () => {
      localStorage.removeItem('profileURL')
      localStorage.removeItem('token');
      firebase_app.auth().signOut()
      props.history.push(`${process.env.PUBLIC_URL}/login`)
    }
  
    const  Logout_From_Auth0 = () =>  {
      localStorage.removeItem("auth0_profile")
      localStorage.setItem("authenticated",false)
      props.history.push(`${process.env.PUBLIC_URL}/login`)
      logout()
    }
    
    if (isLoading) {
      return <div className="App">Loading...</div>;
    }

    var currentUserID = 1 
    return(
      <Fragment>
        {users.map((data, i) => {
          return( 
            <div id={`user_ ${data.id}`}>
              { data.id != currentUserID || props.showCurrentUser == true ? 
                <span  style={{cursor:"pointer",paddingBottom:"10px"}} key={data.id} className="media user-header">
                  <img className="mr-2 rounded-circle img-35" src={require(`../../assets/images/${data.profilePicture}`)} style={{width:"35px",height:"35px"}} alt="" onClick={()=>props.thumbnailClick(data.id)}/>
                  <span className="media-body">
                    <span style={{color:"white",fontSize:14}}  className="f-12 f-w-600" onClick={()=>props.thumbnailClick(data.id)}>{authenticated ? auth0_profile.name :  data.name+' '+data.lastName}</span>&nbsp;
                    {data.type == undefined ? <Fragment><i className="fa fa-circle font-info"/>&nbsp;<i className="fa fa-circle font-success" /></Fragment>:<i className="fa fa-lock" />}
                    {data.id == currentUserID ? <span className="d-block" style={{fontSize:12,cursor:"pointer",color:"white"}} onClick={()=> props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"tokens",item:"Tokens",price:{amount:"15",currency:"MXN"}}})}><b>{data.tokens+" Tokens"}</b></span>:""}
                  </span>
                </span> : ""
              }
            </div>
          )
        })}
      </Fragment>
    )
    
}

// HEADER USERS THUMBNAILS

export const HeaderUserThumbnail = (props) => {

  const [currentUser, setCurrentUser] = useState({})

  // auth0 profile
  const {logout} = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

  axios.defaults.baseURL = `http://localhost:4000`
  const [isLoading, setLoading] = useState(true) 
  // const [userName , setUserName] = useState(props.userName)
  // const [userRole , setUserRole] = useState(props.userRole)


  useEffect(() => {
    const usersData = require("../../api/usercard.json")
    var user = usersData.filter(user =>  user.id == 1)[0]
    console.log('Setting Current User Header Thumbnail: ', user)
    setCurrentUser(user)
    setLoading(false)
  },[]);

  const Logout_From_Firebase = () => {
    localStorage.removeItem('profileURL')
    localStorage.removeItem('token');
    firebase_app.auth().signOut()
    props.history.push(`${process.env.PUBLIC_URL}/login`)
  }

  const  Logout_From_Auth0 = () =>  {
    localStorage.removeItem("auth0_profile")
    localStorage.setItem("authenticated",false)
    props.history.push(`${process.env.PUBLIC_URL}/login`)
    logout()
  }
  
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return(
    <Fragment>
      <div id={`user_ ${currentUser.id}`}> 
          <span  style={{cursor:"pointer",paddingBottom:"10px"}} key={currentUser.id} className="media user-header">
            <img className="mr-2 rounded-circle img-35" src={require(`../../assets/images/${currentUser.profilePicture}`)} style={{width:"35px",height:"35px"}} alt="" onClick={()=> props.checkIfIsOpen({type:"UserProfileCard",data:{userID:currentUser.id}})}/>
            {props.width <= 1000 ?
              "" : 
              <span className="media-body">
                <span style={{color:"white",fontSize:14}}  className="f-12 f-w-600" onClick={()=> props.checkIfIsOpen({type:"UserProfileCard",data:{userID:currentUser.id}})}>{authenticated ? auth0_profile.name :  currentUser.name+' '+currentUser.lastName}</span>&nbsp;
                <span className="d-block" style={{fontSize:12,cursor:"pointer",color:"white"}} onClick={()=> props.checkIfIsOpen({type:"paymentCard",data:{itemToPurchase:"tokens",item:"Tokens",price:{amount:"15",currency:"MXN"}}})}>
                  <i className="fa fa-usd" style={{color:"white"}}> &nbsp; </i><b>{currentUser.tokens+" Tokens"}</b>
                </span>
              </span>
            }
          </span> 
      </div>
    </Fragment>
  )
  
}

export const HeaderEventCaster = (props) => {

  const usersData = require("../../api/usercard.json")
  const [eventCaster, setEventCaster] = useState(usersData[props.eventCasterID])

  useEffect(()=>{
    setEventCaster(usersData.filter(user=> user.id === props.eventCasterID)[0])
  },[props.eventCasterID])

  return(
    <Fragment> 
        <span style={{cursor:"pointer"}} key={eventCaster.id} className="media user-header" style={{marginTop:"0px"}}>
          <img className="mr-2 rounded-circle img-35" src={require(`../../assets/images/${eventCaster.profilePicture}`)} style={{width:"35px",height:"35px"}} alt=""/>
          <span className="media-body">
            <span className="f-12 f-w-600" >{eventCaster.name+' '+eventCaster.lastName}</span>
            <p className="d-block" style={{fontSize:12}} ><span className="badge badge-pill badge-success"><i style={{color:"white"}}className="fa fa-microphone fa-lg"></i></span>&nbsp;{"SportsCaster"}</p>
          </span>
        </span>
    </Fragment>
  )
}

// LIST USERS THUMBNAILS

export const ContactsThumbnails = (props) => {

  const [isLoading, setLoading] = useState(true)
  const [users , setUsers] = useState([])


  useEffect(() => {
    var usersData = require("../../api/usercard.json")
    props.userID.forEach(ID =>{
      var user = usersData.filter(user =>  user.id == ID)
      console.log('Setting User Thumbnail: ', user)
      setUsers(users => [...users,user[0]])
    })
    setLoading(false)

  },[])
  
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  var currentUserID = 1 
  return(
    <Fragment>
      {users.map((data, i) => {
        return( 
          <div id={`user_ ${data.id}`}>
            <span  style={{cursor:"pointer",paddingBottom:"10px"}} key={data.id} className="media user-header">
              <img className="mr-2 rounded-circle img-35" src={require(`../../assets/images/${data.profilePicture}`)} style={{width:"35px",height:"35px"}} alt="" onClick={()=>props.thumbnailClick(data.id)}/>
              <span className="media-body">
                <span style={{color:"white",fontSize:14}}  className="f-12 f-w-600" onClick={()=>props.thumbnailClick(data.id)}>{data.name+' '+data.lastName}</span>&nbsp;
                <Fragment>
                  {true ? <i className="fa fa-circle font-info"/> :""} &nbsp;
                  {true ? <i className="fa fa-circle font-success" /> :""}
                </Fragment>
              </span>
            </span>
          </div>
        )
      })}
    </Fragment>
  )
  
}

export const EventCasters = (props) => {

  const [isLoading, setLoading] = useState(true)
  const [users , setUsers] = useState([])


  useEffect(() => {
    var usersData = require("../../api/usercard.json")
    props.userID.forEach(ID =>{
      var user = usersData.filter(user =>  user.id == ID)
      console.log('Setting User Thumbnail: ', user)
      setUsers(users => [...users,user[0]])
    })
    setLoading(false)

  },[])
  
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  var currentUserID = 1 
  return(
    <Fragment>
      {users.map((data, i) => {
        return( 
          <div id={`user_ ${data.id}`}>
            <span  style={{cursor:"pointer",paddingBottom:"10px"}} key={data.id} className="media user-header">
              <img className="mr-2 rounded-circle img-35" src={require(`../../assets/images/${data.profilePicture}`)} style={{width:"35px",height:"35px"}} alt="" onClick={()=>props.thumbnailClick(data.id)}/>
              <span className="media-body">
                <span style={{color:"white",fontSize:14}}  className="f-12 f-w-600" onClick={()=>props.thumbnailClick(data.id)}>{data.name+' '+data.lastName}</span>&nbsp;
                <i className="fa fa-lock" />
              </span>
            </span>
          </div>
        )
      })}
    </Fragment>
  )
  
}