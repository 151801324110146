import React, { useState, useEffect, Fragment } from 'react'
import {Table,Row,Col,Label, Input, Button, Badge} from 'reactstrap'
import {UserThumbnail} from '../../../general/UserThumbnail'
import { Actions, Administrators, AdministratorsBulkUpload, ChooseAdminCSV, 
    DonwloadCSVTemplate, Email, List, Registered, SendRegistrationReminder, 
    User, UserPermissions, Delete, Edit } from '../../../../constant'

const AdministratorsTable = (props) =>  {

const [administrators , setAdministrator] = useState(props.administratorsData)
  
  return (
    <Fragment>
        
        <div className="table-responsive m-t-20">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{User}</th>
                        <th scope="col">{Registered}</th>
                        <th scope="col">{Email}</th>
                        <th scope="col">{UserPermissions}</th>
                        <th scope="col">{Actions}</th>
                    </tr>
                </thead>
                <tbody>
                {administrators.map((data, i) => {
                    return (
                        <tr key={data.id}>
                            <th scope="row">{i}</th>
                            <td>
                              <UserThumbnail userID={[data.id]}/>
                            </td>
                            <td><Badge color="success">{Registered}</Badge></td>
                            <td>{data.email}</td>
                            <td>{data.permissions}</td>
                            <td>
                                <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{Delete}</Button>
                                <Button color="warning" className="btn-xs mr-1 f-none m-t-20" >{Edit}</Button>
                                <Button outline color="warning" className="btn-xs mr-1 f-none m-t-20" >{SendRegistrationReminder}</Button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    </Fragment>
  )
}

export default AdministratorsTable
