import React from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody, Media, Button} from 'reactstrap'
import { Fragment } from 'react'
import { UpgradePerspectives } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow';

export const SocialSharing = (props) => {

    const UpgradePerspective = () => {
        props.leftSideTabs(false,"UpgradePerspective")
        // props.changePerspective({toLocationId:0,toPerspectiveId:12,type:undefined})
    }
    
    return(
        <Fragment>
            <Card className={"evnt-card"}>
                <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody style={{color:"white"}}>
                <Media style={{width:"100%", height:"100%",float:"none"}} src={require('../../../../assets/images/product/facebookPost.jpg')}/>  
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default SocialSharing