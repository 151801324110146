import React, {useState,Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, Media } from 'reactstrap';

const CollapsedStories = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const rightSidebarToggle = useSelector(state => state.Common.rightSidebarToggle);

  return(
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
        <div onClick={() => props.checkIfIsOpen({type:"user_story"})} className="customers d-inline-block avatar-group">
          <ul>
            <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require('../../assets/images/user/3.jpg')} alt=""/></li>
            <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require('../../assets/images/user/5.jpg')} alt=""/></li>
            <li className="d-inline-block"><Media body className="img-40 rounded-circle" src={require('../../assets/images/user/1.jpg')} alt=""/></li>
          </ul>
        </div>
      </DropdownToggle>
      {/* <DropdownMenu className="p-0">
          <ul className="droplet-dropdown">
            <li>
              <div className="avatars">
                  <div className="avatar"><Media body className="img-50 rounded-circle"  src={require('../../assets/images/user/1.jpg')} alt="#"/>
                    <div className="status status-50 bg-success"> </div>
                  </div>
              </div>
            </li>
          </ul>
      </DropdownMenu> */}
    </Dropdown>
  )
}
export default CollapsedStories