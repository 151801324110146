import React, { Fragment } from 'react'
import {Card,CardHeader,CardBody} from 'reactstrap'
import { TimelinePrimaryColor} from "../../constant"
import EventTimeLine from './EventTimeLine'

const EventTimeLineCard = () => {
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h5>{TimelinePrimaryColor}</h5>
        </CardHeader>
        <CardBody>
            <EventTimeLine/>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EventTimeLineCard;