import React,{useState,Fragment} from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import ShowLeftBar from './ShowLeftBar';
import {Container,Row,Col,Card,CardHeader,CardBody,Button,Nav,NavItem,NavLink,TabContent,TabPane,InputGroup,InputGroupAddon,Input,Form,FormGroup,Label} from 'reactstrap'
import {Live,Information,Chat,Questions,Resources,Upcoming,FromDate,ToDate,Recorded,NotAvailable,Description,Show,Title,StreamingType,StreamingProvider,ShowLink,Youtube,Vimeo,Other,Preview} from '../../constant'
import ConferenceChat from './ConferenceChat'
import ConferenceQuestions from './ConferenceQuestions'
import {useSelector} from 'react-redux'
import UpcommingShows from './UpcommingShows'

const LearningDeatil = (props) => {
  
  const [BasicLineTab, setBasicLineTab] = useState('1')
  const [BottomLineTab, setBottomLineTab] = useState('1')
  const editMode = useSelector(state => state.Common.editMode)
  const et = useSelector(state => state.Common.eventType)

    return (
        <Fragment>
          <BreadCrumb  subparent="Shows" title="Web Development"/>
            <Container fluid={true}>
            <Row>
              <Col xl="9 xl-60">
                <Card>
                  <CardHeader>
                    {editMode? <Label><b>{Show+' '+Title+':'}</b></Label>: ''}
                    <h1 className="f-w-600">
                      {editMode? <Input className="form-control digits" type="text" defaultValue={Show+' '+Title} />: Show+' '+Title}
                      <Form>
                        <FormGroup>
                          <Row>
                            <Col lg="6">
                              <p>{FromDate+':'} {editMode? <Input className="form-control digits" type="time" defaultValue="21:45:00" />:"30/05/2021 - 13:00"}</p>  
                            </Col>
                            <Col lg="6">
                              <p>{ToDate+':'} {editMode? <Input className="form-control digits" type="time" defaultValue="21:45:00" />:"30/05/2021 - 13:00"}</p>  
                            </Col>
                            <Col lg="12">
                              {editMode? '':
                                <p> 
                                  <span className="badge badge-success">{Live}</span> &nbsp;
                                  <span><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i><i className="fa fa-star font-warning"></i></span>
                                </p>}
                            </Col>
                            {editMode? 
                            <Col lg="4">
                              <p><Label><b>{StreamingType+':'}</b></Label>
                              <Input type="select" name="select" className="form-control"  multiple="">
                                <option value={Live}>{Live}</option>
                                <option value={Recorded}>{Recorded}</option>
                                <option value={NotAvailable}>{NotAvailable}</option>
                              </Input> </p>
                            </Col>:''} 
                            {editMode?
                            <Col lg="4">
                              <p><Label><b>{StreamingProvider+':'}</b></Label>
                              <Input type="select" name="select" className="form-control"  multiple="">
                                <option value={Youtube}>{Youtube}</option>
                                <option value={Vimeo}>{Vimeo}</option>
                                <option value={Other}>{Other}</option>
                              </Input> </p>
                            </Col>:''} 
                            {editMode?
                            <Col lg="4">
                              <p><Label><b>{ShowLink+':'}</b></Label>
                              <InputGroup className="text-box">
                                <Input
                                    type="text"
                                    className="form-control input-txt-bx"
                                    placeholder={ShowLink}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button color="primary">{Preview}</Button>
                                </InputGroupAddon>
                              </InputGroup></p>
                            </Col>: ''}
                          </Row>
                        </FormGroup>
                      </Form>
                    </h1>
                  </CardHeader>
                    <CardBody>
                      
                    {editMode? '':
                      <center>
                        <iframe src="https://player.vimeo.com/video/414714818" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      </center>}
                      <Nav className="border-tab" tabs>
                        <NavItem>
                            <NavLink   className={BasicLineTab === '1' ? 'active' : ''} onClick={() => setBasicLineTab('1')}><i className="icofont icofont-info-circle"></i>{Information}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink  className={BasicLineTab === '2' ? 'active' : ''} onClick={() => setBasicLineTab('2')}><i className="icofont icofont-chat"></i>{Chat}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink  className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('3')}><i className="icofont icofont-question"></i>{Questions}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink  className={BasicLineTab === '4' ? 'active' : ''} onClick={() => setBasicLineTab('4')}><i className="icofont icofont-files"></i>{Resources}</NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={BasicLineTab}>
                        <TabPane  className="fade show" tabId="1">
                         {editMode? 
                          <FormGroup className="mb-0">
                            <Label><b>{Show[et]+' '+Description+':'}</b></Label>
                            <Input type="textarea" className="form-control"  rows="3"  placeholder={Show[et]+' '+Description}/>
                          </FormGroup> :  
                          <p className="mb-0">{Show[et]+' '+Description}</p> }
                        </TabPane>
                        <TabPane tabId="2">
                          <ConferenceChat/>
                        </TabPane>
                        <TabPane tabId="3">
                          <ConferenceQuestions/>
                        </TabPane>
                        <TabPane tabId="4">
                          <ConferenceQuestions/>
                        </TabPane>
                      </TabContent> 
                    </CardBody>
                </Card>
                <h2>{Upcoming+' '+Show[et]}</h2>
                <hr/>
                <UpcommingShows/>
              </Col>
              <ShowLeftBar/>
            </Row>
          </Container>
        </Fragment>
    );
};

export default LearningDeatil;