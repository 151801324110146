import React,{Fragment,useState,useEffect} from 'react';
import Lightbox from "react-image-lightbox";
import {Container,Row,Col,Card,CardHeader,CardBody,Media} from 'reactstrap'
import {IMAGE_GALLERY} from "../../../../../constant";
import axios from 'axios'
import { forEach } from 'lodash';
const ImageGallery = () => {
    
    const initilindex = {index:0,isOpen:false}
    const[photoIndex,setPhotoIndex] = useState(initilindex)
    const [images,setImage] = useState([]) 
    const [smallImages,setsmallImages] = useState([])

    useEffect(() => {
        const images = 
        {
            src : [
            "lightgallry/013.jpg",
            "lightgallry/015.jpg",
            "lightgallry/014.jpg",
            "lightgallry/016.jpg",
            "lightgallry/01.jpg",
            "lightgallry/04.jpg",
            "lightgallry/05.jpg",
            "lightgallry/06.jpg",
            "lightgallry/012.jpg",  
            "lightgallry/02.jpg",
            "lightgallry/07.jpg",
            "lightgallry/03.jpg"
            ]
        }

        images.src.forEach(image =>{
            console.log("Set image for gallery: ",image)
            setsmallImages(img => [...img,image])
        })
    },[])

    const onMovePrev = () => {
        const prev = (photoIndex.index + images.length - 1) % images.length
        setPhotoIndex({...photoIndex,index:prev})
    }
    const  onMoveNext = () => {
        const next = (photoIndex.index + 1) % images.length
        setPhotoIndex({...photoIndex,index:next})
    }
        
    return(
        <Fragment>
            <Container fluid={true}>
                <Row>
                    {smallImages.map((data,i)=>{
                        return(
                            <figure className="col-xl-3 col-sm-6">
                                <Media
                                    src={require(`../../../../../assets/images/${data}`)}
                                    alt="Gallery"
                                    className="img-thumbnail"
                                    onClick={() =>
                                        setPhotoIndex({ ...photoIndex,index:i, isOpen:true})
                                    }
                                />
                            </figure>
                        )
                    })}
                </Row>     
            </Container>
            {photoIndex.isOpen && (
                <Lightbox
                    mainSrc={require(`../../../../../assets/images/${images[photoIndex.index]}`)}
                    nextSrc={require(`../../../../../assets/images/${images[(photoIndex.index + 1) % images.length]}`)}
                    prevSrc={require(`../../../../../assets/images/${images[(photoIndex.index + images.length - 1) % images.length]}`)}
                    imageTitle={photoIndex.index + 1 + "/" + images.length}
                    onCloseRequest={() => setPhotoIndex({ ...photoIndex,isOpen:false})}
                    onMovePrevRequest={onMovePrev}
                    onMoveNextRequest={onMoveNext}
                />
            )}
        </Fragment>
    );
    }


export default ImageGallery;