import React,{Fragment,useEffect,useState} from 'react';
import { Collapse } from 'reactstrap';
import { Card,CardHeader,CardBody,Input,Media,Button,Form,FormGroup} from 'reactstrap';
import {Attendance, SearchContacts} from '../../constant';
import ScrollArea from 'react-scrollbar';
// import { setEventCaster } from '../../redux/common/actions'

import {ContactsThumbnails,EventCasters} from '../general/UserThumbnail'

const UsersList = (props) => {
    
    const height = props.height || "auto"
    const [users , setUsers] = useState(props.usersList)
    
    useEffect(()=>{
        console.log("Setting Users List",users)
    })
    
    switch(props.listType){
        default:
        case "users":
            return (
                <Fragment>
                    {props.searchBar == undefined || props.searchBar != false ?
                        <Fragment>
                            <Form>
                                <FormGroup className="m-0">
                                    <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                                </FormGroup>
                            </Form> 
                            <br/>
                        </Fragment>: ""}    
                        <ScrollArea
                            speed={0.5}
                            horizontal={false}
                            vertical={true}
                            style={{height:height}}>
                                <ContactsThumbnails userID={users} thumbnailClick={props.setSportCaster}/>
                        </ScrollArea>
                </Fragment>
            );

        case "casters":
            return (
                <Fragment>
                    {props.searchBar == undefined || props.searchBar != false ?
                        <Fragment>
                            <Form>
                                <FormGroup className="m-0">
                                    <Input className="form-control-social" type="search" placeholder={`${SearchContacts}...`}/>
                                </FormGroup>
                            </Form> 
                            <br/>
                        </Fragment>: ""}    
                        <ScrollArea
                            speed={0.5}
                            horizontal={false}
                            vertical={true}
                            style={{height:height}}>
                                <EventCasters userID={users} thumbnailClick={props.setSportCaster}/>
                        </ScrollArea>
                </Fragment>
            );

    }
}

export default UsersList;