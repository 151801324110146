import React,{Fragment, useState,useEffect,useRef} from 'react';
import BreadCrumb from '../../../layout/Breadcrumb'
import {Container,Row,Col,Card,Table,Button,Media,Input,Label,InputGroupAddon,InputGroupText,InputGroup} from 'reactstrap'
import Tablet from './tabsets'
import Slider from 'react-slick'
import {MoreVertical} from 'react-feather'
import {Link,useHistory} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import {addToCart,watchfetchSingleProducts } from '../../../redux/ecommerce/Product/action';
import Ratings from 'react-ratings-declarative'
import {Product,ProductReview,ProductDescription,ProductName,BuyNow,Brand,Availability,AddToCart,Add,ContinueShopping,OutOfStock,ProductBrand,OrderHistoryTable,ProductSizeArray,ProductsYouMayLike,TopRatedByClients} from "../../../constant"
import ProductGrid from './ProductGrid'

const  Productpage = (props)  => { 
    const history = useHistory()
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [rating,setRating] = useState(0)
    // eslint-disable-next-line
    const [quantity,Setquantity] = useState(1)
    const [orders,setOrders] = useState([])
    const editMode = useSelector(state => state.Common.editMode)
    
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(watchfetchSingleProducts())
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          });
      } ,[dispatch]);
    const { nav1, nav2 } = state;
    const singleItem = useSelector(content => content.data.singleItem)
    const symbol = useSelector(content => content.data.symbol)

    const  addcart = (product, qty) => 
    {
        dispatch(addToCart(product, qty));
        history.push(`${process.env.PUBLIC_URL}/ecommerce/cart`)
    }
    const buyProduct = (product, qty) => 
    {
        dispatch(addToCart(product, qty)); 
        history.push(`${process.env.PUBLIC_URL}/ecommerce/checkout`)
    }

    const changeRating =  (newRating) => {
        setRating(newRating)
    }
    
    return (
        <Fragment>
            <BreadCrumb  subparent="Ecommerce" title="Product Page" statisticsBTN={true}/>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <Card>
                            <Row className="product-page-main">
                                {editMode?'':
                                <Col xl='4'>
                                    <Slider  asNavFor={nav2} 
                                             arrows= {false}
                                         ref={slider => (slider1.current = slider)} className="product-slider">
                                        {singleItem.variants ? singleItem.variants.map((item, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <Media src={require("../../../assets/images/" + item.images)} alt="" className="img-fluid" />
                                                </div>
                                            )
                                        }) :
                                            <Media src={singleItem.img} alt="" className="img-fluid" />
                                        }   
                                    </Slider>
                                    <Slider asNavFor={nav1}
                                        ref={slider => (slider2.current= slider)}
                                        slidesToShow={4}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        infinite={true}
                                        className="small-slick">
                                        {singleItem.variants ? singleItem.variants.map((item, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <Media src={require("../../../assets/images/" + item.images)} alt="" className="img-fluid" />
                                                </div>
                                            )
                                        }) : ''}
                                    </Slider>
                                </Col>}
                                <Col xl={editMode?12:8}>
                                    <div className="product-page-details">
                                        {editMode? '':<h3><b>{ProductName}</b></h3>}
                                        {editMode? 
                                            <Row>
                                                <Col lg="6">
                                                    <Label><b>{ProductName+':'}</b></Label>
                                                    <Input className="form-control digits" type="text" defaultValue={ProductName} />
                                                </Col>
                                                <Col lg="6">
                                                    <Label><b>{ProductBrand+':'}</b></Label>
                                                    <Input className="form-control digits" type="text" defaultValue={ProductBrand} />
                                                </Col>
                                            </Row>
                                         :''}

                                        {editMode? '':
                                        <div className="d-flex">
                                            <Ratings
                                                rating={rating}
                                                widgetRatedColors="blue"
                                                changeRating={changeRating}
                                            >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>: 
                                            <span>{ProductReview}</span>
                                        </div>}
                                    </div>
                                    <br/>
                                    {editMode? '':<p className="mb-0">{ProductDescription}</p>}
                                    {editMode? '':
                                    <div>
                                        <Table className="product-page-width" borderless>
                                            <tbody>
                                                <tr>
                                                    <td><b>{Brand+' :'}</b></td>
                                                    <td><b>{singleItem.tags}</b></td>
                                                </tr>
                                                <tr>
                                                    <td><b>{Availability+' :'}</b></td>
                                                    <td className="in-stock"><b>{singleItem.stock}</b></td>
                                                    <td className="out-of-stock" style={{ display: "none" }}><b>{OutOfStock}</b></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>}
                                    <hr />
                                    {editMode? ''
                                    : 
                                    <div className="product-price digits">
                                        <del>{symbol}{singleItem.discountPrice}
                                        </del>{symbol}{singleItem.price}
                                    </div>}
                                    <hr />
                                    {editMode? '':
                                        <div className="product-details  text-left product-size">
                                            <ul style={{display:'flex'}}>
                                                {ProductSizeArray.map((items,i) => 
                                                  <li key={i}>
                                                    <Button color="outline-light">{items}</Button>
                                                  </li>
                                                )}
                                            </ul>
                                        </div>}
                                    {editMode? 
                                        <div className="order-history table-responsive">
                                            <Table borderless>
                                              <thead>
                                                  <tr>
                                                    {OrderHistoryTable.map((table,i) =>
                                                    <th scope="col" key={i}>{table}</th>
                                                    )}
                                                    <th scope="col"><i className="fa fa-angle-down"></i></th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                <tr className="title-orders">
                                                  <td><Input className="" type="file" /></td>
                                                  <td>
                                                      <Input type="select" name="select" className="form-control digits"  multiple="">
                                                          {ProductSizeArray.map((items,i) => 
                                                              <option value={items}>{items}</option>
                                                          )}
                                                      </Input>
                                                  </td>
                                                  <td><Input className="form-control digits" style={{ height: '35px' }} type="color" defaultValue="#563d7c" /></td>
                                                  <td><Input className="form-control digits" type="number" defaultValue='0' /></td>
                                                  <td><Input className="form-control digits" type="number" defaultValue='0' /></td>
                                                  <td>
                                                    <InputGroup className="mb-3">
                                                      <InputGroupAddon addonType="prepend"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                      <Input className="" type="text" aria-label="Amount (to the nearest dollar)"/>
                                                    </InputGroup>
                                                  </td>
                                                  <td>
                                                    <InputGroup className="mb-3">
                                                        <Input className="" type="text" aria-label="Amount (to the nearest dollar)"/>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <Input type="select" name="select" className=""  multiple="">
                                                                    <option value='%'>{'%'}</option>
                                                                    <option value='$'>{'$'}</option>
                                                                </Input>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                  </td>
                                                  <td><MoreVertical/></td>
                                                </tr>
                                              </tbody>
                                            </Table>
                                            <div className="pull-right">
                                                <Button  color="outline-success" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                                    {Add +' '+ Product}
                                                </Button>
                                            </div>
                                        </div>
                                    : 
                                    <ul className="product-color m-t-15">
                                        <li className="bg-primary"></li>
                                        <li className="bg-secondary"></li>
                                        <li className="bg-success"></li>
                                        <li className="bg-info"></li>
                                        <li className="bg-warning"></li>
                                    </ul>}
                                    {editMode? 
                                    ''
                                    : 
                                    <div className="m-t-15">
                                        <Button  color="primary-gradien" className="m-r-10" onClick={() => addcart(singleItem, quantity)} >
                                            {AddToCart}
                                        </Button>
                                        <Button  color="success-gradien" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                            {BuyNow}
                                        </Button>
                                        <Link to={`${process.env.PUBLIC_URL}/ecommerce/product`}>
                                            <Button color="secondary-gradien">{ContinueShopping}</Button>
                                        </Link>
                                    </div>}
                                </Col>
                            </Row>
                        </Card>
                        <Tablet />
                    </Col>
                </Row>
            </Container>
            <hr/>
            <h3><b>{ProductsYouMayLike}</b></h3>
            <hr/>
            <ProductGrid/>
            <hr/>
            <h3><b>{TopRatedByClients}</b></h3>
            <hr/>
            <ProductGrid/>
        </Fragment>
    );
}

export default Productpage
