import 'aframe'
import 'aframe-look-at-component'
import 'aframe-particle-system-component'
import 'babel-polyfill'
import React, { Fragment, useCallback, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Entity, Scene} from 'aframe-react'


import VR_HotSpots from '../common/VR_HotSpots'
import VR_Ads from '../common/VR_Ads'
import VR_Objs from '../common/VR_Objs'
import VR_Streaming2D from '../common/VR_Streaming2D'
import VR_MediaGallery from '../common/VR_MediaGallery'
import VR_PerspectivesMenu from './Layer_VR_Components/VR_PerspectivesMenu'
import VR_TeamLineUp from '../common/VR_TeamLineUp'


const VR_View = (props) => {
  const event = useSelector(state => state.Common.currentEvent)
  const [LogedIn, logInUser] = useState(false)
  const [VRMode, setVRMode] = useState(false)
  const [isLoadingPerspercivesData, setLoadingPerspercivesData] = useState(true)
  const [locationID,setLocationID] = useState(1)
  const [location,setLocation] = useState([])
  const [perspectiveID, setPerspectiveID] = useState(0)
  const [perspective, setPerspective] = useState([])
  const [currentPerspectiveName, setPerspectiveName] = useState('')
  const [currentShow, setCurrentShow] = useState({})

  const [streaming2DVR, setStreaming2DVR] = useState({})
  const [activePerspectives , setActivePerspectives] = useState([])
  const [hotSpots,setHotSpots] = useState([])

  const [cameraRotation, setCamRotation] = useState("")
  const [cameraZoom , setCameraZoom] = useState(1)

  const LogInUser = () => {
    logInUser(true)
    leftSideTabs(false,{type:"LogIn"})
    leftSideTabs(true,{type:"venueLocations"})
    changePerspective({toLocationId:1,toPerspectiveId:0,type:undefined})
  }

  const zoomCamera = (type) => {
    if(type){
      if(cameraZoom < 5){
        setCameraZoom(cameraZoom+1)
      }else{
        setCameraZoom(1)
      }
    }else{
      setCameraZoom(1)
    }
  }

  useEffect(()=>{
    setPerspectiveData(locationID,perspectiveID).then(()=>{
      setLoadingPerspercivesData(false)
    }).catch(error => console.error(error))
  },[])

  const setPerspectiveData = (locationID,perspectiveID) =>{
    return new Promise ((resolve,reject) => {

      console.log("Setting new perspective",locationID,perspectiveID)

      setLocation(event.locations[locationID])
      console.log("Setting Location Data: ",event.locations[locationID])

      setPerspective(event.locations[locationID].perspectives[perspectiveID])
      console.log("Setting Perspective Data: ",event.locations[locationID].perspectives[perspectiveID])

      setHotSpots([])
      console.log("Cleaning HotSpots")

      
      var streaming2D = event.locations[locationID].perspectives[perspectiveID].streaming2D
      if(streaming2D != undefined){
        setStreaming2DVR(streaming2D)
        console.log("Setting Streaming 2D",streaming2D)
      }else{
        setStreaming2DVR({status:"off"})
      }

      var camRotation = event.locations[locationID].perspectives[perspectiveID].camRotation
      setCamRotation(`${camRotation.x} ${camRotation.y} ${camRotation.z}`)
      console.log("Setting CamRotation")

      event.locations[locationID].perspectives[perspectiveID].hotSpots.forEach(hotSpot =>{
        var perspective = event.locations[hotSpot.toLocationId].perspectives[hotSpot.toPerspectiveId]
        if(perspective.status == "live"){
          setHotSpots(hotSpots => [...hotSpots,hotSpot])
        }
      })

      setActivePerspectives(event.locations[0].perspectives.filter(perspective => perspective.status == "live"))
      setPerspectiveName(event.locations[locationID].perspectives[perspectiveID].name)
      event.locations[locationID].shows.map(show => {
        if(show.status == "live"){
          setCurrentShow(show)
          console.log("Setting current show",show)
        }
      })
      setTimeout(()=>resolve(true),2000)
    })
  }

  const [perspectiveToBuy , setPerspectiveToBuy] = useState({})
  const purchasePerspective = (perspectiveOBJ) =>{
    setPerspectiveToBuy(perspectiveOBJ)
    checkIfIsOpen({type:"UpgradePerspective"})
  }

  // END OF TESTING--------------------------------------------------------------
  useEffect(()=>{
    if(isLoadingPerspercivesData){console.log("LOADING VIDEO...")}else{
      document.getElementById('sceneVideo').play()
      document.getElementById('Streaming2D_VR').play()
      console.log("VIDEOS PLAYED")
    }
  },[isLoadingPerspercivesData])

  const [Video360,setVideo360] = useState({playing:true})
  const playingVideo = (playing) => {
    const mediaPlayer = document.getElementById('sceneVideo')
    const streaming2D = document.getElementById('Streaming2D_VR')

    console.log("Playing Video: ", playing)

    if(playing){
      mediaPlayer.play()
      streaming2D.play()
    }else{
      mediaPlayer.pause()
      streaming2D.pause()
    }
  }

  // Getting Hot Spot Profiles ---------------------------------------------------------
  const [isLoadingHotSpotProfiles, setLoadingHotSpotProfiles] = useState(true)
  const [mainHotSpotColor, setMainHotSpotColor] = useState("#EF2D5E")
  const [hoverMainHotSpotColor, setMainHoverHotSpotColor] = useState()
  const [premiumHotSpotColor, setPremiumHotSpotColor] = useState("#f6fc30")
  const [hoverPremiumHotSpotColor, setPremiumHoverHotSpotColor] = useState()
  const [adPlacementColor, setAdPlacementColor] = useState("#2ff546")
  const [adPlacementHoverColor, setAdPlacementHoverColor] = useState()


  const [ loaderOpacity , setLoaderOpacity ] = useState(0)
  const setHotSpotsProfiles = () => {
    return new Promise ((resolve,reject) => {
      event.settings.VR.hotSpots.profiles.map( profile => {
        switch(profile.name){
          case"hotSpots":
          setMainHotSpotColor(profile.mainColor)
          setMainHoverHotSpotColor(profile.hoverColor)
          break;
          case"premiumHotSpot":
          setPremiumHotSpotColor(profile.mainColor)
          setPremiumHoverHotSpotColor(profile.hoverColor)
          break;
          case"adPlacements":
          setAdPlacementColor(profile.mainColor)
          setAdPlacementHoverColor(profile.hoverColor)
          break;
        }

      })
      //setHoverHotSpotColor(event.locations[locationID].perspectives[perspectiveID])
      resolve(true)
    })
  }
  useEffect(()=>{
    setHotSpotsProfiles().then(setLoadingHotSpotProfiles(false)).catch(error => console.error(error))
  },[])

  const changeAlphaOfLoader = () => {
    var status = true
    var alpha = 0
    var alphaInterval = setInterval(function () {
      if(alpha <= 100 && status){
        if(alpha < 100){
          alpha = alpha+2
          setLoaderOpacity(alpha)
        }else{
          status = false
        }
      }else if( alpha >= 0){
        if (alpha > 0) {
          setLoaderOpacity(alpha--)
        }else{
          status = true
          clearInterval(alphaInterval)
        }
      }
    }, 10);
  }

  // HOTSPOTS DATA --------------------------------------------------------------------
  const changePerspective = useCallback((hotSpotTo) => {
    setLoadingPerspercivesData(true)
    changeAlphaOfLoader()
    console.log('Change to: ',hotSpotTo)
    setPerspectiveData(hotSpotTo.toLocationId,hotSpotTo.toPerspectiveId).then(()=>{
      setLoadingPerspercivesData(false)
    }).catch(error => console.error(error))  
  })

  const [hotSpotToData,setHotSpotToData] = useState({})
  const hotSpotHoverEnter = useCallback((hotSpotTo) => {
    //setHotSpotColor(hoverHotSpotColor)
    console.log('HotSpot To: ',hotSpotTo)
    console.log('Location: ', event.locations[hotSpotTo.toLocationId].name, event.locations[hotSpotTo.toLocationId].perspectives)
    console.log('Perspective: ', event.locations[hotSpotTo.toLocationId].perspectives[hotSpotTo.toPerspectiveId].name, event.locations[hotSpotTo.toLocationId].perspectives[hotSpotTo.toPerspectiveId])
    setHotSpotToData({location:event.locations[hotSpotTo.toLocationId].name,perspective:event.locations[hotSpotTo.toLocationId].perspectives[hotSpotTo.toPerspectiveId].name})
    console.log('-----------------------------------------------------------------------------------------------------')
  })

  // AD PLACEMENTS DATA ----------------------------------------------
  const [currentAd, setCurrentAd ] = useState(null)
  const sponsors = useSelector(state => state.Common.currentEvent.sponsors)
  const AdClick = (ads) => {
    checkForTheBestAd(ads).then( ad => {
      setCurrentAd(ad)
      switch(ad.type){
        case "ad_hotSpot":
          changePerspective({toLocationId:ad.toLocationId,toPerspectiveId:ad.toPerspectiveId})
        break;
        default:
          leftSideTabs(true,{type:ad.type})
        break;
      }
      console.log("Displaying ad: ", ad)
    }).catch(error => console.error(error))
  }

   // CHECK FOR THE BEST ADS TO DISPLAY ( COMMING SOON )
  const checkForTheBestAd = (adsData) => {
    return new Promise ((resolve,reject) => {
      var bestAd = undefined
      var i = 0
      adsData.forEach(data =>{
        sponsors.map(sponsor => {
          if(sponsor.id == data.sponsorID){
            sponsor.adPlacements.map(adPlcement => {
              if(adPlcement.id == data.adPlacementID){
                // give a score to the ad
                adPlcement.score = 100
                i ++
                if(bestAd == undefined || bestAd.score < adPlcement.score){ bestAd = adPlcement }
                if(adsData.length === i){resolve(adPlcement)}
              }
            })
          }
        })
      })
    })
  }

//LAYER 2D ------------------------------------------------------------------------------------
  const [openLeftSideTabs, setOpenLeftSideTabs] = useState([{type:"LogIn"}])

  const tabInfo = (type) =>{
    var tabIndex = openLeftSideTabs.findIndex((tab)=> tab.type === type)
    var tabData = tabIndex > -1 ? openLeftSideTabs[tabIndex].data : undefined
    return({
        index: tabIndex,
        data:  tabData
    })
  }

  const checkIfIsOpen = (tab) => {
    setOpenLeftSideTabs([])
    if(tabInfo(tab.type).index > -1 ){
      leftSideTabs(false,tab)
    }else{
      leftSideTabs(true,tab)
    }
  }

  const leftSideTabs = (type,tab) => {

    // type == true is for adding , type == false for closing
    if(type == undefined || tab.type == undefined){
      return console.log("leftSideTabs function require a type and a tabName", type, tab)
    }

    if(type){
      if(tabInfo(tab.type).index === -1 && openLeftSideTabs.length < 2){
        console.log("Adding ", type,tab)
        setOpenLeftSideTabs(leftTabs => [...leftTabs,tab])
      }else if(openLeftSideTabs.length == 2){
        console.log("There are more than 2 windows on screen",openLeftSideTabs)
      }else if(tabInfo(tab.type).index > -1){
        deleteLeftSideTab(type,tab)
      }
    }else{
      deleteLeftSideTab(type,tab)
    }

    function deleteLeftSideTab (type,tab){
      console.log("DELETING ", type,tab)
      setOpenLeftSideTabs(openLeftSideTabs.filter(tabS => tabS.type !== tab.type));
    }

  }
//LAYER 2D ------------------------------------------------------------------------------------




// VR MODE -----------------------------------------------------------------------------------
  const [vrMode , setVrMode] = useState(false)
  // const [vrCursor , setVRCursor] = useState({
  //   material:"color: #ffffff; opacity: 0",
  //   cursor:"rayOrigin: mouse",
  //   geometry:"primitive: sphere; radius: 0",
  //   animationClick:"",
  //   animationFusing:"",
  //   animationMouseleave:"",
  //   position:""
  // })
  useEffect(()=>{
    const evntScene = document.getElementById("Evnt_Scene")

    if(evntScene != null){
      if(vrMode){
        //evntScene.enterVR()
        // setVRCursor({
        //   material:"color: #0011ff;",
        //   animationClick:"property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 1 1 1; to: 3 3 3",
        //   animationFusing:"property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 3 3 3; to: 1 1 1",
        //   animation__mouseleave:"property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 3 3 3",
        //   cursor:"fuse: true;",
        //   position:"0 0 -3",
        //   geometry:"primitive: ring"
        // })
      
      }else{
        evntScene.exitVR()
        // setVRCursor({
        //   material:"color: #ffffff; opacity: 0",
        //   cursor:"rayOrigin: mouse",
        //   geometry:"primitive: sphere; radius: 0",
        //   animationClick:"",
        //   animationFusing:"",
        //   animationMouseleave:"",
        //   position:""
        // })
      }

      console.log("Evnt_Scene is VR ",evntScene.is('vr-mode'))
    }else{
      console.log("Evnt_Scene is ",evntScene)
    }
  },[vrMode])


  // VR LINEUP ---------------------------------------------------------
  const [VRLineUp , setVRLineUp] = useState(false)
  const [eventCasterID, setSportCaster] = useState(2)
  const [tourInstructions, setTourInstructions] = useState(true)

  if (isLoadingPerspercivesData && isLoadingHotSpotProfiles) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Fragment>

      {/* {LogedIn ? <IntsructionsTour tourInstructions={tourInstructions} setTourInstructions={(bool)=>setTourInstructions(bool)} /> : "" } */}

      {/* {isLoadingPerspercivesData?
      <div style={{zIndex:8, position:"fixed", display:"flex", flexDirection:"row", alignItems:"center", width:"100%", height:"100%", pointerEvents:"none"}} >
        <Media style={{opacity:`${loaderOpacity}%`,width:"100%",height:"100%",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}} src={require('../../../assets/images/loadingIMG.jpg')}/>
      </div>:""} */}

      {/* {LogedIn ?
        <Header
        currentShow={currentShow}
        eventTypes={props.eventTypes}
        eventCasterID={eventCasterID}
        perspectiveData={perspective}
        perspectiveName = {currentPerspectiveName}
        checkIfIsOpen={(type)=> checkIfIsOpen(type)}/>:"" }

      <Layer_2D
        VRLineUp={VRLineUp}
        currentAd={currentAd}
        currentShow={currentShow}
        perspective={perspective}
        perspectiveToBuy={perspectiveToBuy}
        activePerspectives={activePerspectives}
        
        logInUser = {()=>LogInUser()}
        tabInfo = {(type)=>tabInfo(type)}
        setVRLineUp={(type)=> setVRLineUp(type)}
        setVrMode = {(status)=>setVrMode(status)}
        checkIfIsOpen={(type)=> checkIfIsOpen(type)}
        setSportCaster={(casterID)=>setSportCaster(casterID)}
        setTourInstructions={(bool)=>setTourInstructions(bool)}
        changePerspective={(hotSpotObj)=>changePerspective(hotSpotObj)}/> */}

      <Scene id="Evnt_Scene" vr-mode-ui={{enabled: true}} loading-screen="dotsColor: purple; backgroundColor: black">
        {/* <Entity primitive="a-plane" src="#groundTexture" rotation="-90 0 0" height="100" width="100"/> */}
        {/* <Entity primitive="a-light" type="ambient" color="#445451"/> */}
        {/* <Entity primitive="a-light" type="point" intensity="2" position="2 4 4"/> */}

        <a-assets>
          <video
    	      id="sceneVideo"
            // src={'https://bitmovin-a.akamaihd.net/content/playhouse-vr/m3u8s/105560.m3u8'}
		        // src={require(`D:/EVNT/Contenido/Stadium/Ready/${perspective.streaming.link}`)}
            src={require(`../../../assets/video/${perspective.streaming.link}`)}
            loop={true}
            autostart
            crossOrigin={"anonymous"}
	        ></video>

          <video
    	      id="Streaming2D_VR"
            //src={require(`../../../assets/video/${currentShow.Streaming2D.src}`)}
            src={require(`../../../assets/video/Stadium/america-tijuana.mp4`)}
            loop={true}
            autostart
            crossOrigin={"anonymous"}
	        ></video>

        </a-assets>

        <a-camera id="camera" look-controls="reverseMouseDrag: true, reverseTouchDrag: true" zoom={cameraZoom}>
		      <Entity 
            id="cursorVR"
            primitive="a-cursor"
            material="color: #0011ff; opacity: 1"
            animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 1 1 1; to: 3 3 3"
            animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 3 3 3; to: 1 1 1"
            animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 3 3 3"
            cursor="fuse: true;"
            position="0 0 -3"
            geometry="primitive: ring"
            />
        </a-camera>

        <a-videosphere src={'#sceneVideo'} rotation={cameraRotation}></a-videosphere>
        

            {isLoadingPerspercivesData == false && streaming2DVR != undefined && streaming2DVR.status == "live" ?
            <VR_Streaming2D streaming2D={streaming2DVR} changePerspective={(hotSpotObj) => changePerspective(hotSpotObj)}  /> : console.log("Streaming 2D VR is not available in this perspective") }

            {VRLineUp ? 
            <VR_TeamLineUp VRLineUp={VRLineUp} setVRLineUp={(type)=> setVRLineUp(type)} checkIfIsOpen={(type)=> checkIfIsOpen(type)} /> : ""}

            <VR_Objs objs3d={perspective.objs3d} AdClick={(ads) => AdClick(ads)}/>
            <VR_HotSpots 
              hotSpots={hotSpots} 
              mainHotSpotColor={mainHotSpotColor}
              premiumHotSpotColor={premiumHotSpotColor} 
              checkIfIsOpen={(type)=> checkIfIsOpen(type)}
              changePerspective={(hotSpotObj) => changePerspective(hotSpotObj)} 
              hotSpotHoverEnter={(hotSpotObj) => hotSpotHoverEnter(hotSpotObj)}
              purchasePerspective={(perspectiveOBJ)=>purchasePerspective(perspectiveOBJ)}/>
            <VR_Ads 
              adPlacementColor={adPlacementColor}
              adPlacements={perspective.adPlacements}  
              AdClick={(ads) => AdClick(ads)}/>
            
            {perspective.VRMenu != undefined ?
            <VR_MediaGallery VRMenu={perspective.VRMenu} changePerspective={(hotSpotObj) => changePerspective(hotSpotObj)}/>: "" }



            {perspective.vrPrespectivesMenu != undefined ?
            <VR_PerspectivesMenu vrPrespectivesMenu={perspective.vrPrespectivesMenu} 
              activePerspectives={activePerspectives} changePerspective={(hotSpotObj) => changePerspective(hotSpotObj)}/>: "" }


            <Entity id="NadirLogo" primitive="a-image"
            src={require(`../../../assets/images/nadirlogo.png`)}
            width="3" height="2" position="-0.04 -1 0.311" rotation="100 0 0" scale="0.7 1 0"
              events={{ click: () => changePerspective({toLocationId:1,toPerspectiveId:0,type:undefined})}}
            />

      </Scene>

      {/* {LogedIn ?
      < BottomBar_VR
        hotSpotToData={hotSpotToData}
        Video360={Video360}
        currentShow={currentShow}
        perspective={perspective}
        cameraZoom={cameraZoom}
        zoomCamera={(type)=>zoomCamera(type)}
        playingVideo={(playing)=>playingVideo(playing)}
        checkIfIsOpen={(type)=> checkIfIsOpen(type)}
        changePerspective={(hotSpotObj)=>changePerspective(hotSpotObj)}/>
        :""} */}
    </Fragment>
  );
}
export default VR_View;
