import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Media} from 'reactstrap'
import { LogIn_Card, LogInCatalog_Card } from './Layer_2D_Components/LogIn_page';


import VenueLocations from './Layer_2D_Components/VenueLocations'
import ChatTab from './Layer_2D_Components/ChatTab'
import UsersList360 from './Layer_2D_Components/UsersList360'
import Notifications360 from './Layer_2D_Components/Notifications360'
import Settings360 from './Layer_2D_Components/Settings360'
import RemoteControl from './Layer_2D_Components/RemoteControl';
import UserStories from './Layer_2D_Components/UserStories';
import PerspectivesUpgrade from './Layer_2D_Components/PerspectivesUpgrade';
import TeamStatistics from './Layer_2D_Components/TeamStatistics';
import VideoCall from './Layer_2D_Components/VideoCall';
import SocialSharing from './Layer_2D_Components/SocialSharing'
import CloseWindow from './Layer_2D_Components/2DAssets/CloseWindow';
import AdMediaSrc from './Layer_2D_Components/AdMediaSrc';
import AudioSettings from './Layer_2D_Components/AudioSettings';
import TwitterFeed from './Layer_2D_Components/TwitterFeed'
import UserProfileCard from './Layer_2D_Components/UserProfileCard';
import PaymentCard from './Layer_2D_Components/PaymentCard';
import MoreMobile from './Layer_2D_Components/MoreMobile';

export const Layer_2D = (props) => {

    const event = useSelector(state => state.Common.currentEvent)
    const currentAd = props.currentAd

    const notifications = [
        {
            fromID:0,
            dateFrom:"08/10/2021",
            type:"comment",
            text:"Te mando una solicitud de amistad."
        },
        {
            fromID:4,
            dateFrom:"08/10/2021",
            type:"comment",
            text:"Ha publicado una historia."
        },
        {
            fromID:5,
            dateFrom:"08/10/2021",
            type:"comment",
            text:"Tienes una videollamada perdida"
        },
        {
            fromID:0,
            dateFrom:"08/10/2021",
            type:"comment",
            text:"Le dio like a tu historia"
        }
    ]

    return(
        <Fragment>
        {props.tabInfo("LogIn").index > -1 ?
        
            <div className={"layer-2d-log-in"}>
            
                {/* LOG IN WINDOW ----------------------------------------------- */}
                {props.tabInfo("LogIn").index > -1 ?
                   <LogIn_Card logInUser={() => props.logInUser()} windowWidth={props.windowWidth}/>: ""
                }
    
                {props.tabInfo("LogIn").index > -1 ?
                   <LogInCatalog_Card />: ""
                }
    
            </div> :
            <div className={"layer-2d"}>
                {props.tabInfo("ad_MediaSrc").index > -1 ?
                    <AdMediaSrc currentAd={currentAd} checkIfIsOpen={props.checkIfIsOpen} windowName={"ad_MediaSrc"} changePerspective={props.changePerspective}/>: ""
                }
                
                {props.tabInfo("calienteCasino").index > -1 ?
                   <Card className={"evnt-card"}>
                        <div onClick={()=> props.checkIfIsOpen({type:"calienteCasino"})}><CloseWindow/></div>
                        <CardBody style={{color:"white"}}>
                            <iframe width="100%" height="380" src={"https://www.caliente.mx/"} title="Casino Caliente"></iframe>  
                            {/* <img width="435" height="400" src={"https://azteca.evnt.cloud/rappi.JPG"} title="3D Experience"></img>   */}
                        </CardBody>
                    </Card>: ""
                }
    
                {props.tabInfo("nikeStore").index > -1 ?
                   <Card className={"evnt-card"}>
                        <div onClick={()=> props.checkIfIsOpen({type:"nikeStore"})}><CloseWindow/></div>
                        <CardBody style={{color:"white"}}>
                            <iframe width="100%" height="380" src={"https://www.sneakerstore.com.mx/productos/sneakers/hombre"} title="3D Experience"></iframe>  
                            <br/>
                            <Button onClick={()=> {
                                    props.checkIfIsOpen({type:"nikeStore"})
                                    props.changePerspective({toLocationId:2,toPerspectiveId:0,type:undefined})
                                }} style={{marginTop:-15,width:"100%"}} color="primary" outline>{"VR VIEW"}</Button> 
                        </CardBody>
                    </Card>: ""
                }
    
                {/* DIALOG WINDOWS ----------------------------------------------- */}
            
                {props.tabInfo("Settings360").index > -1 ?
                   <Settings360 
                   windowName={"Settings360"}
                   windowWidth={props.windowWidth}
                   setVrMode = {props.setVrMode}
                   checkIfIsOpen={props.checkIfIsOpen} 
                   changePerspective={props.changePerspective} 
                   setTourInstructions={props.setTourInstructions}/>: ""
                }

                {props.tabInfo("MoreMobile").index > -1 ?
                   <MoreMobile 
                   windowName={"MoreMobile"}
                   windowWidth={props.windowWidth}
                   setVrMode = {props.setVrMode}
                   checkIfIsOpen={props.checkIfIsOpen} 
                   changePerspective={props.changePerspective} 
                   setTourInstructions={props.setTourInstructions}/>: ""
                }
    
                {props.tabInfo("RemoteControl").index > -1 ?
                   <RemoteControl checkIfIsOpen={props.checkIfIsOpen} windowName={"RemoteControl"}/>: ""
                }
                
                {props.tabInfo("AudioSettings").index > -1 ?
                   <AudioSettings 
                    checkIfIsOpen={props.checkIfIsOpen} 
                    windowName={"AudioSettings"} 
                    windowWidth={props.windowWidth}
                    setSportCaster={props.setSportCaster}/>: ""
                }
    
                {props.tabInfo("UpgradePerspective").index > -1 ?
                   <PerspectivesUpgrade checkIfIsOpen={props.checkIfIsOpen} windowName={"UpgradePerspective"}
                   windowWidth={props.windowWidth}
                   perspectiveToBuy={props.tabInfo("UpgradePerspective").data}/>: ""
                }
                
                {props.tabInfo("socialSharing").index > -1 ?
                   <SocialSharing checkIfIsOpen={props.checkIfIsOpen} windowName={"socialSharing"}/>: ""
                }
    
                {props.tabInfo("venueLocations").index > -1 ?
                    <VenueLocations checkIfIsOpen={props.checkIfIsOpen} windowName={"venueLocations"} 
                    activePerspectives={props.activePerspectives} changePerspective={props.changePerspective}
                    windowWidth={props.windowWidth}/>: ""
                }
    
                {props.tabInfo("paymentCard").index > -1 ?
                    <PaymentCard 
                        checkIfIsOpen={props.checkIfIsOpen} 
                        windowWidth={props.windowWidth}
                        windowName={"paymentCard"} 
                        changePerspective={props.changePerspective} 
                        windowData={props.tabInfo("paymentCard").data}/>: ""
                }
    
                {props.tabInfo("teamStatistics").index > -1 ?
                    <TeamStatistics 
                        checkIfIsOpen={props.checkIfIsOpen} 
                        windowWidth={props.windowWidth}
                        changePerspective={props.changePerspective} 
                        windowName={"teamStatistics"} 
                        VRLineUp={props.VRLineUp} 
                        setVRLineUp={props.setVRLineUp}/>: ""
                }
                {props.tabInfo("Notifications360").index > -1 ?
                    <Notifications360  checkIfIsOpen={props.checkIfIsOpen} windowName={"Notifications360"} commentsData={notifications}/>: ""
                }
                {props.tabInfo("ChatTab").index > -1 ?
                    <ChatTab checkIfIsOpen={props.checkIfIsOpen} windowName={"ChatTab"} commentsData={event.locations[0].shows[0].comments} chatDetails={props.tabInfo("ChatTab").data} />: ""
                }
                {props.tabInfo("UsersList360").index > -1 ?
                    <UsersList360 checkIfIsOpen={props.checkIfIsOpen} windowName={"UsersList360"} commentsData={event.locations[0].shows[0].comments} usersList={event.locations[0].shows[0].attendance}/>: ""
                }
                {props.tabInfo("talent_preview").index > -1 ?
                    <Card style={{margin:"50px 30px", pointerEvents:"all"}}>
                        <div onClick={()=> props.checkIfIsOpen({type:"talent_preview"})}><CloseWindow/></div>
                        <iframe width="435" height="250" src="https://www.youtube.com/embed/536d3KAjmr0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
                    </Card>: ""
                }
                {props.tabInfo("user_story").index > -1 ?
                    <UserStories checkIfIsOpen={props.checkIfIsOpen} windowName={"user_story"}/>: ""
                }
                {props.tabInfo("videoCall").index > -1 ?
                    <VideoCall checkIfIsOpen={props.checkIfIsOpen} windowName={"videoCall"}/>: ""
                }
                {props.tabInfo("UserProfileCard").index > -1 ?
                    <UserProfileCard checkIfIsOpen={props.checkIfIsOpen} windowName={"UserProfileCard"} windowData={props.tabInfo("UserProfileCard").data} />: ""
                } 
    
                {/* <TwitterFeed/> */}
            
            </div>
        }
        </Fragment>
    )
}

export default Layer_2D