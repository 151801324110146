import React, { Fragment, useState } from 'react'
import {Container} from "reactstrap"
import { Eventt,Venue,VenueLocations } from "../../../../../constant";
import LocationsGrid from "../Shared/LocationsGrid"
import BreadCrumb from '../../../../../layout/Breadcrumb'
import {CarouselLocations2D} from '../../../../advance/carousel/CarouselComponent'

const Locations_2D = (props) => {

    const [locations , setLocations] = useState(props.locationsData)
    const [generalLocations , setGeneralLocations] = useState(props.generalLocationsData)
    return (
        <Fragment>
            <BreadCrumb  subparent={Venue} title={VenueLocations} addBtn={true} statisticsBTN={true}/>
            <Container fluid={true}>
                <CarouselLocations2D locationsData={locations}/>
                <hr/>
                <LocationsGrid locationsData={generalLocations}/>
                <br/>
            </Container>
        </Fragment>
    );
};

export default Locations_2D;