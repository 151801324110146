import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, CardBody, Media, Table, Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Badge, Dropdown, DropdownMenu, DropdownItem, Input} from 'reactstrap'
import ScrollArea from 'react-scrollbar'
import { newuser } from '../../../dashboard/data'
import CloseWindow from './2DAssets/CloseWindow'
import TeamStatistics_table from './2DAssets/TeamStatistics_table'
import TeamStatistics_graph from './2DAssets/TeamStatistics_graph'
import { Data, League, Tables, Team, TeamStatistics } from '../../../../constant'
import UsersList from '../../../users/UsersList'

export const TeamStatisticsCard = (props) => {
    
    const [IconWithTab, setIconWithTab] = useState('1');

    const LeageTable = [
      {
        team:"America",
        img:"avtar/Teams/club-america.png",
        p:"16",
        goals:"21:10",
        gd:"11",
        pts:"34"
      },
      {
        team:"Tijuana",
        img:"avtar/Teams/Club_Tijuana.png",
        p:"16",
        goals:"21:10",
        gd:"11",
        pts:"34"
      },
      {
        team:"Puebla",
        img:"avtar/Teams/Club-puebla.png",
        p:"16",
        goals:"21:10",
        gd:"11",
        pts:"34"
      },
      {
        team:"Atlas",
        img:"avtar/Teams/Fútbol_Club_Atlas.png",
        p:"16",
        goals:"21:10",
        gd:"11",
        pts:"34"
      },
      {
        team:"Necaxa",
        img:"avtar/Teams/necaxa.png",
        p:"16",
        goals:"21:10",
        gd:"11",
        pts:"34"
      }
      // {
      //   team:"Santos",
      //   img:"",
      //   p:"16",
      //   goals:"21:10",
      //   gd:"11",
      //   pts:"34"
      // },
      // {
      //   team:"Tigres",
      //   img:"",
      //   p:"16",
      //   goals:"21:10",
      //   gd:"11",
      //   pts:"34"
      // }
    ]

    
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const windowWidth = props.windowWidth
  const [navButtonsAlign, setNavButtonsAlign] = useState(null)
  const [windowType, setWindowType] = useState(null)
  const [listHeight, setListHeight] = useState("200px")
  const [listHeight2, setListHeight2] = useState("200px")
  useEffect(()=>{
    if(windowWidth<= 1000 && windowWidth >= 425){
      setListHeight("100px")
      setListHeight2("150px")
    }else{
      setListHeight("200px")
      setListHeight2("300px")
    }

    if(windowWidth >= 425){
      setWindowType("evnt-card-lg")
      setNavButtonsAlign("nav flex-column nav-pills")
    }else{
      setWindowType("evnt-card-lg")
      setNavButtonsAlign("")
    }
  },[windowWidth])

    return(
         <Fragment>
             <Card className={`evnt-card ${windowType}`}>
             <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody style={{color:"white"}}> 
                  <div className="team-statistics-card" style={{color:"white"}}>
                    <div>
                      { windowWidth >= 425 ? "" :
                        <h2 className="evnt-card-title"><b>{TeamStatistics}</b></h2> 
                      }
                      <Input type="select" name="select" className="form-control btn-pill digits m-b-10" id="exampleFormControlSelect7">
                        <option>{"AMERICA"}</option>
                        <option>{"CHOLOS"}</option>
                      </Input>
                      <Nav tabs className={`evnt-statistics-menu ${navButtonsAlign}`}>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}>{Team}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}>{League}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '3' ? 'active' : ''} onClick={() => setIconWithTab('3')}>{Data}</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{cursor:"pointer"}} className={IconWithTab === '4' ? 'active' : ''}
                            onClick={()=>{
                              props.setVRLineUp(!props.VRLineUp)
                              props.changePerspective({toLocationId:0,toPerspectiveId:7,type:undefined})
                              props.checkIfIsOpen({type:props.windowName})
                            }}>{"LineUp"}</NavLink>
                        </NavItem>
                      </Nav>
                      <br/>
                    </div>
                    <div className="evnt-statistics-data">
                      { windowWidth <= 425 ? "" :
                        <h2 className="evnt-card-title"><b>{TeamStatistics}</b></h2> 
                      }
                      <TabContent activeTab={IconWithTab}>
                        <TabPane  className="fade show" tabId="1">
                          <div onClick={()=> props.checkIfIsOpen({type:"UserProfileCard",data:{userID:7}})} >
                            <UsersList  listType={"users"} usersList={[7,8,9,10,11,12,13,14,15,16,17]} height={listHeight}/>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className="table-responsive">
                            <ScrollArea
                              className="evnt-statistics-table"
                              speed={0.5}
                              horizontal={true}
                              vertical={true}
                              style={{height:listHeight2}}>  
                              <Table>
                                <thead>
                                  <tr>
                                    <th scope="col">{"#"}</th>
                                    <th scope="col">{"Team"}</th>
                                    <th scope="col">{"P"}</th>
                                    <th scope="col">{"Goals"}</th>
                                    <th scope="col">{"GD"}</th>
                                    <th scope="col">{"Pts."}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    LeageTable.map((data,i)=>{
                                      return(
                                      <tr>
                                        <td>{i+1}</td>
                                        <td><div><img src={require(`../../../../assets/images/${data.img}`)} width="20px"></img>&nbsp;<b>{data.team}</b></div></td>
                                        <td>{data.p}</td>
                                        <td>{data.goals}</td>
                                        <td>{data.gd}</td>
                                        <td>{data.pts}</td>
                                      </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </Table>
                            </ScrollArea>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <ScrollArea
                            className="evnt-statistics-table"
                            speed={0.5}
                            horizontal={true}
                            vertical={true}
                            style={{height:listHeight2}}>  
                            <TeamStatistics_table />
                          </ScrollArea>
                        </TabPane>
                      </TabContent> 
                    </div>
                  </div>
                </CardBody>
             </Card>
         </Fragment>
    )
}

export default TeamStatisticsCard