import React,{useState, useEffect, Fragment} from 'react';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import {Set2DBackGround} from '../../../../redux/common/actions'

import Show_1D_View from './1D/Show_1D_View'
import ShowForm from './EditView/ShowForm'
import Show_VR_View from '../../../VR/VR_View/VR_View'

const ShowView = (props) => {

  const dispatch = useDispatch() 
  const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
  const mainType = useSelector(state => state.Common.mainType)
  const shows = useSelector(state => state.Common.currentEvent.locations[2].shows)
  const perspectives = useSelector(state => state.Common.currentEvent.locations[2].perspectives)
  const [show , setShow] = useState(shows[1])
  const [upcommingShows , setUpCommingShows] = useState(shows)

  useEffect(() => {
    console.log("Setting 2D BackGround ---------------------------------------")
    platformtypes.map(view =>{
      if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.home))}
    }) 
  },[])

  switch("VR"){
    case"Admin":
      return (
        <Fragment>
          <ShowForm showData={show}/>
        </Fragment>
      )
    break;
    case"1D": 
      return (
        <Fragment>
          <Show_1D_View showData={show} upcommingShowsData={upcommingShows} />
        </Fragment>
      );
    break;
    case"2D": 
      return (
        <Fragment>
          {'2D VIEW'}
        </Fragment>
      );
    break;
    case"3D": 
      return (
        <Fragment>
          {'3D VIEW'}
        </Fragment>
      );
    break;
    case"VR": 
      return (
        <Fragment>
          <Show_VR_View perspectivesData={perspectives}/>
        </Fragment>
      );
    break;
  }
};

export default ShowView;