import 'aframe'
import 'aframe-look-at-component'
import {Entity, Scene} from 'aframe-react'
import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const VR_HotSpots = (props) => {
  const event = useSelector(state => state.Common.currentEvent)

  const hotSpotHover = (enter,hotspot) => {
    const hotSpotName = hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name
    if(enter){
    document.getElementById(`${hotSpotName}`).setAttribute('material', {color: "#0703fc", opacity: 1})
    }else{
      var hotSpotColor = event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" ? props.premiumHotSpotColor : props.mainHotSpotColor
      document.getElementById(`${hotSpotName}`).setAttribute('material', {color: `${hotSpotColor}`, opacity: 0.8})
    }
  }

  // const [animationIndex , setAnimationIndex] = useState(1)
  // useEffect(()=>{
  //   setInterval(()=>{
  //     if(animationIndex > 1){
  //       setAnimationIndex(1)
  //     }else{
  //       setAnimationIndex(1.2)
  //     }
  //   },1000)
  // })

  // const hotSpotHover = (enter,hotspot) => {
  //   const hotSpotName = hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name
  //   if(enter){
  //     console.log("enter",enter,hotspot)
  //     document.getElementById(`${hotSpotName}`).src = require("../../../assets/images/banner/hotSpot_hover.png")
  //   }else{
  //     console.log("leave",enter,hotspot)
  //     document.getElementById(`${hotSpotName}`).src = require("../../../assets/images/banner/hotSpot_premium.png")
  //   }
  //   console.log(document.getElementById(`${hotSpotName}`).src)
  // }

  const hotSpotSRC = (type) => {
    if(!props.VRMode){
      type = false
    }
    switch(type){
      case "premium":
        return require("../../../assets/images/banner/hotSpot_premium_hover.png")
      default:
        return require("../../../assets/images/banner/hotSpot_hover.png")
    }
  }

  return (
    <Fragment>
        { props.hotSpots != undefined && props.hotSpots.length > 0 ?
            props.hotSpots.map((hotspot,i)=>{
              return(
              <Fragment>
                <Entity 
                look-at="#camera360"
                id={hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name} primitive="a-image" 
                src={hotSpotSRC(event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type)} 
                position={{x: hotspot.position.x, y: hotspot.position.y, z:hotspot.position.z}}
                scale={`3 3 3`}
                events={{ 
                  click: () => { 
                    {event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" && props.VRMode ? 
                      props.checkIfIsOpen({type:"UpgradePerspective",data:{
                        name:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name,
                        previewType:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.type,
                        src:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.src,
                        video:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.video,
                        toLocationId:hotspot.toLocationId,
                        toPerspectiveId:hotspot.toPerspectiveId
                      }
                      }):
                      props.changePerspective({
                        toLocationId:hotspot.toLocationId,
                        toPerspectiveId:hotspot.toPerspectiveId,
                        type:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type
                      })
                    }
                  }
                }}
                />
                {/* <Entity 
                  id={hotspot.id+"-"+event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name}
                  geometry={{primitive: 'sphere'}} 
                  position={{x: hotspot.position.x, y: hotspot.position.y, z:hotspot.position.z}}
                  // scale={{x: animationIndex, y: animationIndex, z:animationIndex}}
                  material={event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" ? 
                  {color: props.premiumHotSpotColor,opacity: 0.8}:{color: props.mainHotSpotColor,opacity: 0.8}}
                  events={
                    { click: () => 
                        {event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type != undefined && event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type == "premium" ? 
                          props.checkIfIsOpen({type:"UpgradePerspective",data:{
                              name:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].name,
                              previewType:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.type,
                              src:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].preview.src,
                              toLocationId:hotspot.toLocationId,
                              toPerspectiveId:hotspot.toPerspectiveId
                            }
                          }):
                          props.changePerspective({
                            toLocationId:hotspot.toLocationId,
                            toPerspectiveId:hotspot.toPerspectiveId,
                            type:event.locations[hotspot.toLocationId].perspectives[hotspot.toPerspectiveId].type
                          })
                        }, 
                      mouseenter: () => hotSpotHover(true,hotspot),
                      mouseleave: () => hotSpotHover(false,hotspot)
                    }
                  }/> */}
              </Fragment>
              )
            }): console.log("This perspective does not have hot spots")
            // props.hotSpotHoverEnter({id:hotspot.id,toLocationId:hotspot.toLocationId,toPerspectiveId:hotspot.toPerspectiveId})
        }
    </Fragment>  
  );
}
export default VR_HotSpots;
