import React from 'react'
import { Card, CardBody, Media, Badge } from 'reactstrap'
import { Fragment } from 'react'
import EventTimeLine from '../../../calendar/EventTimeLine'
import ScrollArea from 'react-scrollbar'
import { Live } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow'

export const VideoCall = (props) => {
    return(
        <Fragment>
            <Card style={{margin:"0px 30px", height:"400px", pointerEvents:"all"}}>
                <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody>
                    <ScrollArea
                        speed={0.5}
                        horizontal={false}
                        vertical={true}
                        style={{maxHeight:"350px"}}
                        >  
                        <br/>
                        <iframe width="435" height="250" src={require("../../../../assets/video/person01.mp4")} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                        <br/>  
                        <iframe width="435" height="250" src={require("../../../../assets/video/person02.mp4")} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                        <br/> 
                        <iframe width="435" height="250" src={require("../../../../assets/video/person03.mp4")} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                        <br/> 
                        <iframe width="435" height="250" src={require("../../../../assets/video/person04.mp4")} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                        <br/> 
                        <iframe width="435" height="250" src={require("../../../../assets/video/person05.mp4")} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                        <br/> 
                    </ScrollArea>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default VideoCall