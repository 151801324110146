import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import {Table,Row,Col,Label, Media, Badge, Input,InputGroup,InputGroupAddon,Button} from 'reactstrap'
import {Link}  from 'react-router-dom'
import {UserThumbnail} from '../../../../../general/UserThumbnail'
import {Actions, Delete, DonwloadCSVTemplate, UserImage, UserName, Username,
  UserPermissions, User, ChooseAdminCSV, AdministratorsBulkUpload, SponsorUserName,
  AddSponsorIndividually, SponsorsBulkUpload, ChooseTalentsCSV,
  SendRegistrationReminder, SearchSponsorUserName, Search, SponsorsList, Sponsor, 
  SponsorName, SponsorContact, AddSponsor, SearchSponsorWithUserName, 
  ChooseSponsorCSV} from "../../../../../../constant"

const SponsorsTable = (props) =>  {

  const [sponsorsID , setSponsorID] = useState(props.sponsorList)  

  axios.defaults.baseURL = `http://localhost:4000`
  const [sponsors , setTalent] = useState([])
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    sponsorsID.forEach(sponsorID => {
      axios.get(`/users/${sponsorID}`)
      .then(res => {
        setSponsorID(sponsorUser => [...sponsorUser,res.data])
        console.log('Setting Sponsors: ',res.data)
      }).catch(err => console.error("10",err))
    })
    setLoading(false)
  },[sponsors])
    
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Fragment>
      <h1>{SponsorsList}</h1>
      <hr/>
        <Row>
            <Col lg="4">
              <Label><b>{AddSponsorIndividually}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={SponsorUserName} aria-label={SponsorUserName}/>
                <InputGroupAddon addonType="append"><Button color="primary" className="mr-1" >{AddSponsor}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
              <Label><b>{SearchSponsorUserName}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={SearchSponsorWithUserName} aria-label={SponsorUserName}/>
                <InputGroupAddon addonType="append"><Button color="secondary" className="mr-1" >{Search}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
                <Label>
                    <b>{SponsorsBulkUpload}</b> &nbsp;
                    <small>
                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                        {DonwloadCSVTemplate}
                        </Link>
                    </small>
                </Label>
                <div className="custom-file">
                  <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
                  <Label className="custom-file-label" htmlFor="validatedCustomFile">{ChooseSponsorCSV}</Label>
                  <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
                </div>
            </Col>
            <Col lg="12">
                <Button color="warning" className="mr-1 f-right m-t-10" >{SendRegistrationReminder}</Button>
            </Col>
        </Row>
        <div className="table-responsive m-t-20">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{Sponsor}</th>
                        <th scope="col">{SponsorContact}</th>
                        <th scope="col">{Actions}</th>
                    </tr>
                </thead>
                <tbody>
              {sponsors.map((data, i) => {
                  return (
                      <tr key={data.id}>
                          <th scope="row">{i}</th>
                          <td><UserThumbnail userID={[data.id]}/></td>
                          <td><UserThumbnail userID={[data.id]}/></td>
                          <td>
                              <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{Delete}</Button>
                          </td>
                      </tr>
                  )
              })}
                </tbody>
            </Table>

        </div>
    </Fragment>
  )
}
export default SponsorsTable
