import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody,Row, Input, Label, Col, Button, Media} from 'reactstrap'
import { Fragment } from 'react'
import { EvntStudio, HalfTimeShow, Homes, Instructions, ModeSettings, MoreOptions, NikeStore, Settings, SocialSharing, TeamStatistics, VideoQuality, VIPSuite } from '../../../../constant'
import {ChangePlatformType} from '../../../../redux/common/actions'
import CloseWindow from './2DAssets/CloseWindow';

export const MoreMobile = (props) => {
  const dispatch = useDispatch(); 
  const windowWidth = props.windowWidth

  const displayBTN = () => {
    if(windowWidth <= 1000 && 991 <= windowWidth){
        return "none"
    }else{
        return "block"
    }
  }
  return(
    <Fragment>
        <Card className={"evnt-card"}>
        <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
          <CardBody style={{color:"white"}}>
            <h2 className="evnt-card-title" ><b>{MoreOptions}</b></h2>
            <Row>
              <Col md="4" sm="4" xs="3">
                <span  
                  className="icon-bnt" onClick={()=> props.checkIfIsOpen({type:"socialSharing"})}>
                  <i className="fa fa-share-square-o fa-2x vertical-align horizontal-center"></i>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3" style={{display:displayBTN()}}>
                <span  
                  className="icon-bnt"
                  onClick={()=> props.checkIfIsOpen({type:"teamStatistics"})}>
                  <i className="fa fa-bar-chart-o fa-2x"></i>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3">
                <span  
                  className="icon-bnt"
                  onClick={()=> props.checkIfIsOpen({type:"nikeStore"})}>
                  <Media className="b-r-10" style={{width:35}} src={require("../../../../assets/images/icons/nikeSNKRS.png")}/>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3">
                <span  
                  className="icon-bnt"
                  onClick={()=> props.checkIfIsOpen({type:"calienteCasino"})}>
                  <Media className="b-r-10" style={{width:35}} src={require("../../../../assets/images/icons/calienteCasino.png")}/>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3" style={{display:displayBTN()}}>
                <span  
                  className="icon-bnt"
                  onClick={() => props.changePerspective({toLocationId:1,toPerspectiveId:0,type:undefined})}>
                  <i className="fa fa-home fa-3x"></i>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3">
                <span  
                  className="icon-bnt"
                  onClick={()=> props.checkIfIsOpen({type:"Settings360"})}>
                  <i className="fa fa-ellipsis-h fa-2x"></i>
                </span>
              </Col>
              <Col md="4" sm="4" xs="3" style={{display:displayBTN()}}>
                <span  
                  className="icon-bnt"
                  onClick={()=> props.checkIfIsOpen({type:"UpgradePerspective",data:{
                  name:"Palco Azteca",
                  previewType:"video",
                  src:"product/premium_perspective.jpg",
                  video:"Stadium/PalcoNFL.mp4",
                  toLocationId:3,
                  toPerspectiveId:0
                }})}>
                  <h4><b>{"VIP"}</b></h4>
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
    </Fragment>
  )
}

export default MoreMobile