import React, { useState, useEffect, Fragment } from 'react'
import {Table, Row, Col, Label, Media, Badge, Input,InputGroup,InputGroupAddon,Button,Nav,NavItem,NavLink,
TabContent, TabPane} from 'reactstrap'

import {Actions, AddAdministrator, AdministratorEmail, 
  List, Add, AdministratorCRUDPermissions, AdministratorPermissionsTypes, 
  DonwloadCSVTemplate, UserImage, UserName, Username, UserPermissions, 
  User, ChooseAdminCSV, UsersBulkUpload, SendRegistrationReminder, Delete,
  Edit, Registered, Email, GuestsList, BlockedUsers, UsersList, TalentList, TalentsList} from "../../../../constant"

import GuestsListTable from './GuestsListTable'
import BlockedUsersTable from './BlockedUsersTable'
import TalentListTable from './TalentListTable'

const UsersListData = (props) =>  {

  const [guestsUsers , setGuestUsers] = useState(props.guestsList)  
  const [bannedUsers , setBannedUsers] = useState(props.bannedList)  

  const [showTalentList , setShowTalentList] = useState(props.showTalentList) 
  const [talentList , seTtalentList] = useState(props.talentList)

  const [VerticleTab, setVerticleTab] = useState('1')
  return (
    <Fragment>
        <h1>{UsersList}</h1>
        <hr/>
        
        <Row>
          <Col sm="2" xs="12">
            <Nav className="nav flex-column nav-pills">
              {showTalentList != undefined && showTalentList == true ? 
                <NavItem>
                  <NavLink   className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')} style={{cursor: "pointer"}} >{TalentList}</NavLink>
                </NavItem>:''
              }
              <NavItem>
                <NavLink   className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')} style={{cursor: "pointer"}} >{GuestsList}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')} style={{cursor: "pointer",color:"Red"}} >{BlockedUsers}</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="10" xs="12">
             <TabContent activeTab={VerticleTab}>
              <TabPane className="fade show"  tabId="1">
                <h5>{GuestsList}</h5>
                <hr/>
                <GuestsListTable guestsList={guestsUsers}/>
              </TabPane>

              <TabPane tabId="2">
                <h5 style={{color:"red"}}>{BlockedUsers}</h5>
                <hr/>
                <BlockedUsersTable bannedList={bannedUsers}/>
              </TabPane>

              
              {showTalentList != undefined && showTalentList == true ? 
                
                <TabPane tabId="3">
                  <h5>{TalentsList}</h5>
                  <hr/>
                  <TalentListTable talentList={talentList}/>
                </TabPane>:''
              }


            </TabContent>
          </Col>
        </Row>

    </Fragment>
  )
}

export default UsersListData
