import 'aframe'
import 'aframe-look-at-component'
import {Entity, Scene} from 'aframe-react'
import React, { Fragment, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { ExitVRStatistics } from '../../../constant'

const VR_TeamLineUp = (props) => {

  var teamLineUp = [
    {
      name:"Fuentes Vargas",
      teamPosition:"Position1",
      img:"user/ClubAmerica/2-FuentesVargas(1).png",
      position:{
        x:"-4.37",
        y:"-0.61",
        z:"-6.94"
      }
    },
    {
       name:"Sanchez Ramos",
       teamPosition:"Position1",
       img:"user/ClubAmerica/3-SanchezRamos(1).png",
       position:{
        x:"3.657",
        y:"-0.53",
        z:"-6.36"
      }
    },
    {
       name:"Aquino",
       teamPosition:"Position1",
       img:"user/ClubAmerica/5-Aquino(1).png",
       position:{
        x:"1.633",
        y:"0.147",
        z:"-6"
      }
    },
    {
      name:"Suarez",
      teamPosition:"Position1",
      img:"user/ClubAmerica/7-Suarez(1).png",
      position:{
        x:"1.7",
        y:"0.84",
        z:"-6"
      }
    },
    {
      name:"Martinez",
      teamPosition:"Position1",
      img:"user/ClubAmerica/9-Martinez(1).png",
      position:{
        x:"0.123",
        y:"0.20",
        z:"-6"
      }
    },
    {
      name:"Mario Suna",
      teamPosition:"Position1",
      img:"user/ClubAmerica/12-MarioSuna(1).png",
      position:{
        x:"0.07",
        y:"0.90",
        z:"-6"
      }
    },
    {
      name:"Ochoa",
      teamPosition:"Position1",
      img:"user/ClubAmerica/13-Ochoa(1).png",
      position:{
        x:"0.30",
        y:"-1",
        z:"-6"
      }
    },
    {
      name:"Valdez Rojas",
      teamPosition:"Position1",
      img:"user/ClubAmerica/18-ValdezRojas(1).png",
      position:{
        x:"1.94",
        y:"-0.44",
        z:"-6"
      }
    },
    {
      name:"Viñas",
      teamPosition:"Position1",
      img:"user/ClubAmerica/24-Viñas(1).png",
      position:{
          x:"-1.21",
          y:"0.19",
          z:"-6"
        }
    },
    {
      name:"Silva Dias",
      teamPosition:"Position1",
      img:"user/ClubAmerica/25-SilvaDias(1).png",
      position:{
        x:"-1.70",
        y:"-0.38",
        z:"-6"
      }
    },
    {
      name:"Lainez",
      teamPosition:"Position1",
      img:"user/ClubAmerica/28-Lainez(1).png",
      position:{
        x:"-1.70",
        y:"0.86",
        z:"-6"
      }
    }
  ]

  const [showExitBtn, setExitBtn] = useState(false)
  useEffect(()=>{
    setTimeout(()=> setExitBtn(true),6000)
  },[])

  return (
    <Fragment>
      {teamLineUp.map(player =>{
        return(
          <Entity id={player.name} primitive="a-image" 
            src={require(`../../../assets/images/${player.img}`)} 
            position={`${player.position.x} ${player.position.y} ${player.position.z}`} rotation={`0 0 0`} scale={`0.5 0.5 0.5`}
            events={{ click: () => {
                props.checkIfIsOpen({type:"UserProfileCard",data:{userID:7}})
            }}}
            />
        )
      })}
      {showExitBtn ?
        <div className={`page-main-header vr-statistics-button`}
            onClick={()=>props.setVRLineUp(!props.VRLineUp)}>
              <Button color="danger" style={{marginLeft:"auto", marginRight:"auto"}}>{ExitVRStatistics}</Button>
        </div>:""
      }

    </Fragment>  
  );
}
export default VR_TeamLineUp;
