import React from 'react'
import { MoreHorizontal } from 'react-feather';
import {MobileRightToggle} from '../../../../../redux/common/actions'
import { useDispatch, useSelector } from 'react-redux';

export const BottomBar = (props) => {

    const dispatch = useDispatch();
    const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)

    return(
        <div style={{bottom:0,top:"auto", marginLeft: "25%", width: "50%", marginRight: "25%"}} 
            className={`page-main-header open`}>
            <div className="main-header-right row">
                <div className="nav-right col pull-right right-menu">
                  <ul className={`nav-menus ${mobileRightTog ? 'open': ''}`}>
                    <li style={{cursor:"pointer"}} onClick={()=>props.cameraSettings("audio")}>
                        {props.sharingCam.audio? <i class="fa fa-microphone fa-2x"></i>:<i style={{color:"red"}} className="fa fa-microphone-slash fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.cameraSettings("video")}>
                        {props.sharingCam.video? <i className="fa fa-video-camera fa-2x"></i>:<i style={{color:"red"}} className="fa fa-video-camera fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.shareScreenBtn()}>
                        {props.sharingScreen? <i style={{color:"green"}} class="fa fa-desktop fa-2x"></i>:<i class="fa fa-desktop fa-2x"></i>}                      
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("users")}>
                        {props.currentSideTab == "users" ?<i style={{color:"#158df7"}} class="fa fa-users fa-2x"></i>:<i class="fa fa-users fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("chat")}>
                        {props.currentSideTab == "chat" ?<i style={{color:"#158df7"}} class="fa fa-comments fa-2x"></i>:<i class="fa fa-comments fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("questions")}>
                        {props.currentSideTab == "questions" ?<i style={{color:"#158df7"}} className="fa fa-question-circle fa-2x"></i>:<i className="fa fa-question-circle fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("files")}>
                        {props.currentSideTab == "files" ?<i style={{color:"#158df7"}} className="fa fa-files-o fa-2x"></i>:<i className="fa fa-files-o fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("handsUp")}>
                        {props.currentSideTab == "handsUp" ?<i style={{color:"#158df7"}} className="fa fa-hand-o-up fa-2x"></i>:<i className="fa fa-hand-o-up fa-2x"></i>}
                    </li>
                    <li style={{cursor:"pointer"}} onClick={()=>props.openSideTab("apps")}>
                        {props.currentSideTab == "apps" ?<i style={{color:"#158df7"}} className="fa fa-cubes fa-2x"></i>:<i className="fa fa-cubes fa-2x"></i>}
                    </li>
                  </ul>
                </div>
              <div className="d-lg-none mobile-toggle pull-right"><MoreHorizontal onClick={() => dispatch(MobileRightToggle(mobileRightTog))}/></div>
            </div>
        </div>
    )
}

export default BottomBar