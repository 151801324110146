import React, { Fragment,useState,useEffect } from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import {useHistory,Link} from 'react-router-dom'
import {Container,Row,Col,Card,Media,Button} from 'reactstrap'
import {WebDevelopment,GoTo,Show} from "../../constant"
import JobFilter from '../jobSearch/Job-filter'
import axios from 'axios'
import {useSelector,useDispatch} from 'react-redux'

import UpcomingConferences from './UpcommingShows'

const LearningList = (props) => {
    const history = useHistory()
    const [learnindData,setLearningData] = useState([])

    const dispatch = useDispatch()
    const et = useSelector(state => state.Common.eventType)

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/api/learning.json`).then(res => setLearningData(res.data))
    },[])

    const clickApply = () => {
        history.push(`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`);
    }

    return (
        <Fragment>
            <BreadCrumb  subparent={Show[et]} title={Show[et] + 'List'}/>
            <Container fluid={true}>
                <Row>
                    <JobFilter/>
                    <Col xl="9 xl-60">
                        <Row>
                            <Col xl="12">
                                <Card>
                                <Row className="blog-box blog-list">
                                    <Col sm="5">
                                    <Media className="img-fluid sm-100-w" src={require("../../assets/images/faq/2.jpg")} alt=""/>
                                </Col>
                                    <Col sm="7">
                                    <div className="blog-details">
                                        <h2><b>{WebDevelopment}</b></h2>
                                        <div className=""><span class="badge badge-success"><b>Live</b></span> <b>Hosted by: {"Petey Cruiser"} | Attendance: {"1850 Viewers"} </b></div>
                                        <div className="blog-bottom-content">
                                            <ul className="">
                                                <li><b>From: </b>{"10 March 2019 - 13:00"}</li>
                                                <li className="digits"><b>To: </b>{"10 March 2019 - 14:00"}</li>
                                            </ul>
                                        <hr/>
                                        <p className="mt-0">{"inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit."}</p>
                                        <Link to={`${process.env.PUBLIC_URL}/Pages/Shows/ShowViews/ShowView`}> 
                                            <Button color="primary btn-block" width="100%">{GoTo+' '+Show[et]}</Button>
                                        </Link>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                </Card>
                            </Col>
                        </Row>
                        <UpcomingConferences/>
                    </Col>
                </Row>
                </Container>
        </Fragment>
    );
};

export default LearningList;