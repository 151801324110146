import React, { useState, Fragment, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Set2DBackGround} from '../../../redux/common/actions'

import Event_1D from './EventViews/1D/Event_1D'
import Event_2D from './EventViews/2D/Event_2D'
import EventForm from './EventViews/EditView/EventForm'

const EventHome = () =>  {

  const dispatch = useDispatch()
  const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
  const mainType = useSelector(state => state.Common.mainType)
  const event = useSelector(state => state.Common.currentEvent)

  useEffect(() => {

    console.log("Setting 2D BackGround ---------------------------------------")
    platformtypes.map(view =>{
      if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.home))}
    }) 

  },[])

  switch(mainType){
    case"Admin":
      return (
        <Fragment>
          <EventForm eventData={event}/>
        </Fragment>
      )
    break;
    default:
    case"1D":
      return (
        <Fragment>
          <Event_1D eventData={event}/>
        </Fragment>
      )
    break;
    case"2D":
      return (
        <Fragment>
          <Event_2D eventData={event}/>
        </Fragment>
      )
    break;
  }

  
}
export default EventHome
