import React, {Fragment,useEffect,useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Set2DBackGround} from '../../../../redux/common/actions'
import Experience_1D from './1D/Experience_1D'
import ExperienceForm from './EditView/ExperienceForm'

const ExperienceView = () => {

    const dispatch = useDispatch()
    const platformtypes =  useSelector(state => state.Common.currentEvent.platformtypes)
    const mainType = useSelector(state => state.Common.mainType)
    const experience = useSelector(state => state.Common.currentEvent.experiences[0])
  
    useEffect(() => {
      console.log("Setting 2D BackGround ---------------------------------------")
      platformtypes.map(view =>{
        if(view.type == "2D"){dispatch(Set2DBackGround(view.backgrounds.home))}
      }) 
    },[])

    switch(mainType){
        case"Admin":
          return (
            <Fragment>
              <ExperienceForm experienceData={experience}/>
            </Fragment>
          )
        break;
        default:
        case"1D":
          return (
            <Fragment>
              <Experience_1D experienceData={experience}/>
            </Fragment>
          )
        break;
        case"2D":
          return (
            <Fragment>
              {"2D"}
              {/* <Experience_2D experiencesData={experiences}/> */}
            </Fragment>
          )
        break;
      }

};

export default ExperienceView;