import React, { useState, useEffect } from 'react'
import { Button, Card } from 'reactstrap'
import { Fragment } from 'react'
import { Media, Row, Col } from 'reactstrap'
import CloseWindow from './2DAssets/CloseWindow'
import ScrollArea from 'react-scrollbar'
import TeamStatistics_table from './2DAssets/TeamStatistics_table'
import TeamStatistics_graph from './2DAssets/TeamStatistics_graph'
import { Followers, Following, TeamStatistics } from '../../../../constant'

export const UserProfileCard = (props) => {

    var users = require("../../../../api/usercard.json")
    var userID = props.windowData.userID
    const [profileUser, setProfileUser] = useState(users.filter(user=>user.id == userID)[0])

    useEffect(()=>{
        setProfileUser(users.filter(user=>user.id == userID)[0])
        console.log("Setting User Card", profileUser)
    },[userID])

    switch(userID){
        case 7:
            return(
                <Fragment>
                    <Card style={{margin:"15px 30px", width:"350px",height:"450px", pointerEvents:"all"}}>
                    <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                    <div style={{padding:"20px 0px"}} className="user-profile">
                        <div style={{display:"flex"}}>
                            <div className="avatars text-center" style={{padding:"20px"}}>
                              <div className="avatar"><Media body className="img-100 rounded-circle" src={require(`../../../../assets/images/${profileUser.profilePicture}`)} alt="#"/>
                                <div className="status status-80 bg-success"> </div>
                              </div>
                            </div>
                            <div style={{paddingTop:"15px"}}>
                                <h5 className="text-uppercase"><b>{profileUser.name} <br/> {profileUser.lastName}</b></h5>
                                <p style={{fontSize:"12px"}}>{"Portero - Club America"}</p>
                                <div className="social-media">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                        <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-content text-center">
                            <ScrollArea
                            speed={0.5}
                            horizontal={false}
                            vertical={true}
                            style={{height:"220px"}}>  
                                {/* <div className="follow text-center">
                                    <Row>
                                        <Col className="border-right"><span>{"Following"}</span>
                                            <div className="follow-num">{profileUser.following}</div>
                                        </Col>
                                        <Col><span>{"Follower"}</span>
                                            <div className="follow-num">{profileUser.follower}</div>
                                        </Col>
                                    </Row>
                                </div> */}
                                <TeamStatistics_table />
                                <TeamStatistics_graph />  
                            </ScrollArea>
                            {/* <hr />
                            <div className="text-center digits">
                                <p className="mb-0">{"Mark.jecno23@gmail.com"}</p>
                                <p className="mb-0">{"+91 365 - 658 - 1236"}</p>
                                <p className="mb-0">{"Fax: 123-4560"}</p>
                            </div> */}
                            <br/>
                            <Button color="primary" onClick={()=> props.checkIfIsOpen({type:"teamStatistics"})}>{TeamStatistics}</Button>
                        </div>
                    </div>
                    </Card>
                </Fragment>
            )  

        default:
        return(
            <Fragment>
                <Card style={{margin:"15px 30px", width:"350px",height:"400px", pointerEvents:"all"}}>
                <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <div style={{padding:"20px 0px"}} className="user-profile">
                    <div className="image">
                        <div className="avatars text-center">
                          <div className="avatar"><Media body className="img-100 rounded-circle" src={require(`../../../../assets/images/${profileUser.profilePicture}`)} alt="#"/>
                            <div className="status status-100 bg-success"> </div>
                          </div>
                        </div>
                    </div>
                    <div className="user-content text-center" style={{paddingTop:"15px"}}>
                        <h4 className="text-uppercase"><b>{profileUser.name} {profileUser.lastName}</b></h4>
                        <div className="social-media">
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                         
                        <hr />
                        <div className="follow text-center">
                            <Row>
                                <Col className="border-right"><span>{Following}</span>
                                    <div className="follow-num">{profileUser.following}</div>
                                </Col>
                                <Col><span>{Followers}</span>
                                    <div className="follow-num">{profileUser.follower}</div>
                                </Col>
                            </Row>
                        </div>

                        {/* <hr />
                        <div className="text-center digits">
                            <p className="mb-0">{"Mark.jecno23@gmail.com"}</p>
                            <p className="mb-0">{"+91 365 - 658 - 1236"}</p>
                            <p className="mb-0">{"Fax: 123-4560"}</p>
                        </div> */}
                    </div>
                </div>
                </Card>
            </Fragment>
        )   
    }
}
export default UserProfileCard