import 'aframe'
import 'aframe-look-at-component'
import {Entity, Scene} from 'aframe-react'
import React, { Fragment, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

const VR_Streaming2D = (props) => {

  const event = useSelector(state => state.Common.currentEvent)
  const [position, setPosition] = useState(props.streaming2D.from.position)
  const [rotation, setRotation] = useState(props.streaming2D.from.rotation)
  const [scale, setScale] = useState(props.streaming2D.from.scale)
  const [streamingType , setStreamingType] = useState(props.streaming2D.type)
  const [streaming2DSRC, setStreaming2DSRC] = useState(props.streaming2D.src)
  const [streamingFrame , setStreamingFrame] = useState("banner/videoFrame_1.png")
  const [magnifyedStreaming , magnifingStreaming] = useState(false)

  useEffect(()=>{
    setPosition(props.streaming2D.from.position)
    setRotation(props.streaming2D.from.rotation)
    setScale(props.streaming2D.from.scale)
    setStreamingType(props.streaming2D.type)
    if(props.streaming2D.type == "HalfTime"){props.checkIfIsOpen({type:"ChatTab",data:{name:"Show",img:`user-card/12.jpg`}})}
    setStreaming2DSRC(props.streaming2D.src)
    setStreamingFrame(event.sponsors[props.streaming2D.streamingFrame.sponsorID].adPlacements[props.streaming2D.streamingFrame.adPlacementID].src)
    console.log("Setting 2D STREAMING VR",props.streaming2D)
  },[props.streaming2D])

  const magnifyStreaming2D = (streaming2DFrom,streaming2DTo) => 
  {
    if(magnifyedStreaming){
      setPosition(streaming2DFrom.position)
      setRotation(streaming2DFrom.rotation)
      setScale(streaming2DFrom.scale)
      magnifingStreaming(false)
      console.log("Reducing Streaming 2D",streaming2DFrom)
    }else{
      setPosition(streaming2DTo.position)
      setRotation(streaming2DTo.rotation)
      setScale(streaming2DTo.scale)
      magnifingStreaming(true)
      console.log("Magnifing Streaming 2D",streaming2DTo)
    }
  }

  // if (props.streaming2D == undefined) {
  //   return <div className="App">Loading...</div>;
  // }

  var showBanner = (props.showBanner == true) ? true: false

  return (
    <Fragment>
      <Entity id="Streaming2D_VR" primitive="a-video" src={require(`../../../assets/video/${streaming2DSRC}`)} width="8" height="4.5" 
        position={`${position.x} ${position.y} ${position.z}`}
        rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
        scale={`${scale.x} ${scale.y} ${scale.z}`}
        events={{ click: () =>{ streamingType != "HalfTime" ?
            magnifyStreaming2D(props.streaming2D.from,props.streaming2D.to):
            props.changePerspective({toLocationId:4,toPerspectiveId:0,type:undefined})
          }}}/>
      
      {magnifyedStreaming || showBanner || streamingType == "HalfTime"? 
      <Entity id={`Video Banner Frame`} primitive="a-image" 
          src={require(`../../../assets/images/${streamingFrame}`)} 
          width="8" height="4.5" position={`${position.x} ${position.y} ${position.z}`} rotation={`${rotation.x} ${rotation.y} ${rotation.z}`} scale={`${scale.x} ${scale.y} ${scale.z}`}
          events={{ click: () => console.log("hola") }}/> : ""}

    </Fragment>  
  );
}
export default VR_Streaming2D;
