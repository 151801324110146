import React from 'react'
import { Card, CardBody, Media, Badge } from 'reactstrap'
import { Fragment } from 'react'
import { Notifications } from '../../../../constant'
import CloseWindow from './2DAssets/CloseWindow'
import NotificationsList from '../../../general/NotificationsList'
import '../../../../assets/css/evnt_cards.css'

export const Notifications360 = (props) => {
    return(
        <Fragment> 
            <Card style={{margin:"0px 30px", width:"300px", height:"400px", pointerEvents:"all"}}>
            <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody>
                  <p className="evnt-card-title"><b>{Notifications}</b></p>
                  <hr/>
                  <NotificationsList type="comments" height={"280px"} commentsData={props.commentsData}/>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default Notifications360