import React,{useState,useEffect,Fragment} from 'react';
import {useSelector, useDispatch } from 'react-redux'
import {Row,Col,Card,Button,Input,Media,Modal, ModalHeader,ModalBody,InputGroup,InputGroupAddon,InputGroupText} from 'reactstrap'
import {ADD_TO_CART,ADD_TO_WISHLIST} from '../../../redux/actionTypes'
import {Link,useHistory} from 'react-router-dom'
import errorImg from '../../../assets/images/search-not-found.png';
import {getVisibleproducts} from '../../../services/ecommerce.service'
import {watchfetchProducts} from "../../../redux/ecommerce/Product/action"
import {NotFoundData,ProductDetails,Quantity,AddToCart,ViewDetails,ProductSizeArray } from "../../../constant"

const  ProductGrid = (props) =>  {
  
  const  history = useHistory();
  const dispatch = useDispatch()
  const data = useSelector(content => content.data.productItems);
  // eslint-disable-next-line 
  const [layoutColumns] = useState(3);
  const symbol = useSelector(content => content.data.symbol);
  const [open, setOpen] = useState(false);
  const [singleProduct, setSingleProduct] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  // eslint-disable-next-line
  const [setStock] = useState('');
  const [quantity, setQuantity] = useState(1);
  const filters = useSelector(content => content.filters);
  const products = getVisibleproducts(data, filters)

  
  useEffect(() => {
    dispatch(watchfetchProducts())
  },[dispatch]);

  const onOpenModal = (productId) => {
      setOpen(true);
      products.forEach((product, i) => {
        if (product.id === productId) {
          setSingleProduct(product)
        }
      })
    };

  const onCloseModal = () => {
    setOpen(false)
  };


const minusQty = () => {
  if (quantity > 1) {
    setStock('InStock')
    setQuantity(quantity - 1)
  }
}

const changeQty = (e) => {
  setQuantity(parseInt(e.target.value))
}

const plusQty = () => {
  if (quantity >= 1) {
    setQuantity(quantity + 1)
  } else {
    setStock('Out of Stock !')
  }
}

  const addcart = (product, qty) => {
    dispatch({ type: ADD_TO_CART, payload: { product, qty } })
    history.push(`${process.env.PUBLIC_URL}/ecommerce/cart`);
  }

  const addWishList = (product) => {
    dispatch({ type: ADD_TO_WISHLIST, payload: {product} });
    history.push(`${process.env.PUBLIC_URL}/ecommerce/wishlist`);
  }

  const onClickDetailPage = (product) => {
    const id = product.id;
    history.push(`${process.env.PUBLIC_URL}/ecommerce/product-detail/${id}`)
  }

      
  return (
    <Fragment> 
        <div className="product-grid">
          <div className="product-wrapper-grid">
            {searchKeyword.length > 0 ?
              <div className="search-not-found text-center">
                <div>
                  <img className="img-fluid second-search" src={errorImg} alt="" />
                  <p>{NotFoundData}</p>
                </div>
              </div>
              :
              <Row className="gridRow">
                {products ? products.map((item, i) =>
                  <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                        {(item.status === 'sale') ?
                            <span className="ribbon ribbon-danger">
                              {item.status}
                            </span> : ''}
                          {(item.status === '50%') ?
                            <span className="ribbon ribbon-success ribbon-right">
                              {item.status}
                            </span> : ''}
                          {(item.status === 'gift') ?
                            <span className="ribbon ribbon-secondary ribbon-vertical-left">
                              <i className="icon-gift"></i>
                            </span> : ''}
                          {(item.status === 'love') ?
                            <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                              <i className="icon-heart"></i>
                            </span> : ''}
                          {(item.status === 'Hot') ?
                            <span className="ribbon ribbon ribbon-clip ribbon-warning">
                              {item.status}
                            </span> : ''}
                          <img className="img-fluid" src={require("../../../assets/images/" + item.img)} alt="" />
                          <div className="product-hover">
                              <ul>
                              <li>
                                <Link to={`${process.env.PUBLIC_URL}/ecommerce/cart`}>
                                <Button color="default" onClick={() => addcart(item, quantity)}>
                                  <i className="icon-shopping-cart"></i>
                                </Button>
                                </Link>
                              </li>
                              <li>
                                <Button color="default" data-toggle="modal"
                                  onClick={() => onOpenModal(item.id)}>
                                  <i className="icon-eye"></i>
                                </Button>
                              </li>
                              <li>
                              <Link to={`${process.env.PUBLIC_URL}/ecommerce/wishlist`}>
                                <Button color="default"  onClick={() => addWishList(item)} >
                                  <i className="icon-heart"></i>
                                </Button>
                              </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div onClick={() => onClickDetailPage(item)} className="product-details">
                          <a className="font-primary" href="#javascript">
                            <h5>{item.name}</h5>
                          </a>
                          <p>{item.note}</p>
                          <div className="product-price">
                            <del>
                              {symbol} {item.discountPrice} </del>
                            {symbol} {item.price}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : ''}
                <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                  <ModalBody>
                    <ModalHeader toggle={onCloseModal}>
                      <div className="product-box row">
                          <Col lg="6" className="product-img">
                            <Media className="img-fluid" src={singleProduct.img ? require("../../../assets/images/" + singleProduct.img) : ""} alt=""/>
                          </Col>
                          <Col lg="6" className="product-details  text-left">
                            <h4>{singleProduct.category}</h4>
                            <div className="product-price">
                            <del>{symbol}{singleProduct.discountPrice}</del>
                            {symbol}{singleProduct.price}
                            </div>
                            <div className="product-view">
                              <h6 className="f-w-600">{ProductDetails}</h6>
                              <p className="mb-0">{singleProduct.discription}</p>
                            </div>
                            <div className="product-size">
                              <ul>
                                  {ProductSizeArray.map((items,i) => 
                                    <li key={i}>
                                      <Button color="outline-light">{items}</Button>
                                    </li>
                                  )}
                              </ul>
                            </div>
                            <div className="product-qnty">
                              <h6 className="f-w-600">{Quantity}</h6>
                              <fieldset>
                                <InputGroup className="bootstrap-touchspin">
                                  <InputGroupAddon addonType="prepend">
                                    <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={minusQty}><i className="fa fa-minus"></i></Button>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="bootstrap-touchspin-prefix" style={{display:"none"}}></InputGroupText>
                                  </InputGroupAddon>
                                  <Input className="touchspin text-center" type="text" name="quantity" value={quantity} onChange={(e) => changeQty(e)}  style={{display:"block"}}/>
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText className="bootstrap-touchspin-postfix" style={{display:"none"}}></InputGroupText>
                                  </InputGroupAddon>
                                  <InputGroupAddon addonType="append" className="ml-0">
                                    <Button color="primary btn-square" className="bootstrap-touchspin-up"  onClick={plusQty}><i className="fa fa-plus"></i></Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </fieldset>
                              <div className="addcart-btn">
                              <Link to={`${process.env.PUBLIC_URL}/ecommerce/cart`}><Button color="primary" className="mr-2 mt-2" onClick={() => addcart(singleProduct, quantity)}>{AddToCart}</Button></Link>
                              <Button onClick={() => onClickDetailPage(singleProduct)} color="primary" className="mr-2 mt-2">{ViewDetails}</Button>
                              </div>
                            </div>
                          </Col>
                      </div>
                    </ModalHeader>
                  </ModalBody>                          
                </Modal>
              </Row>
            }
          </div>
        </div>  
    </Fragment>
  );
}

export default ProductGrid;