import React, { useState, useEffect, Fragment } from 'react'
import {Table,Row,Col,Label, Media, Input,InputGroup,InputGroupAddon,Button} from 'reactstrap'
import {AddAdministrator, AddBannerIndividually, AllowSponsorToAddABanner, ChooseJPGorPNG, IndividualBanners, Sponsors} from "../../../../../../../constant"

const BannersForm = (props) =>  {

  const [event , setEvent] = useState(props.bannersData)
  
  return (
    <Fragment>
        <hr/>
        <Row>
            <Col lg='6'>
                <Label>
                    <b>{Sponsors}</b> &nbsp;
                    <small>{AllowSponsorToAddABanner}</small>
                </Label>
                <InputGroup>
                    <Input type="select" name="select" className="form-control"  multiple="">
                      <option value={'Brand Name'}>{'Brand Name'}</option>
                      <option value={'Brand Name'}>{'Brand Name'}</option>
                    </Input>
                    <InputGroupAddon addonType="append"><Button color="primary" className="mr-1" >{AddAdministrator}</Button></InputGroupAddon>
                </InputGroup>
            </Col>
            <Col lg='6'>
                <Label>
                    <b>{AddBannerIndividually}</b> &nbsp;
                    <small>
                        {IndividualBanners}
                    </small>
                </Label>
                <div className="custom-file">
                  <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
                  <Label className="custom-file-label" htmlFor="validatedCustomFile">{ChooseJPGorPNG}</Label>
                  <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
                </div>
            </Col>
        </Row>
    </Fragment>
  )
}

export default BannersForm
