import 'aframe'
import 'aframe-look-at-component'
import React, { Fragment, useEffect, useState } from 'react'
import {Entity} from 'aframe-react'
import { useSelector } from 'react-redux'

const VR_PerspectivesMenu = (props) => {
  const [loadingMenu, setLoadingMenu] = useState(true)
  const [menuPosition, setMenuPosition] = useState(props.vrPrespectivesMenu.position)
  const [menuRotation, setMenuRotation] = useState(props.vrPrespectivesMenu.rotation)
  const [menuScale, setMenuScale] = useState(props.vrPrespectivesMenu.scale)

  const [team2 , setTeam2] = useState("Tijuana")
  const [resumeStreaming , setResumeStresming] = useState("Stadium/llegadaClubAmerica.mp4")

  const setCurrentResume = (resume) => {
    // setTeam2(resume.team2)
    // setResumeStresming(resume.streaming)
    console.log("Setting New Resume", resume)

  }

  const gallery = [
    {
      id:0,
      thumbnail: "Stadium/america-santos.jpg",
      streaming:"Stadium/america-santos.mp4",
      name: 'America vs Pachuca',
      team1:'America',
      team2:'Santos'
    },
    {
      id:1,
      thumbnail: "Stadium/america-atlas.jpg",
      streaming2D:"Stadium/america-atlas.mp4",
      name: 'Centro Cancha',
      team1:'America',
      team2:'Atlas'
    },
    {
      id:2,
      thumbnail: "Stadium/america-tigres.jpg",
      streaming2D:"Stadium/america-tigres.mp4",
      name: 'Centro Medio',
      team1:'America',
      team2:'Tigres'
    },
    {
      id:3,
      thumbnail: "Stadium/america-necaxa.jpg",
      streaming2D:"Stadium/america-necaxa.mp4",
      name: 'Centro Platea 1',
      team1:'America',
      team2:'Necaxa'
    },
    {
      id:4,
      thumbnail: "Stadium/america-puebla.jpg",
      streaming2D:"Stadium/america-puebla.mp4",
      name: 'Centro Platea 2',
      team1:'America',
      team2:'Puebla'
    },
    {
      id:5,
      thumbnail: "Stadium/america-tijuana.jpg",
      streaming2D:"Stadium/america-tijuana.mp4",
      name: 'Esquina Sur Abajo',
      team1:'America',
      team2:'Tijuana'
    }
  ];

  useEffect(()=>{
    setMenuPosition(props.vrPrespectivesMenu.position)
    setMenuRotation(props.vrPrespectivesMenu.rotation)
    setMenuScale(props.vrPrespectivesMenu.scale)
    console.log("Setting VR Menu",props.vrPrespectivesMenu)
    setLoadingMenu(false)
  },[props.vrPrespectivesMenu])

  const thumbnailsPositions = ["-0.33 -0.2 -0.016","0 -0.2 0","0.33 -0.2 0","0.66 -0.19 -0.016","0.97 -0.20 0","1.29 -0.20 0"]

  if (loadingMenu) {
    return <div className="App">Loading...</div>;
  }

  return (
    <Fragment>
      <a-entity id="menu-wrapper"
          position={`${menuPosition.x} ${menuPosition.y} ${menuPosition.z}`}
          rotation={`${menuRotation.x} ${menuRotation.y} ${menuRotation.z}`}
          scale={`${menuScale.x} ${menuScale.y} ${menuScale.z}`}>
          <a-entity id="menu-title" position={`0.251 5 -10.60`} rotation={`0 0 0`} scale="30 30 1"
           text="value: Selecciona tu asiento; align:center;"></a-entity>
          <a-entity id="menu-wrapper"  position={`-4.45 5 -10.75`} rotation={`0 0 0`} scale="10 10 10">
            <a-entity id="menu-block-0">
            {props.activePerspectives.map((perspective,i) =>{
                return(
                  <Fragment>
                    <Entity id={`${perspective.name} Thumbnail`} primitive="a-image" 
                    src={require(`../../../../assets/images/perspectives/${perspective.thumbnail}`)} 
                    width="3" height="2" position={thumbnailsPositions[i]} rotation="0 0 0" scale="0.1 0.1 0.1"
                    events={{ click: () => props.changePerspective({toLocationId:0,toPerspectiveId:perspective.id,type:undefined}) }}/>
                    <a-entity id="menu-title" text={`value: ${perspective.name}; align:center;`} position={thumbnailsPositions[i]} scale="0.8 0.8 0.8"></a-entity>
                  </Fragment> 
                )
              })
            }
            </a-entity>
          </a-entity>
        </a-entity>
    </Fragment>  
  )
}
export default VR_PerspectivesMenu;
