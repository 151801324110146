import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import {Table,Row,Col,Label, Media, Badge, Input,InputGroup,InputGroupAddon,Button} from 'reactstrap'
import {Link}  from 'react-router-dom'
import {Actions, Delete, DonwloadCSVTemplate, UserImage, 
  UserName, Username, UserPermissions, User, ChooseAdminCSV, 
  AdministratorsBulkUpload, UserEmail, AddGuest, 
  AddGuestIndividually, UserBulkUpload, ChooseGuestCSV, 
  SendRegistrationReminder, SearchUserEmail, Search, 
  Registered, BlockedUsers, BlockUserIndividually, 
  UnblockUser} from "../../../../constant"

import {UserThumbnail} from '../../../general/UserThumbnail'

const BlockedUsersTable = (props) =>  {

  const [bannedList , setBannedList] = useState(props.bannedList)  

  axios.defaults.baseURL = `http://localhost:4000`
  const [bannedUsers , setBannedUsers] = useState([])
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    bannedList.forEach(guestID => {
      axios.get(`/users/${guestID}`)
      .then(res => {
        setBannedUsers(bannedUser => [...bannedUser,res.data])
        console.log('Setting Banned User: ',res.data)
      }).catch(err => console.error("02",err))
    })
    setLoading(false)
  },[bannedList])
    
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Fragment>
        <Row>
            <Col lg="4">
              <Label><b>{BlockUserIndividually}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={UserEmail} aria-label={UserEmail}/>
                <InputGroupAddon addonType="append"><Button color="primary" className="mr-1" >{AddGuest}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
              <Label><b>{SearchUserEmail}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={SearchUserEmail} aria-label={UserEmail}/>
                <InputGroupAddon addonType="append"><Button color="secondary" className="mr-1" >{Search}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
                <Label>
                    <b>{UserBulkUpload}</b> &nbsp;
                    <small>
                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                        {DonwloadCSVTemplate}
                        </Link>
                    </small>
                </Label>
                <div className="custom-file">
                  <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
                  <Label className="custom-file-label" htmlFor="validatedCustomFile">{ChooseGuestCSV}</Label>
                  <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
                </div>
            </Col>
        </Row>
        <div className="table-responsive m-t-20">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{User}</th>
                          <th scope="col">{Registered}</th>
                        <th scope="col">{Actions}</th>
                    </tr>
                </thead>
                <tbody>
              {bannedUsers.map((data, i) => {
                  return (
                      <tr key={data.id}>
                          <th scope="row">{i}</th>
                          <td>
                            <UserThumbnail userID={[data.id]}/>
                          </td>
                          <td><Badge color="success">{Registered}</Badge></td>
                          <td>
                              <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{Delete}</Button>
                              <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{UnblockUser}</Button>
                          </td>
                      </tr>
                  )
              })}
                </tbody>
            </Table>
        </div>
    </Fragment>
  )
}

export default BlockedUsersTable
