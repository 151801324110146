import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Media } from 'reactstrap'
import '../../../../assets/css/evnt_cards.css'
import { Fragment } from 'react'
import { ChooseYourSeat } from '../../../../constant'
import { CarouselLocationsVR_2D } from '../../../advance/carousel/CarouselComponent'
import CloseWindow from './2DAssets/CloseWindow'

const VenueLocations = (props) => {
    const windowWidth = props.windowWidth
    const [windowType, setWindowType]=useState(null)
    useEffect(()=>{
        if(windowWidth<= 1000 && windowWidth >= 425){
            setWindowType("evnt-card-lg")
        }else{
            setWindowType("evnt-card-md")
        }
    },[windowWidth])

    return (
        <Fragment>
            <Card className={`evnt-card ${windowType}`}>
                <div onClick={()=> props.checkIfIsOpen({type:props.windowName})}><CloseWindow/></div>
                <CardBody style={{color:"white"}}>
                <h2 className="evnt-card-title"><b>{ChooseYourSeat}</b></h2>
                
                    <Fragment>
                        <CarouselLocationsVR_2D 
                            menuForLocation={0} 
                            style={{cursor:"pointer"}} 
                            windowWidth={props.windowWidth}
                            checkIfIsOpen={props.checkIfIsOpen}
                            changePerspective={props.changePerspective} 
                            activePerspectives={props.activePerspectives}  />
                    </Fragment> 

                    {/* <CarouselLocationsVR_2D/> */}
                    {/* <Media style={{height:"300px", width:"auto",float:"none"}} src={require('../../../../assets/images/Mapa_EstadioAzteca.png')}/>   */}
                
                </CardBody>
            </Card>
        </Fragment>
    )
  }
  export default VenueLocations