import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import {Table,Row,Col,Label, Media, Badge, Input,InputGroup,
  InputGroupAddon,Button} from 'reactstrap'
import {Link}  from 'react-router-dom'
import {UserThumbnail} from '../../../general/UserThumbnail'
import {Actions, Delete, DonwloadCSVTemplate, UserImage, UserName,
   Username, UserPermissions, User, ChooseAdminCSV, 
   AdministratorsBulkUpload, TalentEmail, AddGuest, 
   AddTalentIndividually, TalentBulkUpload, ChooseTalentsCSV, 
   SendRegistrationReminder, SearchTalentEmail, Search, Registered, 
   EventGuestsList, Company, Role} from "../../../../constant"

const TalentListTable = (props) =>  {

  const [talentsID , setTalentID] = useState(props.talentList)  

  axios.defaults.baseURL = `http://localhost:4000`
  const [talents , setTalent] = useState([])
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    talentsID.forEach(talentID => {
      axios.get(`/users/${talentID}`)
      .then(res => {
        setTalent(talentUser => [...talentUser,res.data])
        console.log('Setting Talent: ',res.data)
      }).catch(err => console.error("10",err))
    })
    setLoading(false)
  },[talentsID])
    
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <Fragment>
        <Row>
            <Col lg="4">
              <Label><b>{AddTalentIndividually}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={TalentEmail} aria-label={TalentEmail}/>
                <InputGroupAddon addonType="append"><Button color="primary" className="mr-1" >{AddGuest}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
              <Label><b>{SearchTalentEmail}</b></Label>
              <InputGroup>
                <Input className="form-control" type="text" placeholder={SearchTalentEmail} aria-label={TalentEmail}/>
                <InputGroupAddon addonType="append"><Button color="secondary" className="mr-1" >{Search}</Button></InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="4">
                <Label>
                    <b>{TalentBulkUpload}</b> &nbsp;
                    <small>
                        <Link to={`${process.env.PUBLIC_URL}/Pages/Experiences/ExperiencesListView/ExperiencesListView`}> 
                        {DonwloadCSVTemplate}
                        </Link>
                    </small>
                </Label>
                <div className="custom-file">
                  <Input className="custom-file-input" id="validatedCustomFile" type="file" required="" />
                  <Label className="custom-file-label" htmlFor="validatedCustomFile">{ChooseTalentsCSV}</Label>
                  <div className="invalid-feedback">{"Example invalid custom file feedback"}</div>
                </div>
            </Col>
            <Col lg="12">
                <Button color="warning" className="mr-1 f-right m-t-10" >{SendRegistrationReminder}</Button>
            </Col>
        </Row>
        <div className="table-responsive m-t-20">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{User}</th>
                          <th scope="col">{Registered}</th>
                          <th scope="col">{Company}</th>
                          <th scope="col">{Role}</th>
                        <th scope="col">{Actions}</th>
                    </tr>
                </thead>
                <tbody>
              {talents.map((data, i) => {
                  return (
                      <tr key={data.id}>
                          <th scope="row">{i}</th>
                          <td><UserThumbnail userID={[data.id]}/></td>
                          <td><Badge color="success">{Registered}</Badge></td>
                          <td>{data.company}</td>
                          <td>{data.role}</td>
                          <td>
                              <Button color="danger" className="btn-xs mr-1 f-none m-t-20" >{Delete}</Button>
                              <Button outline color="warning" className="btn-xs mr-1 f-none m-t-20" >{SendRegistrationReminder}</Button>
                          </td>
                      </tr>
                  )
              })}
                </tbody>
            </Table>
        </div>
    </Fragment>
  )
}
export default TalentListTable
