import React, { useEffect, useLayoutEffect, useState } from 'react';
import {Maximize} from 'react-feather';
import CollapsedStories from '../../components/users/collapsedStories'
import Search from './Search'
import {useSelector} from 'react-redux';

import {HeaderEventCaster, HeaderUserThumbnail} from '../../components/general/UserThumbnail';

const Rightbar = (props) => {
  
  const [currentShow, setCurrentShow] = useState(props.currentShow)
  const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
  useEffect(()=>{ console.log(props.eventTypes) })

  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  
  const width = useWindowSize()
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
    function updateSize() {
        setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
 }

  return(
    <div className="nav-right col pull-right right-menu">
      <ul 
        style={(width <= 991?{color:"white", cursor:"pointer", marginTop:"30px",background:"rgba( 255, 255, 255, 0.10 )",backgroundColor:"#e89cff2e",boxShadow:"0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",backdropFilter:"blur( 10.5px )"}
        :{color:"white", cursor:"pointer"})}
        className={`nav-menus ${mobileRightTog ? 'open': ''}`}>

        <li style={{cursor:"pointer",color:"white",width:"250px"}} onClick={()=> props.checkIfIsOpen({type:"AudioSettings"})}>
          <HeaderEventCaster eventCasterID={props.eventCasterID}/>
        </li>

        <li className="step12" style={{width:"126px"}}>
          {true?<CollapsedStories checkIfIsOpen={props.checkIfIsOpen} />:<Search/>}
        </li>

        {width >= 991? 
        <li className="step13" style={(width <= 1000?{cursor:"pointer",color:"white",width:"auto"}:{cursor:"pointer",color:"white",width:"200px"})}>
          <HeaderUserThumbnail marginTop={0} userID={currentShow.talents} showCurrentUser={true} checkIfIsOpen={props.checkIfIsOpen} width={width}/>
        </li>: ""}

        <li className="step14" onClick={()=> props.checkIfIsOpen({type:"Notifications360"})}>
          {width >= 991?<i className="fa fa-bell fa-2x"></i>:<i className="fa fa-bell fa-lg"></i>}<span style={{right:"10px"}} className="notification badge badge-pill badge-danger">{"2"}</span>
        </li>
        <li className="step15" onClick={()=> props.checkIfIsOpen({type:"UsersList360"})}>
          {width >= 991?<i className="fa fa-users fa-2x"></i>:<i className="fa fa-users fa-lg"></i>}
        </li>
        {/* {props.eventTypes.length > 0 && width >= 991 ?
        <li>
          <CurrentPlatformType eventTypes={props.eventTypes}/>
        </li>:""} */}
        {/* {width >= 991 ?  <li><a onClick={goFull} className="text-dark" href="#!"><Maximize/></a></li> : ""} */}
      </ul>
    </div> 
  )
}


export default Rightbar